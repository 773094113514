<template>
<!-- SECTION Users-->
  <div id="usersSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      
<!-- HEADER Users -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
        <div class="bg-menu border-1 rounded-5 box sombra">
          
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
            <ion-icon name="people" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary["Usuarios"][session.user.language]}}
            </h6>
          </div>
          
          <div class="p-3 pt-0">
  <!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">	
              <hr class="m-0 p-0">

              <select
              :value="currentCompany.nifCif"
              class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
              style="border: solid 2px white ; color: black;"
              @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif"
                :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif"
                :value="company.nifCif">
                  {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                </option>
              </select>
              
              <hr class="m-0 p-0">

              <div class="col-12 p-0 justify-content-center d-flex">
                <button id="btnDisplayNewUserModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3" 
                @click="toggleNewUserModal">
                  <span class="button-text text-white">
                    <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{dictionary["Añadir usuario"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>


      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
          <!--<ion-icon v-if="session.user.bookmark==='users'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
            <ion-icon name="people-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            {{dictionary["Usuarios"][session.user.language]}}
          </div>

          <div class="m-0 p-3 overflow-auto">
            <table id="usersDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in usersColumns" :key="column.data"
                  :width="column.width" class="fw-medium text-nowrap p-2">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    
    </div>
  </div>

<!-- MODAL New User -->
  <NewUserModal
  v-if="display.newUserModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :modules="availableModules"
  v-on:toggleNewUserModal="toggleNewUserModal">
  </NewUserModal>

<!-- MODAL Edit User -->
  <EditUserModal
  v-if="display.editUserModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :userId="targetUserId"
  :editRequest="editRequest"
  :modules="availableModules"
  :availableRol="userRol"
  v-on:toggleEditUserModal="toggleEditUserModal">
  </EditUserModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteUserModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Usuario a eliminar'][session.user.language] + ': '"
  :alertTarget="targetUserId"
  v-on:acceptAlert="onDeleteUser"
  v-on:dismissAlert="toggleDeleteUserModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import NewUserModal from '@/components/users/NewUserModal'
import EditUserModal from '@/components/users/EditUserModal'
import TextAlertModal from '@/components/other/TextAlertModal'
// Clomos
import api from '@/services/api';
//assets
import availableModulesJSON  from '@/assets/json/rol_name_types'

export default {
  name: "UsersComponent",
  components: { NewUserModal, EditUserModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getUserList()
      this.displayUsersDataTable();
    }
  },
  data() {
    return {
      availableModules: availableModulesJSON,
    userRol:[],
      usersColumns: [
        {text:"Identificador", data: "userId", width: "20%", className:"tx-left tx-12 align-middle dt-nowrap",
        render: (data) => {
          if(this.session.user.id===data)
            return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-14 tx-clomos-midtone'></ion-icon>"
            +"<span data-id="+data+" class='btnEditMyProfile c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>"+data+"</span>";
          else 
            return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-14 tx-clomos-midtone'></ion-icon>"
            +"<span data-id="+data+" class='btnShowUser c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>"+data+"</span>";
          }
        },
        {text:"Nombre", data: "userName", width: "5%", className:"tx-left tx-12 align-middle"},
        {text:"Apellidos", data: "userLastName", width: "10%", className:"tx-left tx-12 align-middle"},
        {text:"Empresa", data: "userCompany", width: "10%", className:"tx-left tx-12 align-middle",
          render: (data) => {
            let companyName = data;
            if (this.availableCompanies.length > 0) {
              for (let company in this.availableCompanies) 
                if (this.availableCompanies[company].nifCif === data) 
                  companyName = this.availableCompanies[company].name;
            }
            return companyName;
          }
        },
        {text:"Rol", data: "userRol", width: "10%", className:"tx-left tx-12 align-middle",
        render: (data) => {
            if (!this.userRol || this.userRol.length === 0) return data;           
            const userRole = this.userRol.find(element => data===element.numero);
            if (!userRole) return data;
            return this.dictionary[userRole.nombre][this.session.user.language];
           }
        },
        {text:"Teléfono", data: "userTelephone", width: "10%", className:"tx-left tx-12 align-middle"},
        {text:"Dirección", data: "userEmail", width: "15%", className:"tx-left tx-12 align-middle"},
        {text:"Editar", data: "userId", width: "5%", className:"align-middle",
          render: (data) => {
            if (this.session.user.id===data)
              return "<ion-icon name='create-outline' data-id="+data+" class='btnEditMyProfile btn tx-16 tx-primary m-auto p-auto'></ion-icon>"
            else if (Number(this.session.user.rol)>=128)
              return "<ion-icon name='create-outline' data-id="+data+" class='btnEditUser btn tx-16 tx-primary m-auto p-auto'></ion-icon>"
            else 
              return "<ion-icon name='create-outline' data-id="+data+" class='tx-16 tx-gray-400 m-auto p-auto'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "userId", width: "5%", className:"align-middle",
          render: (data) => {
            if (this.session.user.id!=data && Number(this.session.user.rol)>=128 )
              return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteUser btn tx-16 tx-danger m-auto p-auto'></ion-icon>"
            else
              return "<ion-icon name='trash-outline' data-id="+data+" class='tx-16 tx-gray-400 btn m-auto p-auto'></ion-icon>"
          }
        }
      ],
      targetUserId: this.$route.params.targetEntity,
      userList: [],
      display: {
        newUserModal: false,
        editUserModal: false,
        deleteUserModal: false
      }
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$emit('setCurrentCompany',value);
    },
    displayUsersDataTable() {
      $("#usersDataTable").DataTable().clear()
      for (let i in this.userList) {
        $("#usersDataTable").DataTable().rows.add([{
          userId: this.userList[i].id, 
          userName: this.userList[i].name, 
          userLastName: this.userList[i].lastName, 
          userEmail: this.userList[i].email, 
          userRol: this.userList[i].rol, 
          userTelephone: this.userList[i].telephone, 
          userCompany: this.userList[i].nifCif,
        }]);
      }
      $("#usersDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async toggleNewUserModal() {
      if (this.display.newUserModal) {
        await this.getUserList();
        this.displayUsersDataTable();
      }
      this.display.newUserModal = !this.display.newUserModal;
    },
    async toggleEditUserModal() {
      if (this.display.editUserModal) {
        await this.getUserList();
        this.displayUsersDataTable();
        this.editRequest = false;
      }
      this.display.editUserModal = !this.display.editUserModal;
    },
    async toggleDeleteUserModal() {
      if (this.display.deleteUserModal) {
        await this.getUserList();
        this.displayUsersDataTable();
      }
      this.display.deleteUserModal = !this.display.deleteUserModal;
    },
    async displayRolbyModulues() {
    this.userRol = [];
    let userModules = this.session.authorisation.availableModules;
      for (let moduleName in userModules) {
        for (let moduleRol in this.availableModules[moduleName]) {
          let rolInfo = {
            numero: moduleRol,
            nombre: this.availableModules[moduleName][moduleRol]
          };      
          let existingRolIndex = this.userRol.findIndex(addedRol => addedRol.numero === rolInfo.numero);
          if (existingRolIndex !== -1) {        
            if (!this.userRol[existingRolIndex].nombre.includes(rolInfo.nombre)) {
              this.userRol[existingRolIndex].nombre += "/" + rolInfo.nombre;
            }
          } else {        
            this.userRol.push(rolInfo);
          }
        }
      }
  },

    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnShowUser").get().forEach(btn => {
          btn.addEventListener('click', this.onShowUser);
        });
        $(".btnEditMyProfile").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetMyUser);
        });
        $(".btnEditUser").get().forEach(btn => {
          btn.addEventListener('click', this.onEditUser);
        });
        $(".btnDeleteUser").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteUser);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getUserList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getUserList(request);
      this.userList = api.parseUsersFromResponse(response);
      if (callback && typeof callback === "function") callback(this.userList);
    },
    // TARGET 
    onTargetUser(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetUserId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetUserId}});
    },
    onTargetMyUser() {
      this.$router.replace({params: {currentDisplay: 'profile'}});
    },
    // SHOW 
    onShowUser(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetUserId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetUserId}});
      this.editRequest = false;
      this.toggleEditUserModal();
    },
    // SET
    onEditUser(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetUserId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetUserId}});
      this.editRequest = true;
      this.toggleEditUserModal();
    },
    // DELETE
    async onDeleteUser(event) {
      if (!this.display.deleteUserModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetUserId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetUserId}});
      }
      else {
        let response = await api.deleteUsers([this.targetUserId]);
        if (response["status-code"] === "200") {
          window.alert("El usuario ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el usuario.");
        }
      }
      this.toggleDeleteUserModal();
    }
  },
  async created() {
    await this.getUserList();
    this.$emit('getAvailableCompanies',(this.displayUsersDataTable));
    
  },
  mounted() {
     this.displayRolbyModulues()
    $("#usersDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.usersColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btnShowUser:hover, .btnCopyId:hover, .btnEditMyProfile:hover, .btnEditUser:hover, .btnDeleteUser:hover {
  color: var(--main-dark) !important;
}
</style>