<template>
  <!-- SECTION Lockings-->
    <div id="LockingsSection" class="row col-12 m-0 p-0">
      <div class="row col-12 p-2 m-0 section-container justify-content-center">
        
  <!-- HEADER Lockings -->
        <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5" >
          <div class="bg-menu border-1 rounded-5 box sombra">
            
            <div class="col-12 overflow-hidden justify-content-center">
              <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
              <ion-icon name="lock-open" class="tx-18 me-2 align-bottom"></ion-icon>
                {{dictionary["Reservas"][session.user.language]}}
              </h6>
            </div>
  
            <div class="p-3 pt-0">
  <!-- Selector de Sistema -->
              <div class="col-12 m-0 p-0">	
                <hr class="m-0 p-0">
  
                <select
                :value="currentCompany.nifCif"
                class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
                style="border: solid 2px white ; color: black;"
                @change="onCurrentCompanyChanged">
                  <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                  <option v-for="company in availableCompanies" :key="company.nifCif"
                  :v-if="company.nifCif != session.user.nifCif
                  && company.relation != undefined
                  && company.relation.referenceCompany === this.session.user.nifCif"
                  :value="company.nifCif">
                    {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                  </option>
                </select>
                
                <hr class="m-0 p-0">
  
                <div class="col-12 p-0 justify-content-center d-flex">
                  <button id="btnDisplayNewLockingsModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3" 
                  @click="toggleNewLockingModal">
                    <span class="button-text text-white">
                      <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                      <span class="text-white mt-0 text-nowrap">{{dictionary["Añadir reserva"][session.user.language]}}</span>
                    </span>
                  </button>
                </div>
              </div>
              
            </div>
            
          </div>
        </div>
  
        <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
          <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
            <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
              <ion-icon name="lock-open-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
              {{dictionary["Reservas"][session.user.language]}}
            </div>
  <!-- TABLE Lockings -->
            <div class="m-0 p-3 overflow-auto">
              <table id="LockingsDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
                <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                  <tr>
                    <th v-for="column in lockingColumns" :key="column.data"
                    :width="column.width" class="fw-medium text-nowrap p-2">
                      {{dictionary[column.text][session.user.language]}}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  
  <!-- MODAL New Locking -->
    <NewLockingModal
    v-if="display.newLockingModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :timeZones="timeZones"
    :currentCompany="currentCompany"
    :availableCompanies="availableCompanies"
    v-on:toggleNewLockingModal="toggleNewLockingModal">
    </NewLockingModal> 
    
  <!--MODAL Edit Locking-->
    <EditLockingModal
    v-if="display.editLockingModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :timeZones="timeZones"
    :currentCompany="currentCompany"
    :availableCompanies="availableCompanies"
    :lockingId="targetLockingId"
    v-on:toggleEditLockingModal="toggleEditLockingModal">
    </EditLockingModal> 
  
    <!-- MODAL Cancel Text Alert -->
    <TextAlertModal
    v-if="display.cancelLockingModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :alertHeader="dictionary['Pedido a cancelar'][session.user.language] + ': '"
    :alertTarget="targetLockingId"
    :alertTexts="idPedido"
    v-on:acceptAlert="onCancelLocking"
    v-on:dismissAlert="toggleCancelLockingModal">
    </TextAlertModal>
  
  <!-- MODAL Delete Text Alert -->
    <TextAlertModal
    v-if="display.deleteLockingModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :alertHeader="dictionary['Pedido a eliminar'][session.user.language] + ': '"
    :alertTarget="targetLockingId"
    :alertTexts="idPedido"
    v-on:acceptAlert="onDeleteLocking"
    v-on:dismissAlert="toggleDeleteLockingModal">
    </TextAlertModal>
  
  </template>
  
  <script>
  import $ from 'jquery';
  import NewLockingModal from '@/components/lockers/NewLockingModal';
  import EditLockingModal from '@/components/lockers/EditLockingModal';
  import TextAlertModal from '@/components/other/TextAlertModal';
  // Clomos
  import api from '@/services/api';
  
  
  export default {
    name: "LockingsComponent",
    components: {  NewLockingModal, TextAlertModal, EditLockingModal  },
    props: {
      session: {
        type: Object,
        requiered: true,
      },
      dictionary: {
        type: Object,
        requiered: true,
      },
      countries: {
        type: Object,
        requiered: true,
      },
      timeZones: {
        type: Object,
        requiered: true,
      },
      currentCompany: {
        type: Object,
        requiered: true,
      },
      availableCompanies: {
        type: Object,
        requiered: true,
      },
      visibility: {
        type: Object,
        requiered: true,
      },
      dataTableOptions: {
        type: Object,
        requiered: true,
      }
    },
    watch: { 
      currentCompany: async function() {
        await this.getLockingList();
        this.displayLockingsDataTable();
      }
    },
    data() {
      return {
        lockingColumns: [
        {text:"Albarán", data: "lockingId", width: "5%",
            render: (data) => {
              return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailLocking btn tx-16 tx-primary  p-0 m-0'></ion-icon>";
            }
          },
          {
      text: "Identificador",
      data: "lockingId",
      width: "15%",
      className: "tx-left tx-12 align-middle dt-nowrap",
      render: (data) => {
          let dataShorted = data;
          if (data.length > 8) {
              dataShorted = data.substring(0, 8) + '...';
          }
          return "<ion-icon name='copy-outline' data-id='" + data + "' class='btnCopyId btn tx-14' ></ion-icon>" +
              "<span data-id='" + data + "' class='btnTargetProduct text-nowrap align-middle' " +
              "title='" + data + "'>" + dataShorted + "</span>";
              }
          },
          {text:"Pedido", data: "lockingParams", width: "15%", className:"tx-left tx-12 align-middle dt-nowrap",
            render: (data) => {
              return "<span data-id="+data.idPedido+" class='btnTargetProduct "+/*"btn "+*/"tx-bold tx-12 text-nowrap align-middle'>"+data.idPedido+"</span>";
            }
          },
        
          {text:"Estado", data: "lockingStatus", width: "10%", className:"tx-left tx-12 align-middle",
            render: (data) => {
              switch (data.status) {
                case "0":
                  return this.dictionary["Creada"][this.session.user.language];
                case "1":
                return this.dictionary["Planificada"][this.session.user.language];
                case "2":
                return this.dictionary["Depositado"][this.session.user.language]+ (new Date() > new Date(new Date(data.executionEndDateTime).setDate(new Date(data.executionEndDateTime).getDate() + parseInt(data.maxHoldingDays))) ? "<ion-icon name='alert' color='danger'></ion-icon>" : "");
                case "3":
                  return this.dictionary["Anulando"][this.session.user.language];
                case "4":
                  return this.dictionary["Finalizada"][this.session.user.language];
                case "5":
                  return this.dictionary["Cancelada"][this.session.user.language];
                default:
                  return data.status;
              }
             
            }        
          },
          
          {text:"Fecha de creación", data: "lockingCreationDateTime", width: "10%", className:"tx-left tx-12 align-middle",
            render: (data) => {   
              return (data!="")
              ?"<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              :"<span style='display: none;'></span> <spam>-</spam>";
            }
          },
          {text:"Modificar", data: "lockingStatus", width: "5%", className:"tx-12 align-middle",
            render: (data) => {
              return (
                data.status==="4"||data.status==="5"?"<ion-icon name='settings-outline' data-id="+data.id+" class='btn tx-16 tx-primary m-0 p-0 opacity-50' disabled></ion-icon>": "<ion-icon name='settings-outline' data-id="+data.id+" class='btnModifyLocking btn tx-16 tx-primary m-0 p-0'></ion-icon>"
                )
            }
          },
          {text:"Anular", data: "lockingStatus", width: "5%", className:"align-middle",
            render: (data) => {
              return (
                data.status==="0"||data.status==="2"?
              "<ion-icon name='close-circle-outline' data-id='" + data.id + "' data-idOrder='" + data.idOrder +"' data-idLocker='" + data.idLocker + "' class='btnCancelLocking btn tx-16 tx-danger m-0 p-0'></ion-icon>":
              "<ion-icon name='close-circle-outline' data-id='" + data.id + "' data-idOrder='" + data.idOrder +"' data-idLocker='" + data.idLocker + "' class=' btn tx-16 tx-danger opacity-50 m-0 p-0' disabled></ion-icon>"
      )
            }
          },
          {text:"Eliminar", data: "lockingStatus", width: "5%", className:"align-middle",
            render: (data) => {
              return (
              "<ion-icon name='trash-outline' data-id='" + data.id + "' data-idOrder='" + data.idOrder + "' class='btnDeleteLocking btn tx-16 tx-danger m-0 p-0'></ion-icon>"
              )
            }
          }
        ],
        targetLockingId: "",
        idPedido:[],
        idLocker:"",
        lockingList: [],
        lockingData:[],
        alertValue: '',
        bulgeInput: {
          volume: {
            height: 0,
            width: 0,
            depth: 0
          },
          units: 0
        },
        bulgeList: [],
        display: {
          newLockingModal: false,       
          deleteLockingModal: false,
          editLockingModal: false,
          cancelLockingModal: false,
        }
      }
    },
    computed: {
      targetLocking() {
        for (let i in this.lockingList) {
          if (this.lockingList[i].id === this.targetLockingId)
            return this.lockingList[i];
        }
        return {};
      },
    },
    emits: ["setCurrentCompany", "getAvailableCompanies"],
    methods: {
      // DISPLAY
      onCurrentCompanyChanged(event) {
        let value = event.target.value;
        this.$router.replace({params: {currentCompanyNifCif: value}})
        this.$emit('setCurrentCompany',value);
      },
      displayLockingsDataTable() {
        $("#LockingsDataTable").DataTable().clear()
        for (let i in this.lockingList) {
          $("#LockingsDataTable").DataTable().rows.add([{
            lockingId: this.lockingList[i].id, 
            lockingType: this.lockingList[i].type,
            lockingParams: this.lockingList[i].params,
            locking: this.lockingList[i],
            lockingStatus: {
            id: this.lockingList[i].id,
            idOrder: this.lockingList[i].params.idPedido,
            idLocker:this.lockingList[i].params.idLocker,
            status: this.lockingList[i].status, plannedDateTime: (this.lockingList[i].plannedDateTime !== undefined && this.lockingList[i].plannedDateTime !== "") ? this.lockingList[i].plannedDateTime : "", executionEndDateTime: (this.lockingList[i].executionEndDateTime !== undefined && this.lockingList[i].executionEndDateTime !== "") ? new Date(this.lockingList[i].executionEndDateTime) : "",
            maxHoldingDays: (this.lockingList[i].maxDiasEspera !== undefined && this.lockingList[i].maxDiasEspera !== "") ? this.lockingList[i].params.maxDiasEspera :""
          },
            lockingCreationDateTime: (this.lockingList[i].creationDateTime != undefined && this.lockingList[i].creationDateTime != "") ? new Date(this.lockingList[i].creationDateTime) : "",
            lockingPlannedDateTime: (this.lockingList[i].plannedDateTime != undefined && this.lockingList[i].plannedDateTime != "") ? new Date(this.lockingList[i].plannedDateTime) : "",
            lockingExecutionEndDateTime: (this.lockingList[i].executionEndDateTime != undefined && this.lockingList[i].executionEndDateTime != "") ? new Date(this.lockingList[i].executionEndDateTime) : ""
          }]);
        }
        $("#LockingsDataTable").DataTable().draw();
        this.setButtonFunctions();
        if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
        $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
      },
      async toggleNewLockingModal() {
        if (this.display.newLockingModal) {
          await this.getLockingList();
          this.displayLockingsDataTable();
        }
        this.display.newLockingModal = !this.display.newLockingModal;
      },
      async toggleEditLockingModal() {
        if (this.display.editLockingModal) {
          await this.getLockingList();
          this.displayLockingsDataTable();
        }
        this.display.editLockingModal = !this.display.editLockingModal;
      },
      async toggleDeleteLockingModal() {
        if (this.display.deleteLockingModal) {
          await this.getLockingList();
          this.displayLockingsDataTable();
        }
        this.display.deleteLockingModal = !this.display.deleteLockingModal;
      },
      async toggleCancelLockingModal() {
        if (this.display.cancelLockingModal) {
          await this.getLockingList();
          this.displayLockingsDataTable();
        }
        this.display.cancelLockingModal = !this.display.cancelLockingModal;
      },
      // BUTTONS
      setButtonFunctions() {
        setTimeout(()=>{
          $(".btnTargetLocking").get().forEach(btn => {
            btn.addEventListener('click', this.onTargetLocking);
          });
          $(".btnDetailLocking").get().forEach(btn => {
            btn.addEventListener('click', this.onDetailLocking)
          });
          $(".btnModifyLocking").get().forEach(btn => {
            btn.addEventListener('click', this.onModifyLocking);
          });
          $(".btnCancelLocking").get().forEach(btn => {
            btn.addEventListener('click', this.onCancelLocking);
          });
          $(".btnDeleteLocking").get().forEach(btn => {
            btn.addEventListener('click', this.onDeleteLocking);
          });
          $(".btnCopyId").get().forEach(btn => {
            btn.addEventListener('click', this.onCopyId);
          });
        }, 300);
      },
      onCopyId(event) {
        navigator.clipboard.writeText(event.target.getAttribute('data-id'));
      },
      // GET
      async getLockingList(callback) {
        let request = {
          nifCif: this.currentCompany.nifCif
        };
        let entryListResponse = await api.getOrderList(request);
        let entryList = api.parseOrdersFromResponse(entryListResponse)
        let refundListResponse = await api.getRefundList(request);
        let refundList = api.parseOrdersFromResponse(refundListResponse);
        
        this.lockingList = entryList.concat(refundList);
  
        if (callback && typeof callback === "function") callback(this.lockingList);
      },
      // TARGET 
      onTargetLocking(event) {
        console.log('TARGET WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetLockingId = event.target.getAttribute('data-id');
        this.targetLockingOrder=  event.target.getAttribute('data-id');
      },
      // PLAN
      onUpdateAlertValue(value) {
        this.alertValue = value;
      },
      async onModifyLocking (event){
        console.log('MODIFY WHERE :');
        console.log(event.target.getAttribute('data-id'));
        this.targetLockingId = event.target.getAttribute('data-id');
        this.toggleEditLockingModal();
      },
      // DETAILS
      async onDetailLocking(event) {
        console.log('DETAIL WHERE ID:');
        console.log(event.target.getAttribute('data-id'),this.targetLocking);
        this.targetLockingId = event.target.getAttribute('data-id');
        let response = await api.getMachineData(this.targetLocking.params.idLocker);
        if (response["status-code"] === "200") {
          let machine = api.parseMachineFromResponse(response);
          this.targetLocking.params.lockerLabel=machine.label
          this.targetLocking.params.lockerEmplacement= machine.emplacement
        }
        var tr = $(event.target).closest('tr');
        var row = $("#LockingsDataTable").DataTable().row(tr);
        if (row.child.isShown()) {
          event.target.name = "list-circle-outline";
          row.child.hide();
          tr.removeClass('shown');
        } else {
          event.target.name = "chevron-up-circle-outline";
          let content = '' +
          '<div id="resumenPedido" style ="background-color: white!important"' + this.targetLocking.id + '">' +
          '<table style=  "border-collapse: collapse;  width: 100%; padding: 6px;">' +
          '<tr >' +
          '<th style= "padding: 8px; text-align: left; font-size: 1.1rem; background-color: #f2f2f2 ;" colspan="2">'+this.dictionary["Detalles de la reserva"][this.session.user.language]+'</th>' +
          '</tr>' +
          '<tr >' +
          '<td style= "padding: 8px; text-align: left;">'+this.dictionary["ID reserva"][this.session.user.language]+'</td>' +
          '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.id + '</td>' +
          '</tr>' +
          '<tr>' +
          '<td style= "padding: 8px; text-align: left;">'+this.dictionary["ID pedido"][this.session.user.language]+'</td>' +
          '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.params.idPedido + '</td>' +
          '</tr>' +
          '<tr>' +
          '<td style="padding: 8px; text-align: left;">' + this.dictionary["Estado"][this.session.user.language] + ' '+ this.dictionary["Reserva"][this.session.user.language].toLowerCase() + '</td>'+
          '<td style= "padding: 8px; text-align: left;">' + (this.targetLocking.status === "0"
            ? this.dictionary["Creado"][this.session.user.language]
            : this.targetLocking.status === "1"
            ? this.dictionary["Planificado"][this.session.user.language]
            : this.targetLocking.status === "2"
            ? this.dictionary["Depositado"][this.session.user.language]
            : this.targetLocking.status === "3"
            ? this.dictionary["Anulando"][this.session.user.language]
            : this.targetLocking.status === "4"
            ? this.dictionary["Finalizado"][this.session.user.language]
            : this.targetLocking.status === "5"
            ? this.dictionary["Anulado"][this.session.user.language]
            : this.targetLocking.status)+ '</td>' +
          '</tr>' +
          '<tr>' +
          '<td style= "padding: 8px; text-align: left;">'+this.dictionary["Locker"][this.session.user.language]+'</td>' +
          '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.params.lockerLabel + '</td>' +
          '</tr>' +
          '<tr>' +
          '<td style= "padding: 8px; text-align: left;">'+this.dictionary["Localización"][this.session.user.language]+'</td>' +
          '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.params.lockerEmplacement + '</td>' +
          '</tr>' +
          '<tr>' +
          '<td style= "padding: 8px; text-align: left;">'+this.dictionary["Fecha prevista"][this.session.user.language]+'</td>' +
          '<td style= "padding: 8px; text-align: left;">' +   api.parseDateUserTimezone(this.targetLocking.params.fechaProgramada, this.session.user, this.$dayjs).split(' ')[0] + '</td>' +
          '</tr>' +
          '<tr>' +
          '<td style= "padding: 8px; text-align: left;">'+this.dictionary["Días de espera"][this.session.user.language]+'</td>' +
          '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.params.maxDiasEspera + '</td>' +
          '</tr>' +
          '<tr>' +
          '<td style= "padding: 8px; text-align: left;">'+this.dictionary['E-mail'][this.session.user.language] + ' '+ this.dictionary['Transportista'][this.session.user.language].toLowerCase() +'</td>' +
          '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.params.emailTransportista + '</td>' +
          '</tr>' +
          '<tr>' +
          '<td style= "padding: 8px; text-align: left;">'+this.dictionary['E-mail'][this.session.user.language] + ' '+ this.dictionary['Cliente'][this.session.user.language].toLowerCase() +'</td>' +
          '<td style= "padding: 8px; text-align: left;" >' + this.targetLocking.params.emailConsumidor + '</td>' +
          '</tr>' +
          '<tr>' +
          '<td style= "padding: 8px; text-align: left;">'+this.dictionary["Observaciones"][this.session.user.language]+'</td>' +
          '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.params.observaciones + '</td>' +
          '</tr>' +
          '<tr>' +
          '<td style= "padding: 8px; text-align: left;" colspan="2">' +
          '<p style ="font-size: 1.1rem;font-weight:bold;padding:4px; width:100%; background:#f2f2f2;margin-bottom: 1px;">'+this.dictionary["Detalle de los paquetes"][this.session.user.language]+'</p>' +
          '<table>' +
          '<tr >' +
          '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Nº de paquete"][this.session.user.language]+'</th>' +
          '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Alto"][this.session.user.language]+'</th>' +
          '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Ancho"][this.session.user.language]+'</th>' +
          '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Fondo"][this.session.user.language]+'</th>' +
          '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Peso"][this.session.user.language]+'</th>' +
          '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Tipo"][this.session.user.language]+'</th>' +
          '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Accesible"][this.session.user.language]+'</th>' +
          '</tr>';
          for (let i = 0; i < this.targetLocking.params.bultos.length; i++) {
            content += '<tr>' +
            '<td style= "padding: 8px; text-align: center;">' + this.targetLocking.params.bultos[i].id + '</td>' +
            '<td style= "padding: 8px; text-align: center;">' + this.targetLocking.params.bultos[i].alto + ' mm' + '</td>' +
            '<td style= "padding: 8px; text-align: center;">' + this.targetLocking.params.bultos[i].ancho + ' mm' + '</td>' +
            '<td style= "padding: 8px; text-align: center;">' + this.targetLocking.params.bultos[i].fondo + ' mm' + '</td>' +
            '<td style= "padding: 8px; text-align: center;">' + ((this.targetLocking.params.bultos[i].peso!=undefined)?this.targetLocking.params.bultos[i].peso:'-') + ' g' + '</td>' +
            '<td style= "padding: 8px; text-align: center;">' + ((this.targetLocking.params.bultos[i].tipo!=undefined)?this.dictionary[this.targetLocking.params.bultos[i].tipo][this.session.user.language]:'-') + '</td>' +
            '<td style= "padding: 8px; text-align: center;">' + ((this.targetLocking.params.bultos[i].accesible!=undefined)?this.dictionary[this.targetLocking.params.bultos[i].accesible][this.session.user.language]:'-') + '</td>' +
            '</tr>';
          }
          content += '</table>' +
          '</td>' +
          '</tr>' +
          '</table>' +
          '</div>';
          row.child(content).show();
          if (this.targetLockingId) {
            let detailTable = document.getElementById('detailTable' + this.targetLockingId);
            if (detailTable && detailTable.parentNode) {
                detailTable.parentNode.style.padding = 0;
            }
          }
          tr.addClass('shown');
        }
      },
      // DELETE
      async onDeleteLocking(event) {
        if (!this.display.deleteLockingModal) {
          console.log('DELETE WHERE ID:');
          console.log(event.target.getAttribute('data-id'));
          this.targetLockingId = event.target.getAttribute('data-id');
          this.idPedido=[];
          let pedidoLabel= "Identificador del pedido: "+event.target.getAttribute('data-idOrder');
          this.idPedido.push(pedidoLabel);
        }
        else {
          let response = await api.deleteOrder(this.targetLockingId);
          if (response["status-code"] === "200") {
            this.$emit('toggleNewLockingModal');
            window.alert("La reserva ha sido eliminada satisfactoriamente.");
          }
          else {
            window.alert("Error al eliminar la reserva.");
          }
        }
        this.toggleDeleteLockingModal();
      },
      //CANCEL
      async onCancelLocking(event) {
        if (!this.display.cancelLockingModal) {
          console.log('CANCEL WHERE ID:');
          console.log(event.target.getAttribute('data-id'));
          this.targetLockingId = event.target.getAttribute('data-id');
          this.idPedido=[];
          let pedidoLabel= "Identificador del pedido: "+event.target.getAttribute('data-idOrder');
          this.idLocker= event.target.getAttribute('data-idLocker'); 
          this.idPedido.push(pedidoLabel);
        }
        else {
         
          
          let response = await api.cancelOrder(this.targetLockingId,this.idLocker);
          if (response["status-code"] === "200") {
            this.$emit('toggleNewLockingModal');
            window.alert("La reserva ha sido cancelada satisfactoriamente.");
          }
          else {
            window.alert("Error al cancelada la reserva.");
          }
        }
        this.toggleCancelLockingModal();
      }
    },  
    async created() {
      await this.getLockingList()
      this.$emit('getAvailableCompanies',(this.displayLockingsDataTable));
    },
    mounted() {
      $("#LockingsDataTable").DataTable(
        {
          pagingType: 'full_numbers',
          pageLength: 10,
          processing: true,
          dom: 'Bfrtip',
          buttons: [
            { "extend": 'colvis', "className": 'btn' },
            { "extend": 'copy', "className": 'btn' },
            { "extend": 'csv', "className": 'btn' },
            { "extend": 'print', "className": 'btn me-0' },
          ],
          columns: this.lockingColumns,
          select: this.dataTableOptions.select,
          language: this.dataTableOptions.language[this.session.user.language],
          //stateSave: this.dataTableOptions.stateSave,
          order: [[4,'desc']]
        }
      );
      $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
    }
  }
  </script>
  <style>
  #prepareLockingModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }
  
  .btnCopyId:hover, .btnDetailLocking:hover,  .btnModifyLocking:hover  , .btnDeleteLocking:hover, .btnCancelLocking:hover {
    color: var(--main-dark) !important;
  }
  
  </style>