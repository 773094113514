<template>
    <!-- SECTION Incidences-->
      <div id="incidencesSection" class="row col-12 m-0 p-0">
        <div class="row col-12 p-2 m-0 section-container justify-content-center">
      
    <!-- HEADER incidences -->
          <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
            <div class="bg-menu border-1 rounded-5 box sombra">
              
              <div class="col-12 overflow-hidden justify-content-center">
                <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
                  <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
                  <span> {{dictionary["Incidencias"][session.user.language]}} </span>
                  <span v-if="targetEmplacementData.label != undefined">: <br> {{targetEmplacementData.label}} </span>
                </h6>
              </div>
    
              <div class="p-3 pt-0">
    <!-- Selector de Sistema -->
                <div class="col-12 m-0 p-0">	
                  <hr v-if="this.$route.params.currentDisplay==='incidences'" class="m-0 p-0">
    
                  <select v-if="this.$route.params.currentDisplay==='incidences'"
                  :value="currentCompany.nifCif"
                  class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
                  style="border: solid 2px white ; color: black;"
                  @change="onCurrentCompanyChanged">
                    <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                    <option v-for="company in availableCompanies" :key="company.nifCif"
                    :v-if="company.nifCif != session.user.nifCif
                    && company.relation != undefined
                    && company.relation.referenceCompany === this.session.user.nifCif"
                    :value="company.nifCif">
                      {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                    </option>
                  </select>
    
                  <hr class="m-0 p-0 mb-3">
                  
                  
                  <hr class="m-0 p-0">
    
                  <div class="col-12 p-0 justify-content-center d-flex">
                    <button id="btnDisplayNewMachineModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3" 
                    @click="toggleNewIncidenceModal">
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                       <!--  <span class="text-white mt-0 text-nowrap">{{dictionary["Añadir tipo"][session.user.language]}}</span> -->
                       <span class="text-white mt-0 text-nowrap">{{"Añadir incidencia"}}</span>
                      </span>
                    </button>
                  </div>
                </div>
    
              </div>
            </div>
    
          </div>
      
    <!-- COMPONENT Devices -->
          <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
            <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
              <!--<ion-icon v-if="session.user.bookmark==='devices'" name="bookmark" class="bookmark-button"></ion-icon>
              <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
              <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
                <ion-icon name="warning-outline" class="tx-18 mb-1 me-2 align-bottom"></ion-icon>   
                <span> {{dictionary["Incidencias"][session.user.language] }} </span>
                <span v-if="this.$route.params.currentDisplay==='incidences' || this.targetEmplacementId == ''"> 
                  {{ ": "+currentCompany.name }} 
                </span>
                <span v-else-if="this.targetEmplacementData.label != undefined"> 
                  {{ ": "+targetEmplacementData.label }} 
                </span>
              </div>
      

    
    <!-- TABLE Devices -->
              <div v-show="displayType==='table'" class="m-0 p-3 h-auto"
              id="devicesTableContent"
              :style="(!display.showEverything)?'height: 15rem !important; overflow: scroll; overflow-x: hidden;':''">
                <table id="devicesDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
                  <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                    <tr>
                      <th v-for="column in devicesColumns" :key="column.data"
                      :width="column.width" class="fw-medium text-nowrap p-2">
                        <!-- {{dictionary[column.text][session.user.language]}} -->
                        {{ column.text }}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
   
            </div>
    
          </div>
          
          <div v-if="this.$route.params.currentDisplay==='incidences'"
          class="col-12 m-0 p-2 pt-3 pb-0 d-flex flex-row">
            <hr class="tx-clomos-dark m-auto w-100 p-1">
            <ion-icon :name="(display.showEverything)?'arrow-up':'arrow-down'"
            class="bg-caja tx-clomos-dark targets-icons m-auto p-2 pt-1 pb-1 tx-30 border-1" style="vertical-align: middle; min-width:1.8rem; min-height:1.2rem; border-radius:4px;"
            @click="display.showEverything=!display.showEverything"></ion-icon>
            <hr class="tx-clomos-dark m-auto w-100 p-1">
          </div>
        </div>
      </div>
    
    <!-- SECTION historic -->
      <HistoricIncidencesComponent v-if="this.$route.params.currentDisplay==='incidences' "
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :visibility="visibility"
      :currentCompany="currentCompany"
      :requestedIncidenceId="targetIncidenceId"
      :requestedIncidenceName="targetIncidenceLabel"
      :dataTableOptions="dataTableOptions">
      </HistoricIncidencesComponent>
    
    <!-- MODAL New Incidence -->
      <NewIncidenceModal
      v-if="display.newDeviceModal"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :timeZones="timeZones"
      :currentCompany="currentCompany"
      v-on:toggleNewIncidenceModal="toggleNewIncidenceModal">
      </NewIncidenceModal>
    
    <!-- MODAL Edit Incidence -->
      <EditIncidenceModal
      v-if="display.editDeviceModal"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :timeZones="timeZones"
      :currentCompany="currentCompany"
      :deviceId="targetIncidenceId"
      v-on:toggleEditIncidenceModal="toggleEditIncidenceModal">
      </EditIncidenceModal>   
            
    <!-- MODAL Delete Text Alert -->
      <TextAlertModal
      v-if="display.deleteDeviceModal"
      :session="session"
      :dictionary="dictionary"
      :countries="countries"
      :alertHeader="dictionary['Dispositivo a eliminar'][session.user.language] + ': '"
      :alertTarget="targetIncidenceId"
      v-on:acceptAlert="onDeleteDevice"
      v-on:dismissAlert="toggleDeleteDeviceModal">
      </TextAlertModal>
    </template>
    
<script>
import $ from 'jquery';
import HistoricIncidencesComponent from '@/components/incidences/HistoricIncidencesComponent';
import NewIncidenceModal from '@/components/incidences/NewIncidenceModal';
import EditIncidenceModal from '@/components/incidences/EditIncidenceModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "DevicesComponent",
  components: { HistoricIncidencesComponent, NewIncidenceModal, EditIncidenceModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    targetEmplacementId: {
      type: String,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      if (this.$route.params.currentDisplay === 'incidences' || this.targetEmplacementId == '') {
        await this.getDevicesDataByCompany();
      }
      else {
        await this.getEmplacementData();
        await this.getDevicesDataByEmplacement();
      }
      this.onChangeDisplayType(this.displayType)
      if (this.incidenceList[0] != undefined && this.targetIncidenceId === "")
        this.targetIncidenceId = this.incidenceList[0].id;
        this.targetIncidenceLabel= this.incidenceList[0].label;
    },
    targetEmplacementId: async function() {
      if (this.$route.params.currentDisplay === 'incidences' || this.targetEmplacementId == '') {
        await this.getDevicesDataByCompany();
      }
      else {
        await this.getEmplacementData();
        await this.getDevicesDataByEmplacement();
      }
      this.onChangeDisplayType(this.displayType)
      if (this.incidenceList[0] != undefined && this.targetIncidenceId === "")
        this.targetIncidenceId = this.incidenceList[0].id;
        this.targetIncidenceLabel= this.incidenceList[0].label;
    }
  },
  data() {
    return {
      devicesColumns: [
        {text:"Identificador", data: "incidenceId", width: "25%", className:"tx-left align-middle tx-12",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-16 tx-clomos-midtone disabled'></ion-icon>"
            +"<span data-id="+data+" class='btnTargetDevice c-pointer c-pointer tx-bold tx-clomos-midtone text-nowrap align-middle'>"+data+"</span>";
          }
        },
        {text:"Nombre", data: "incidenceLabel", width: "15%", className:"tx-left align-middle tx-12"},     
        {text:"Nivel", data: "incidenceLevel", width: "15%", className:"tx-left align-middle tx-12"},    
        {text:"Aviso mail", data: "incidendeMail", width: "10%", className:"tx-left align-middle justify-content-center tx-12"},
        {text:"Aviso sms", data: "incidendeSms", width: "10%", className:"tx-left align-middle justify-content-center tx-12"},        
        {text:"Configuración", data: "incidenceId", width: "5%", className:"align-middle tx-12",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id="+data+" class='btnEditDevice btn tx-16 tx-primary disabled'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "incidenceId", width: "5%", className:"align-middle tx-12",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteDevice btn tx-16 tx-danger disabled'></ion-icon>"
          }
        },
      ],
      targetEmplacementData: {},
      targetIncidenceId: this.$route.params.targetEntity,
      targetIncidenceLabel:"",
      incidenceList: [],
      emplacementList: [],
      devicesWithoutParent: [],
      devicesMap: undefined,
      displayType : "table",
      deviceCardsPage: 0,
      display: {
        showEverything: false,
        newDeviceModal: false,
        editDeviceModal: false,
        linkDeviceModal: false,
        deleteDeviceModal: false,
      },
      linkAction: "link"
    }
  },

  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    onChangeDisplayType(type) {
      this.displayType = type;
      setTimeout(()=>{
        if (type === "table") this.displayDevicesDataTable();
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.deviceCardsPage = index;
      this.setButtonFunctions();
    },  
    displayDevicesDataTable() {
      $("#devicesDataTable").DataTable().clear()
      for (let i in this.incidenceList) {
        let parentLabel = "";
        for (let ii in  this.emplacementList) {
          if (this.emplacementList[ii].id === this.incidenceList[i].parent) {
            parentLabel = this.emplacementList[ii].label;
            break;
          }
        }
        $("#devicesDataTable").DataTable().rows.add([{
          incidenceId: this.incidenceList[i].id, 
          incidenceLabel: this.incidenceList[i].label, 
          incidenceLevel: this.incidenceList[i].level,
          incidendeMail: this.incidenceList[i].mail==="true"?"SI":"NO",
          incidendeSms: this.incidenceList[i].sms==="true"?"SI":"NO",
          deviceParent: {parentId: this.incidenceList[i].parent, parentLabel:parentLabel, incidenceId:this.incidenceList[i].id}, 
          deviceVersion: this.incidenceList[i].version, 
        }]);
      }
      $("#devicesDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async toggleNewIncidenceModal() {
      if (this.display.newDeviceModal) {
        if (this.$route.params.currentDisplay === 'incidences' || this.targetEmplacementId == '') {
          await this.getDevicesDataByCompany();
        }
        else {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        this.onChangeDisplayType(this.displayType)
      }
      this.display.newDeviceModal = !this.display.newDeviceModal;
    },
    async toggleEditIncidenceModal() {
      if (this.display.editDeviceModal) {
        if (this.$route.params.currentDisplay === 'incidences' || this.targetEmplacementId == '') {
          await this.getDevicesDataByCompany();
        }
        else {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        this.onChangeDisplayType(this.displayType)
      }
      this.display.editDeviceModal = !this.display.editDeviceModal;
    },
    async toggleLinkDeviceModal() {
      if (this.display.linkDeviceModal) {
        if (this.$route.params.currentDisplay === 'incidences' || this.targetEmplacementId == '') {
          await this.getDevicesDataByCompany();
        }
        else {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        this.onChangeDisplayType(this.displayType)
      }
      this.display.linkDeviceModal = !this.display.linkDeviceModal;
    },
    async toggleDeleteIncidenceModal() {
      if (this.display.deleteDeviceModal) {
        if (this.$route.params.currentDisplay === 'incidences' || this.targetEmplacementId == '') {
          await this.getDevicesDataByCompany();
        }
        else {
          await this.getEmplacementData();
          await this.getDevicesDataByEmplacement();
        }
        this.onChangeDisplayType(this.displayType)
        if (this.incidenceList[0] != undefined) {this.targetIncidenceId = this.incidenceList[0].id; this.targetIncidenceLabel= this.incidenceList[0].label;}
      }
      this.display.deleteDeviceModal = !this.display.deleteDeviceModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetDevice);
        });
        $(".btnEditDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onEditDevice);
        });      
        $(".btnDeleteDevice").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteDevice);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    async getDevicesDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      if(this.currentCompany.nifCif==="Mapner"){
        this.incidenceList = [
    {
        "id": "1",
        "label": "Temperatura elevada",
        "descripcion": "Esta incidencia describe una temperatura elevada en los equipos",
        "rolesAviso": "255",
        "mail": "false",
        "sms": "true",
        "avisoEstado": "true",
        "tiempoRepeticion": "600",
        "level": "1"
    },
    {
        "id": "2",
        "label": "Fuego",
        "descripcion": "Esta incidencia describe la presencia de fuego en la ubicación o equipo.",
        "rolesAviso": "255",
        "mail": "false",
        "sms": "true",
        "avisoEstado": "true",
        "tiempoRepeticion": "600",
        "level": "4"
    },
    {
        "id": "3",
        "label": "Atrapamiento",
        "descripcion": "Esta incidencia describe una situación donde alguien o algo queda atrapado en equipos o estructuras.",
        "rolesAviso": "255",
        "mail": "false",
        "sms": "true",
        "avisoEstado": "true",
        "tiempoRepeticion": "600",
        "level": "4"
    },
    {
        "id": "4",
        "label": "Fuga de gas",
        "descripcion": "Esta incidencia describe una fuga de gas en la ubicación o equipo.",
        "rolesAviso": "255",
        "mail": "false",
        "sms": "true",
        "avisoEstado": "true",
        "tiempoRepeticion": "600",
        "level": "2"
    },
    {
        "id": "5",
        "label": "Intrusión",
        "descripcion": "Esta incidencia describe una intrusión o presencia no autorizada en el área protegida.",
        "rolesAviso": "255",
        "mail": "false",
        "sms": "true",
        "avisoEstado": "true",
        "tiempoRepeticion": "600",
        "level": "2"
    },
    {
        "id": "6",
        "label": "Pérdida de energía",
        "descripcion": "Esta incidencia describe una interrupción o pérdida de suministro eléctrico en la ubicación.",
        "rolesAviso": "255",
        "mail": "false",
        "sms": "true",
        "avisoEstado": "true",
        "tiempoRepeticion": "600",
        "level": "2"
    },
    {
        "id": "7",
        "label": "Robo",
        "descripcion": "Esta incidencia describe un robo o hurto de bienes en la ubicación.",
        "rolesAviso": "255",
        "mail": "false",
        "sms": "true",
        "avisoEstado": "true",
        "tiempoRepeticion": "600",
        "level": "2"
    },
    {
        "id": "8",
        "label": "Inundación",
        "descripcion": "Esta incidencia describe una situación de inundación en la ubicación.",
        "rolesAviso": "255",
        "mail": "false",
        "sms": "true",
        "avisoEstado": "true",
        "tiempoRepeticion": "600",
        "level": "2"
    }
];
console.log(this.incidenceList[1])
 
      }else{
       /*  let response = await api.getDeviceListByCompany(request);
      this.incidenceList = api.parseDevicesFromResponse(response); */
      request
      }
    
      if (callback && typeof callback === "function") callback(this.incidenceList);
    },
    async getDevicesDataByEmplacement(callback) {
      let request = {
        emplacementId: this.targetEmplacementId,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getincidenceListByEmplacement(request);
      this.incidenceList = api.parseDevicesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.incidenceList);
    },
    async getEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetEmplacementData);
    },
    // TARGET 
    onTargetDevice(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetIncidenceId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetIncidenceId}})
      
      let filter = this.incidenceList.filter(incidence => incidence.id === this.targetIncidenceId);
if (filter.length > 0) {
    this.targetIncidenceLabel = filter[0].label;
} else {
    this.targetIncidenceLabel = null; 
}
    },
    // SET 
    onEditDevice(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetIncidenceId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetIncidenceId}})
      this.toggleEditIncidenceModal();
    },
 
    // DELETE
    async onDeleteDevice(event) {
      if (!this.display.deleteDeviceModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetIncidenceId);
        this.targetIncidenceId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetIncidenceId}})
      }
      else {
        let response = await api.deleteDevice(this.targetIncidenceId);
        if (response["status-code"] === "200") {
          window.alert("El dispositivo ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el dispositivo.");
        }
      }
      await this.toggleDeleteIncidenceModal();
    }
  },
  async created() {
    await this.getEmplacementList();
    if (this.$route.params.currentDisplay === 'incidences') {
      await this.getDevicesDataByCompany();
    }
    else if (this.targetEmplacementId != ''){
      await this.getEmplacementData();
      await this.getDevicesDataByEmplacement();
    }
    this.$emit('getAvailableCompanies',this.onChangeDisplayType(this.displayType));
    if (this.incidenceList[0] != undefined && this.targetIncidenceId === "")
      this.targetIncidenceId = this.incidenceList[0].id;    
  },
  mounted() {
    $("#devicesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.devicesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btonCopyId:hover, .btnTargetDevice:hover, .btnLinkDevice:hover, .btnUnlinkDevice:hover, .btnEditDevice:hover, .btnDeleteDevice:hover {
  color: var(--main-dark) !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  padding: 0.5rem !important;
}

*:disabled {
  background-color: dimgrey;
  color: linen;
  opacity: 1;
}

</style>