<template>
<!-- MODAL New Product -->
  <div id="newProductModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
      
<!-- HEAD New Product -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="cube" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary['Nuevo producto'][session.user.language]}}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewProductModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT New Product -->
      <div class="bg-caja col-12 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

            <form class="p-1 m-0 border rounded form-material">
              <div class="bg-menu text-white p-1 m-0 rounded"> 
                {{dictionary['Datos del producto'][session.user.language]}}
              </div>

              <div class="row pt-3">
                
<!-- NOMBRE -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Nombre'][session.user.language]}}
                  </label>
                  <div class="col-12">
                    <input v-model="newProductData.label" id="inputNewProductLabel" type="text" 
                    class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

<!-- REFERENCIA -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Referencia'][session.user.language]}}
                  </label>
                  <div class="col-12">
                    <input v-model="newProductData.reference" id="inputNewProductReference" type="text" 
                    class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

              </div>

              <div class="row">

<!-- DESCRIPCIÓN -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Descripción'][session.user.language]}}
                  </label>
                  <div class="col-12">
                    <textarea v-model="newProductData.description" id="inputNewProductDescription" type="text" rows="1"
                    class="form-control form-control-line" style="color:black;"/>
                  </div>
                </div>
              
              </div>
            
              <div class="row">
              
<!-- UNIDAD DE MEDIDA -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Unidad de medida'][session.user.language]}}
                  </label>
                  <div class="col-12">
                    <select v-model="newProductData.unit"
                    id="inputNewProductUnit"
                    class="form-control form-control-line" style="color:black;">
                      <option value="Kg">
                        {{dictionary["Kilo"][session.user.language]}}
                      </option>
                      <option value="M">
                        {{dictionary["Metro"][session.user.language]}}
                      </option>
                      <option value="L">
                        {{dictionary["Litro"][session.user.language]}}
                      </option>
                      <option value="unidades">
                        {{dictionary["Unidad"][session.user.language]}}
                      </option>
                    </select>
                  </div>
                </div>

<!-- PESO POR UNIDAD -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Peso por unidad'][session.user.language]}} <span class="ps-1">(gr)</span>
                  </label>
                  <div class="col-12">
                    <input v-model="newProductData.unitWeight" id="inputNewProductUnitWeight" type="number" min=0
                    class="form-control form-control-line" style="color:black;">
                  </div>
                </div>
        
              </div>

              <div class="row">
              
<!-- APILABLE -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Apilable'][session.user.language]}}
                  </label>
                  <div class="col-12" style="cursor:pointer">
                    <div id="inputNewProductStackable" 
                    class="form-control border-0 tx-bold w-100"
                    :class="newProductData.stackable"
                    @click="(newProductData.stackable==='true')?newProductData.stackable='false':newProductData.stackable='true'">
                      {{ dictionary[newProductData.stackable][session.user.language] }}
                  </div>
                  </div>
                </div>

<!-- TEMERATURA DE CONSERVACIÓN -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Temperatura de conservación'][session.user.language]}}
                  </label>
                  <div class="col-12">
                    <select v-model="newProductData.conservationTemperature"
                    id="selectNewProductConservationTemperature"
                    class="form-control form-control-line" style="color:black;">
                      <option value="frio">
                        {{dictionary['Frío'][session.user.language]}}
                      </option>
                      <option value="ambiente">
                        {{dictionary['Ambiente'][session.user.language]}}
                      </option>
                      <option value="ultracongelado">
                        {{dictionary['Ultracongelado'][session.user.language]}}
                      </option>
                    </select>
                  </div>
                </div>

              </div>

              <div class="row">
<!-- CATEGORÍA -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Categoría'][session.user.language]}}
                  </label>
                  <div class="col-12">
                    <select v-model="newProductData.category"
                    id="selectNewProductCategory"
                    class="form-control form-control-line" style="color:black;">
                      <option value="Alimentación">
                        {{dictionary["Alimentación"][session.user.language]}}
                      </option>
                      <option value="Arte">
                        {{dictionary["Arte"][session.user.language]}}
                      </option>
                      <option value="Bebidas">
                        {{dictionary["Bebidas"][session.user.language]}}
                      </option>
                      <option value="Belleza e higiene">
                        {{dictionary["Belleza e higiene"][session.user.language]}}
                      </option>
                      <option value="Calzado">
                        {{dictionary["Calzado"][session.user.language]}}
                      </option>
                      <option value="Complementos">
                        {{dictionary["Complementos"][session.user.language]}}
                      </option>
                      <option value="Conservas">
                        {{dictionary["Conservas"][session.user.language]}}
                      </option>
                      <option value="Decoración">
                        {{dictionary["Decoración"][session.user.language]}}
                      </option>
                      <option value="Disfraces">
                        {{dictionary["Disfraces"][session.user.language]}}
                      </option>
                      <option value="Dulces y chocolates">
                        {{dictionary["Dulces y chocolates"][session.user.language]}}
                      </option>
                      <option value="Electricidad e iluminación">
                        {{dictionary["Electricidad e iluminación"][session.user.language]}}
                      </option>
                      <option value="Embutidos y carnes">
                        {{dictionary["Embutidos y carnes"][session.user.language]}}
                      </option>
                      <option value="Frescos">
                        {{dictionary["Frescos"][session.user.language]}}
                      </option>
                      <option value="Hombre">
                        {{dictionary["Hombre"][session.user.language]}}
                      </option>
                      <option value="Libros">
                        {{dictionary["Libros"][session.user.language]}}
                      </option>
                      <option value="Lácteos">
                        {{dictionary["Lácteos"][session.user.language]}}
                      </option>
                      <option value="Mascotas">
                        {{dictionary["Mascotas"][session.user.language]}}
                      </option>
                      <option value="Moda">
                        {{dictionary["Moda"][session.user.language]}}
                      </option>
                      <option value="Muebles">
                        {{dictionary["Muebles"][session.user.language]}}
                      </option>
                      <option value="Mujer">
                        {{dictionary["Mujer"][session.user.language]}}
                      </option>
                      <option value="Otros">
                        {{dictionary["Otros"][session.user.language]}}
                      </option>
                      <option value="Vinos">
                        {{dictionary["Vinos"][session.user.language]}}
                      </option>
                    </select>
                  </div>
                </div>

<!-- PRIORIDAD DE SALIDA -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Prioridad de salida'][session.user.language]}}
                  </label>
                  <div class="col-12">
                    <select v-model="newProductData.priority"
                    id="selectNewProductPriority"
                    class="form-control form-control-line" style="color:black;">
                      <option value="FIFO">
                        {{dictionary['Fecha de entrada'][session.user.language]}} (FIFO)
                      </option>
                      <option value="LIFO">
                        {{dictionary['Fecha de salida'][session.user.language]}} (LIFO)
                      </option>
                      <option value="FEFO">
                        {{dictionary['Fecha de caducidad'][session.user.language]}} (FEFO)
                      </option>
                    </select>
                  </div>
                </div>

              </div>

              <div class="row">

<!-- TIENDA -->
                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Empresa'][session.user.language]}}
                  </label>
                  <div class="col-12">
                    <select
                    v-model="newProductData.nifCif"
                    class="form-control form-control-line" style="color:black;">
                      <option v-for="company in availableCompanies" :key="company.nifCif"
                      :v-if="company.nifCif != session.user.nifCif
                      && company.relation != undefined
                      && company.relation.referenceCompany === this.session.user.nifCif"
                      :value="company.nifCif">
                        {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                      </option>
                    </select>
                  </div>
                </div>
                
              </div>

              <div class="col-12 p-0 ps-1 pe-1">
                <hr class="tx-clomos-dark ms-2 me-2">
              </div>
              
              <div class="row m-0 ps-1">
                <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-2">
                  {{dictionary['Dimensiones'][session.user.language]}} <span class="ps-1 small">(mm)</span>
                </label>
              </div>

              <div class="row">
<!-- ALTO -->
                <div class="form-group col-md-4 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Alto'][session.user.language]}}
                  </label>
                  <div class="col-12">
                    <input v-model="newProductData.volume.height" id="inputNewProductHeight" type="number" min=0
                    class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

<!-- ANCHO -->
                <div class="form-group col-md-4 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Ancho'][session.user.language]}}
                  </label>
                  <div class="col-12">
                    <input v-model="newProductData.volume.width" id="inputNewProductWidth" type="number" min=0
                    class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

<!-- FONDO -->
                <div class="form-group col-md-4 col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{dictionary['Fondo'][session.user.language]}}
                  </label>
                  <div class="col-12">
                    <input v-model="newProductData.volume.depth" id="inputNewProductDepth" type="number" min=0
                    class="form-control form-control-line" style="color:black;">
                  </div>
                </div>

              </div>

              <div class="row">

<!-- SUBMIT -->
                <div class="form-group col-12 p-1">
                  <div class="col-12 mt-2 mb-2">
                    <button type="button" @click="onCreateNewProduct"
                    id="btnSubmitCreateNewProduct" class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">{{dictionary['Crear nuevo producto'][session.user.language]}}</span>
                      </span>
                    </button>
                  </div>
                </div>

              </div>
              
            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {  
  name: "NewProductModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    }
  },
  data() {
    return {
      newProductData: {
        nifCif: this.$route.params.currentCompanyNifCif,
        label: "",
        description: "",
        category: "Otros",
        unit: "unidades",
        conservationTemperature: "ambiente",
        priority: "FIFO",
        stackable: "false",
        logisticProfile: "",
        batch: "false",
        serialNumber: "false",
        size: "false",
        color: "false",
        expirationDate: "false",
        unitWeight: 0,
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        enabledSlots: {} 
      },
    }
  },
  emits: ["toggleNewProductModal"],
  methods: {
    // SET
    async onCreateNewProduct() {
      let valid = true;
      if (this.newProductData.label === "") {
        $("#inputNewProductLabel").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newProductData.description === "") {
        $("#inputNewProductDescription").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newProductData.unit === "") {
        $("#inputNewProductUnit").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newProductData.unitWeight === "") {
        $("#inputNewProductUnitWeight").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newProductData.volume.height === 0) {
        $("#inputNewProductHeight").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newProductData.volume.width === 0) {
        $("#inputNewProductWidth").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newProductData.volume.depth === 0) {
        $("#inputNewProductDepth").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewProductLabel").get()[0].classList.remove("missing")
          $("#inputNewProductDescription").get()[0].classList.remove("missing")
          $("#inputNewProductUnit").get()[0].classList.remove("missing")
          $("#inputNewProductUnitWeight").get()[0].classList.remove("missing")
          $("#inputNewProductHeight").get()[0].classList.remove("missing")
          $("#inputNewProductWidth").get()[0].classList.remove("missing")
          $("#inputNewProductDepth").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      let response = await api.postNewProduct(this.newProductData);
      if (response["status-code"] === "201") {
        this.$emit('toggleNewProductModal');
        window.alert("El producto ha sido creado satisfactoriamente.");
      }
      else {
        window.alert("Error al crear el producto.");
      }
    }
  }
}
</script>

<style>

#newProductModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>