<template>
  <!-- MODAL New Locking -->
    <div id="newLockingModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
      <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
  <!-- HEAD -->
        <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
          <div class="col-10">
            <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
              <ion-icon name="lock-closed-outline" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary["Modificar reserva"][session.user.language]}} 
            </h6>
          </div>
  
          <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleEditLockingModal')">	
            <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
          </div>
        </div>
  
  <!-- CONTENT Prepare Locking -->
        <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
          <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;" class="p-3">
            <div class="col-12 p-3 border rounded form-material" style="overflow: auto; overflow-y: hidden; display: flex; flex-flow: wrap;">
  
  <!-- CONTENT New Locking -->
              <form class="col-12 m-0 p-0">
  
  <!-- LOCKING DATA -->
                <div class="col-12 m-0 p-0">
                  <label class="col-12 m-0 p-0 tx-bold tx-clomos-dark text-nowrap">
                    {{dictionary['Datos de la reserva'][session.user.language]}}
                  </label>
                </div>
  
                <div class="row col-12 m-0 p-0">
  
                  <div class="form-group col-lg-6 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['ID pedido'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="editLockingData.id" id="inputEditLockingId" type="text"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>
  
                  <div class="form-group col-lg-6 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Observaciones'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <textarea v-model="editLockingData.params.observaciones" id="inputEditLockingObservations" rows="1"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;"/>
                    </div>
                  </div>
  
                </div>
  
                <div class="row col-12 m-0 p-0">
  
                  <div class="form-group col-lg-6 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['E-mail'][session.user.language] + " (" + dictionary['Cliente'][session.user.language] + ")" }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="editLockingData.params.emailConsumidor" id="inputEditLockingUserEmail" type="text"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>
  
                  <div class="form-group col-lg-6 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['E-mail'][session.user.language] + " (" + dictionary['Transportista'][session.user.language] + ")" }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="editLockingData.params.emailTransportista" id="inputEditLockingTransportEmail" type="text"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>
                  
                </div>
  
  <!-- DELIVEY DATA -->
                <div class="col-12 m-0 p-0">
                  <label class="col-12 m-0 p-0 tx-bold tx-clomos-dark text-nowrap">
                    {{dictionary['Datos de la entrega'][session.user.language]}}
                  </label>
                </div>
  
                <div class="row col-12 m-0 p-0">
  
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Fecha prevista'][session.user.language]}}
                    </label>
                    <div class="col-12 p-0">
                      <input v-model="editLockingData.params.fechaProgramada.split(' ')[0]" id="inputEditLockingScheduledDateTime" type="date"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>
  
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Días de espera'][session.user.language]}}
                    </label>
                    <div class="col-12 p-0">
                      <input v-model="editLockingData.params.maxDiasEspera" id="inputEditLockingMaxHoldingDays" type="number" min=1
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>
  
                </div> 
                
                <div class="row">
  <!-- SUBMIT -->
  
                  <div class="form-group col-lg-6 col-12 p-0 pt-3 m-0 ms-auto me-auto">
                    <div class="col-md-12">
                      <button type="button" id="btnSubmitCreateEditLocking" class="button-ok gradient-tablas rounded-3"
                      @click="setEditLockingData">
                        {{ dictionary['Modificar reserva'][session.user.language] }}
                      </button>
                    </div>
                  </div>
  
                </div>
  
              </form>
                
            </div>
          </div>
        </div>
        
      </div>
    </div>
  
  </template>
  
  <script>
  import $ from 'jquery';
  
  // Clomos
  import api from '@/services/api';
  
  export default {
    name: "EditLockingModal",
    props: {
      session: {
        type: Object,
        requiered: true,
      },
      dictionary: {
        type: Object,
        requiered: true,
      },
      countries: {
        type: Object,
        requiered: true,
      },
      currentCompany: {
        type: Object,
        requiered: true,
      },
      availableCompanies: {
        type: Object,
        requiered: true,
      },
      productId: {
        type: String,
        requiered: true,
      },
      lockingId: {
        type: String,
        requiered: true,
      },
    },
    data() {
      return {
        editLockingData: {
          userId: this.session.user.id,
          params: {
            observaciones: "",
            emailConsumidor: "",
            emailTransportista: "",
            fechaProgramada: "",
            maxDiasEspera: ""
          },
        },
        emplacementList: [],
        lockerList: [],
        lockersMap: undefined,
        targetLocker: {},
        inputNewBulgeId: "",
        inputNewBulgeWidth: 0,
        inputNewBulgeHeight: 0,
        inputNewBulgeLength: 0,
      }
    },
    emits: ["toggleEditLockingModal"],
    methods: {
      // GET
      async getOrderData(callback) {
        let response = await api.getOrderData(this.lockingId);
        this.editLockingData = api.parseOrderFromResponse(response);
        if (this.editLockingData.summary != undefined && this.editLockingData.summary != "") {
          this.editLockingData.summary = JSON.parse(this.editLockingData.summary);
        }
        if (callback && typeof callback === "function") callback(this.editLockingData)
      },
      // SET
      async setEditLockingData() {
        let valid = true;
        if (this.editLockingData.id == "") {
          $("#inputEditLockingId").get()[0].classList.add("missing")
          valid = false;
        }
        if (this.editLockingData.params.emailConsumidor == "" || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.editLockingData.params.emailConsumidor))) {
          $("#inputEditLockingUserEmail").get()[0].classList.add("missing")
          valid = false;
        }
        if (this.editLockingData.params.emailTransportista == "" || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.editLockingData.params.emailTransportista))) {
          $("#inputEditLockingTransportEmail").get()[0].classList.add("missing")
          valid = false;
        }
        if (this.editLockingData.params.maxDiasEspera == "") {
          $("#inputEditLockingMaxHoldingDays").get()[0].classList.add("missing")
          valid = false;
        }
  
        if (!valid) {
          setTimeout(() => {
            $("#inputEditLockingId").get()[0].classList.remove("missing")
            $("#inputEditLockingUserEmail").get()[0].classList.remove("missing")
            $("#inputEditLockingTransportEmail").get()[0].classList.remove("missing")
          }, 3000);
          return;
        }
        this.editLockingData.userId = this.session.user.id;
        let response = await api.setLockingData(this.editLockingData);
        if (response["status-code"] === "200") {
          this.$emit('toggleEditLockingModal');
          window.alert("La reserva ha modificada creada satisfactoriamente.");
        }
        else {
          window.alert("Error al modificar la reserva.");
        }
      }
    },
    async created() {
      await this.getOrderData();
    }
  }
  </script>
  
  <style>
  
  #newLockingModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }
  
  .targets-icons:hover {
    color: var(--main-dark-50);
  }
  
  @media (max-width: 767px) {
    #stockImgUploadFormContainer {
      flex-direction: column;
    }
  }
  
  .loader {
    color: white !important;
    visibility: visible;
    transition: all 0.3s;
  }
  
  .requesting {
    animation: spin 3s infinite linear;
    transition: all 0.3s;
  }
  
  @keyframes spin {
      from {
          transform: rotate(0deg);
      }
      to {
          transform: rotate(360deg);
      }
  }
  </style>