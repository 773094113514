<template>
<!-- SECTION Lockings-->
  <div id="LockingsSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      
<!-- HEADER Lockings -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5" >
        <div class="bg-menu border-1 rounded-5 box sombra">
          
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
            <ion-icon name="alert-circle-outline" class="tx-18 me-2 align-bottom"></ion-icon>
              <!-- {{dictionary["Reservas"][session.user.language]}} -->
              {{"Historico incidencias" }}
            </h6>
          </div>

          <div class="p-3 pt-0">
<!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">	
              <hr class="m-0 p-0">

              <!-- <select
              :value="currentCompany.nifCif"
              class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
              style="border: solid 2px white ; color: black;"
              @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif"
                :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif"
                :value="company.nifCif">
                  {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                </option>
              </select> -->
              
              <!--<label class="col-12 m-0 p-0 pb-1 tx-white tx-14">
              {{"Estado"}}:
            </label>-->
            
            <select @change="onchangeState($event)"
            class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
            style="border: solid 2px white; color: black; display:none;">
              <option value="">Mostrar todo</option>
              <option v-for="state in uniqueStates" :key="state" :value="state">
                {{ state }}
              </option>
            </select>
              <!--<hr class="m-0 p-0">
              <label class="col-12 m-0 p-0 pb-1 tx-white tx-14">
              {{"Nivel"}}:
            </label>-->
            <select 
            class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
            style="border: solid 2px white; color: black;display:none;">
              <option value="">Mostrar todo</option>
              <option v-for="state in ([...new Set(this.lockingList.map(incidence => incidence.level))])" :key="state" :value="state">
                {{ state }}
              </option>
            </select>
              
              <div class="col-12 p-0 justify-content-center d-flex">
                <button id="btnDisplayNewLockingsModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3" 
                @click="toggleOpenNewIncidence">
                  <span class="button-text text-white">
                    <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap"><!-- {{dictionary["Añadir reserva"][session.user.language]}} --> {{ "Abrir incidencia" }}</span>
                  </span>
                </button>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>

      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
          <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
            <ion-icon name="alert-circle-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
          <!--   {{dictionary["Reservas"][session.user.language]}} -->
          {{" Historico incidencias: "+requestedIncidenceName }}
          </div>
<!-- TABLE Lockings -->
          <div class="m-0 p-3 overflow-auto">
            <table id="LockingsDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in lockingColumns" :key="column.data"
                  :width="column.width" class="fw-medium text-nowrap p-2">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>

<!-- MODAL OpenNewIncidence -->
  <OpenNewIncidenceModal
  v-if="display.newLockingModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :incidenceId="requestedIncidenceId"
  v-on:toggleOpenNewIncidence="toggleOpenNewIncidence">
  </OpenNewIncidenceModal> 
  
<!--MODAL Edit Locking-->
  <EditLockingModal
  v-if="display.editLockingModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :lockingId="targetLockingId"
  v-on:toggleEditLockingModal="toggleEditLockingModal">
  </EditLockingModal> 

  <!-- MODAL Cancel Text Alert -->
  <TextAlertModal
  v-if="display.cancelLockingModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Pedido a cancelar'][session.user.language] + ': '"
  :alertTarget="targetLockingId"
  :alertTexts="idPedido"
  v-on:acceptAlert="onCancelLocking"
  v-on:dismissAlert="toggleCancelLockingModal">
  </TextAlertModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteLockingModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Pedido a eliminar'][session.user.language] + ': '"
  :alertTarget="targetLockingId"
  :alertTexts="idPedido"
  v-on:acceptAlert="onDeleteLocking"
  v-on:dismissAlert="toggleDeleteLockingModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import OpenNewIncidenceModal from '@/components/incidences/OpenNewIncidenceModal';
import EditLockingModal from '@/components/lockers/EditLockingModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';


export default {
  name: "HistoricIncidencesComponent",
  components: {  OpenNewIncidenceModal, TextAlertModal, EditLockingModal  },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    requestedIncidenceId: {
        type: String,
        requiered: true,
    },
    requestedIncidenceName:{
        type: String,
        requiered: true,
    },
    visibility: {
        type: Object,
        requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
     
      await this.getLockingList();
      this.lockingList = this.lockingList.filter(incidence => incidence.idIncidencia === this.requestedIncidenceId);
      this.uniqueStates=[...new Set(this.lockingList.map(incidence => incidence.status))]

      this.uniqueLevel=[...new Set(this.lockingList.map(incidence => incidence.level))];
      this.displayLockingsDataTable();

    },
    requestedIncidenceName : async function(){
      await this.getLockingList();
      this.lockingList = this.lockingList.filter(incidence => incidence.idIncidencia === this.requestedIncidenceId);
      this.uniqueStates=[...new Set(this.lockingList.map(incidence => incidence.status))]
      console.log(this.uniqueStates)
      this.uniqueLevel=[...new Set(this.lockingList.map(incidence => incidence.level))];
      this.displayLockingsDataTable();
    }

  },
  data() {
    return {
      lockingColumns: [
      {text:"Albarán", data: "lockingId", width: "5%",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailLocking btn tx-20 tx-primary p-0 m-0 disabled'></ion-icon>";
          }
        },
        {
    text: "Identificador",
    data: "lockingId",
    width: "15%",
    className: "tx-left align-middle dt-nowrap",
    render: (data) => {
        let dataShorted = data;
        if (data.length > 8) {
            dataShorted = data.substring(0, 8) + '...';
        }
        return "<ion-icon name='copy-outline' data-id='" + data + "' class='btnCopyId btn tx-14 tx-clomos-midtone disabled' ></ion-icon>" +
            "<span data-id='" + data + "' class='btnTargetProduct tx-bold tx-clomos-midtone text-nowrap align-middle' " +
            "title='" + data + "'>" + dataShorted + "</span>";
    }
},
        {text:"Nombre", data: "lockingParams", width: "15%", className:"tx-left tx-12 align-middle dt-nowrap",
          render: (data) => {
            return data
          }
        },
      
        {text:"Estado", data: "lockingStatus", width: "10%", className:"tx-left tx-12 align-middle",
          render: (data) => {
            switch (data.status) {
              case "0":
                return this.dictionary["Creada"][this.session.user.language];
              case "1":
              return "Pendiente de revisión";
              case "2":
              return "En investigación"
              case "3":
                return "En proceso de resolución";
              case "4":
                return "Solucionada";
              case "5":
                return "Cerrada"
              default:
                return data.status;
            }
           
          }        
        },
        
        {text:"Fecha de creación", data: "lockingCreationDateTime", width: "10%", className:"tx-left tx-12 align-middle",
          render: (data) => {   
            return (data!="")
            ?"<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
            :"<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {text:"Modificar", data: "lockingStatus", width: "5%", className:" align-middle tx-12",
          render: (data) => {
            return (
              data.status==="4"||data.status==="5"?"<ion-icon name='settings-outline' data-id="+data.id+" class='btn tx-16 tx-primary m-0 p-0 opacity-50 disabled'></ion-icon>": "<ion-icon name='settings-outline' data-id="+data.id+" class='btnModifyLocking btn tx-16 tx-primary m-0 p-0 disabled'></ion-icon>"
              )
          }
        },
       
        {text:"Eliminar", data: "lockingStatus", width: "5%", className:"align-middle",
          render: (data) => {
            return (
            "<ion-icon name='trash-outline' data-id='" + data.id + "' data-idOrder='" + data.idOrder + "' class='btnDeleteLocking btn tx-16 tx-danger m-0 p-0 disabled'></ion-icon>"
    )
          }
        }
      ],
      targetLockingId: "",
      idPedido:[],
      idLocker:"",
      lockingList: [],
      lockingData:[],
      alertValue: '',
      bulgeInput: {
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        units: 0
      },
      bulgeList: [],
      display: {
        newLockingModal: false,       
        deleteLockingModal: false,
        editLockingModal: false,
        cancelLockingModal: false,
      },
      uniqueStates:null
    }
  },
  computed: {
    targetLocking() {
      for (let i in this.lockingList) {
        if (this.lockingList[i].id === this.targetLockingId)
          return this.lockingList[i];
      }
      return {};
    },
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onchangeState(event) {
      console.log("Original Locking List:", this.originalLockingList);
      console.log("Filtered Locking List:", this.lockingList);
    const selectedState = event.target.value;
    console.log("Selected State:", selectedState);
    if (selectedState !== "") {
        // Filter the locking list based on selected state and requested incidence ID
        this.lockingList = this.originalLockingList.filter(incidence => incidence.status === selectedState && incidence.idIncidencia === this.requestedIncidenceId);
    } else {
        // If no state is selected, show all lockings for the requested incidence ID
        this.lockingList = this.originalLockingList.filter(incidence => incidence.idIncidencia === this.requestedIncidenceId);
    }
    // Update the displayed table
    this.displayLockingsDataTable();
},
    onchangeLevel(value){
      
      if(value!=""){
            this.lockingList = this.lockingList.filter(incidence => incidence.level === value);
          }
          },
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayLockingsDataTable() {
      $("#LockingsDataTable").DataTable().clear()
      for (let i in this.lockingList) {
        $("#LockingsDataTable").DataTable().rows.add([{
          lockingId: this.lockingList[i].idHistoricoIncidencia, 
          lockingType: this.lockingList[i].type,
          lockingParams: this.requestedIncidenceName,
          locking: this.lockingList[i],
          lockingStatus: {
          id: this.lockingList[i].id,
          //idOrder: this.lockingList[i].params.idPedido,
          //idLocker:this.lockingList[i].params.idLocker,
          status: this.lockingList[i].status,
          //maxHoldingDays: (this.lockingList[i].maxDiasEspera !== undefined && this.lockingList[i].maxDiasEspera !== "") ? this.lockingList[i].params.maxDiasEspera :""
        },
          lockingCreationDateTime: (this.lockingList[i].openDate != undefined && this.lockingList[i].openDate != "") ? new Date(this.lockingList[i].openDate) : "",
          //lockingPlannedDateTime: (this.lockingList[i].plannedDateTime != undefined && this.lockingList[i].plannedDateTime != "") ? new Date(this.lockingList[i].plannedDateTime) : "",
          //lockingExecutionEndDateTime: (this.lockingList[i].executionEndDateTime != undefined && this.lockingList[i].executionEndDateTime != "") ? new Date(this.lockingList[i].executionEndDateTime) : ""
        }]);
      }
      $("#LockingsDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async toggleOpenNewIncidence() {
      if (this.display.newLockingModal) {
        await this.getLockingList();
        this.displayLockingsDataTable();
      }
      this.display.newLockingModal = !this.display.newLockingModal;
    },
    async toggleEditLockingModal() {
      if (this.display.editLockingModal) {
        await this.getLockingList();
        this.displayLockingsDataTable();
      }
      this.display.editLockingModal = !this.display.editLockingModal;
    },
    async toggleDeleteLockingModal() {
      if (this.display.deleteLockingModal) {
        await this.getLockingList();
        this.displayLockingsDataTable();
      }
      this.display.deleteLockingModal = !this.display.deleteLockingModal;
    },
    async toggleCancelLockingModal() {
      if (this.display.cancelLockingModal) {
        await this.getLockingList();
        this.displayLockingsDataTable();
      }
      this.display.cancelLockingModal = !this.display.cancelLockingModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetLocking").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetLocking);
        });
        $(".btnDetailLocking").get().forEach(btn => {
          btn.addEventListener('click', this.onDetailLocking)
        });
        $(".btnModifyLocking").get().forEach(btn => {
          btn.addEventListener('click', this.onModifyLocking);
        });
        $(".btnCancelLocking").get().forEach(btn => {
          btn.addEventListener('click', this.onCancelLocking);
        });
        $(".btnDeleteLocking").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteLocking);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getLockingList(callback) {
      this.originalLockingList = [...this.lockingList];
      let request = {
        nifCif: this.currentCompany.nifCif
      };console.log(this.currentCompany.nifCif)
      if(this.currentCompany.nifCif==="Mapner"){
        
        this.lockingList = [
    {
        "idHistoricoIncidencia": "04de19e9-de82-4eb8-86bc-0913aeef80a7",
        "idIncidencia": "3",
        "label": "Se detecta atrapamiento en bomba aire1",
        "status": "1",
        "dateStatusNow": "2024-05-13 12:27:23.000",
        "openDate": "2024-05-13 12:27:23.000",
        "investigationDate": "",
        "processDate": "",
        "solvedDate": "",
        "closedDate": "",
        "level": "3"
    },
    {
        "idHistoricoIncidencia": "f60bba7d-0c35-44c1-8e2c-62d51e6f45a7",
        "idIncidencia": "3",
        "label": "Atrapamiento en sistema de ventilación",
        "status": "1",
        "dateStatusNow": "2024-05-14 14:32:11.000",
        "openDate": "2024-05-14 13:25:00.000",
        "investigationDate": "2024-05-14 14:00:00.000",
        "processDate": "2024-05-14 14:10:00.000",
        "solvedDate": "",
        "closedDate": "",
        "level": "1"
    },
    {
        "idHistoricoIncidencia": "f1c8b7d1-b763-421d-b616-9142a3a25e7f",
        "idIncidencia": "1",
        "label": "Temperatura elevada en servidor 1",
        "status": "3",
        "dateStatusNow": "2024-05-15 10:15:42.000",
        "openDate": "2024-05-15 10:15:42.000",
        "investigationDate": "",
        "processDate": "",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "3d8e7e6b-1234-4f5e-bf0c-38c9f2c3d9d1",
        "idIncidencia": "1",
        "label": "Alta temperatura en equipo de refrigeración",
        "status": "1",
        "dateStatusNow": "2024-05-15 11:20:18.000",
        "openDate": "2024-05-15 10:30:00.000",
        "investigationDate": "2024-05-15 10:45:00.000",
        "processDate": "2024-05-15 11:00:00.000",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "ad3c9bdf-8d49-456b-a9b3-d5d8d24c9089",
        "idIncidencia": "2",
        "label": "Fuego en sala de servidores",
        "status": "1",
        "dateStatusNow": "2024-05-16 09:30:50.000",
        "openDate": "2024-05-16 09:30:50.000",
        "investigationDate": "",
        "processDate": "",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "ed5f890a-36a4-462f-a69d-9fbf3e7e1cf7",
        "idIncidencia": "2",
        "label": "Incendio en planta de producción",
        "status": "1",
        "dateStatusNow": "2024-05-16 10:45:12.000",
        "openDate": "2024-05-16 10:00:00.000",
        "investigationDate": "2024-05-16 10:15:00.000",
        "processDate": "2024-05-16 10:30:00.000",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "56c6b7d9-5d7b-4c58-8f70-08cf5b593e8d",
        "idIncidencia": "4",
        "label": "Fuga de gas en laboratorio",
        "status": "1",
        "dateStatusNow": "2024-05-17 08:25:33.000",
        "openDate": "2024-05-17 08:25:33.000",
        "investigationDate": "",
        "processDate": "",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "89cb7c5d-2db9-47b6-8986-5b3c76d8d1e5",
        "idIncidencia": "4",
        "label": "Detección de gas en planta baja",
        "status": "1",
        "dateStatusNow": "2024-05-17 09:30:22.000",
        "openDate": "2024-05-17 08:50:00.000",
        "investigationDate": "2024-05-17 09:00:00.000",
        "processDate": "2024-05-17 09:15:00.000",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "15df76a2-9672-44c9-b6ef-8c8f4a541b96",
        "idIncidencia": "5",
        "label": "Intrusión detectada en almacén",
        "status": "1",
        "dateStatusNow": "2024-05-18 07:14:19.000",
        "openDate": "2024-05-18 07:14:19.000",
        "investigationDate": "",
        "processDate": "",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "f68d5b2d-79e3-4d2a-99f8-1c8f3d8f1d1d",
        "idIncidencia": "5",
        "label": "Alerta de intrusión en oficina principal",
        "status": "1",
        "dateStatusNow": "2024-05-18 08:20:11.000",
        "openDate": "2024-05-18 07:30:00.000",
        "investigationDate": "2024-05-18 07:45:00.000",
        "processDate": "2024-05-18 08:00:00.000",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "48f3b8a3-bc71-4c7c-8d89-9f8b7e1f5f11",
        "idIncidencia": "6",
        "label": "Pérdida de energía en laboratorio",
        "status": "1",
        "dateStatusNow": "2024-05-19 06:05:23.000",
        "openDate": "2024-05-19 06:05:23.000",
        "investigationDate": "",
        "processDate": "",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "83c8d6e7-92ab-4b5e-bc1a-9d7e3c4d1c7a",
        "idIncidencia": "6",
        "label": "Corte de energía en planta de producción",
        "status": "1",
        "dateStatusNow": "2024-05-19 07:10:45.000",
        "openDate": "2024-05-19 06:30:00.000",
        "investigationDate": "2024-05-19 06:45:00.000",
        "processDate": "2024-05-19 07:00:00.000",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "70e4b7a9-2b79-45c1-bf4d-3c8f1e1f3c3b",
        "idIncidencia": "7",
        "label": "Robo de equipo en laboratorio",
        "status": "0",
        "dateStatusNow": "2024-05-20 05:45:33.000",
        "openDate": "2024-05-20 05:45:33.000",
        "investigationDate": "",
        "processDate": "",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "c8d5b7d1-0e5b-49c5-8d91-3f4c2d2c1d3d",
        "idIncidencia": "7",
        "label": "Robo en almacén de repuestos",
        "status": "3",
        "dateStatusNow": "2024-05-20 06:50:11.000",
        "openDate": "2024-05-20 06:00:00.000",
        "investigationDate": "2024-05-20 06:15:00.000",
        "processDate": "2024-05-20 06:30:00.000",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "83d8b9d3-41b9-4e8a-9c7e-6f3d1c7a8b4b",
        "idIncidencia": "8",
        "label": "Inundación en sótano",
        "status": "2",
        "dateStatusNow": "2024-05-21 04:25:23.000",
        "openDate": "2024-05-21 04:25:23.000",
        "investigationDate": "",
        "processDate": "",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    },
    {
        "idHistoricoIncidencia": "e7c9b8a5-3b2b-4e5c-9c1a-2f3d1a1b3b9b",
        "idIncidencia": "8",
        "label": "Inundación en planta baja",
        "status": "1",
        "dateStatusNow": "2024-05-21 05:30:42.000",
        "openDate": "2024-05-21 04:45:00.000",
        "investigationDate": "2024-05-21 05:00:00.000",
        "processDate": "2024-05-21 05:15:00.000",
        "solvedDate": "",
        "closedDate": "",
        "level": "2"
    }
];

      } else {  /* let entryListResponse = await api.getOrderList(request);
      let entryList = api.parseOrdersFromResponse(entryListResponse)
      let refundListResponse = await api.getRefundList(request);
      let refundList = api.parseOrdersFromResponse(refundListResponse);
      
      this.lockingList = entryList.concat(refundList); */request}
    

      if (callback && typeof callback === "function") callback(this.lockingList);
    },
    // TARGET 
    onTargetLocking(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockingId = event.target.getAttribute('data-id');
      this.targetLockingOrder=  event.target.getAttribute('data-id');
    },
    // PLAN
    onUpdateAlertValue(value) {
      this.alertValue = value;
    },
    async onModifyLocking (event){
      console.log('MODIFY WHERE :');
      console.log(event.target.getAttribute('data-id'));
      this.targetLockingId = event.target.getAttribute('data-id');
      this.toggleEditLockingModal();
    },
    // DETAILS
    async onDetailLocking(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'),this.targetLocking);
      this.targetLockingId = event.target.getAttribute('data-id');
      let response = await api.getMachineData(this.targetLocking.params.idLocker);
      if (response["status-code"] === "200") {
        let machine = api.parseMachineFromResponse(response);
        this.targetLocking.params.lockerLabel=machine.label
        this.targetLocking.params.lockerEmplacement= machine.emplacement
      }
      var tr = $(event.target).closest('tr');
      var row = $("#LockingsDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' +
        '<div id="resumenPedido" style ="background-color: white!important"' + this.targetLocking.id + '">' +
        ' <h2 >'+this.dictionary["Resumen de la Reserva"][this.session.user.language]+'</h2>' +
        '<table style=  "border-collapse: collapse;  width: 100%;">' +
        '<tr >' +
        '<th style= "padding: 8px; text-align: left; font-size: 1.5rem; background-color: #f2f2f2 ;" colspan="2">'+this.dictionary["Detalles de la reserva"][this.session.user.language]+'</th>' +
        '</tr>' +
        '<tr >' +
        '<td style= "padding: 8px; text-align: left;">'+this.dictionary["ID reserva"][this.session.user.language]+'</td>' +
        '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.id + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style= "padding: 8px; text-align: left;">'+this.dictionary["ID pedido"][this.session.user.language]+'</td>' +
        '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.params.idPedido + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="padding: 8px; text-align: left;">' + this.dictionary["Estado"][this.session.user.language] + ' '+ this.dictionary["Reserva"][this.session.user.language].toLowerCase() + '</td>'+
        '<td style= "padding: 8px; text-align: left;">' + (this.targetLocking.status === "0"
          ? this.dictionary["Creado"][this.session.user.language]
          : this.targetLocking.status === "1"
          ? this.dictionary["Planificado"][this.session.user.language]
          : this.targetLocking.status === "2"
          ? this.dictionary["Depositado"][this.session.user.language]
          : this.targetLocking.status === "3"
          ? this.dictionary["Anulando"][this.session.user.language]
          : this.targetLocking.status === "4"
          ? this.dictionary["Finalizado"][this.session.user.language]
          : this.targetLocking.status === "5"
          ? this.dictionary["Anulado"][this.session.user.language]
          : this.targetLocking.status)+ '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style= "padding: 8px; text-align: left;">'+this.dictionary["Locker"][this.session.user.language]+'</td>' +
        '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.params.lockerLabel + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style= "padding: 8px; text-align: left;">'+this.dictionary["Localización"][this.session.user.language]+'</td>' +
        '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.params.lockerEmplacement + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style= "padding: 8px; text-align: left;">'+this.dictionary["Fecha prevista"][this.session.user.language]+'</td>' +
        '<td style= "padding: 8px; text-align: left;">' +   api.parseDateUserTimezone(this.targetLocking.params.fechaProgramada, this.session.user, this.$dayjs).split(' ')[0] + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style= "padding: 8px; text-align: left;">'+this.dictionary["Días de espera"][this.session.user.language]+'</td>' +
        '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.params.maxDiasEspera + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style= "padding: 8px; text-align: left;">'+this.dictionary['E-mail'][this.session.user.language] + ' '+ this.dictionary['Transportista'][this.session.user.language].toLowerCase() +'</td>' +
        '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.params.emailTransportista + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style= "padding: 8px; text-align: left;">'+this.dictionary['E-mail'][this.session.user.language] + ' '+ this.dictionary['Cliente'][this.session.user.language].toLowerCase() +'</td>' +
        '<td style= "padding: 8px; text-align: left;" >' + this.targetLocking.params.emailConsumidor + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style= "padding: 8px; text-align: left;">'+this.dictionary["Observaciones"][this.session.user.language]+'</td>' +
        '<td style= "padding: 8px; text-align: left;">' + this.targetLocking.params.observaciones + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style= "padding: 8px; text-align: left;" colspan="2">' +
        '<h4 stlye ="font-size: 1.5rem;">'+this.dictionary["Detalle de los paquetes"][this.session.user.language]+'</h4>' +
        '<table>' +
        '<tr >' +
        '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Nº de paquete"][this.session.user.language]+'</th>' +
        '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Alto"][this.session.user.language]+'</th>' +
        '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Ancho"][this.session.user.language]+'</th>' +
        '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Fondo"][this.session.user.language]+'</th>' +
        '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Peso"][this.session.user.language]+'</th>' +
        '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Tipo"][this.session.user.language]+'</th>' +
        '<th style= "padding: 8px; text-align: left;  background-color: #f2f2f2;">'+this.dictionary["Accesible"][this.session.user.language]+'</th>' +
        '</tr>';
        for (let i = 0; i < this.targetLocking.params.bultos.length; i++) {
          content += '<tr>' +
          '<td style= "padding: 8px; text-align: center;">' + this.targetLocking.params.bultos[i].id + '</td>' +
          '<td style= "padding: 8px; text-align: center;">' + this.targetLocking.params.bultos[i].alto + ' mm' + '</td>' +
          '<td style= "padding: 8px; text-align: center;">' + this.targetLocking.params.bultos[i].ancho + ' mm' + '</td>' +
          '<td style= "padding: 8px; text-align: center;">' + this.targetLocking.params.bultos[i].fondo + ' mm' + '</td>' +
          '<td style= "padding: 8px; text-align: center;">' + ((this.targetLocking.params.bultos[i].peso!=undefined)?this.targetLocking.params.bultos[i].peso:'-') + ' g' + '</td>' +
          '<td style= "padding: 8px; text-align: center;">' + ((this.targetLocking.params.bultos[i].tipo!=undefined)?this.dictionary[this.targetLocking.params.bultos[i].tipo][this.session.user.language]:'-') + '</td>' +
          '<td style= "padding: 8px; text-align: center;">' + ((this.targetLocking.params.bultos[i].accesible!=undefined)?this.dictionary[this.targetLocking.params.bultos[i].accesible][this.session.user.language]:'-') + '</td>' +
          '</tr>';
        }
        content += '</table>' +
        '</td>' +
        '</tr>' +
        '</table>' +
        '</div>';
        row.child(content).show();
        if (this.targetLockingId) {
          let detailTable = document.getElementById('detailTable' + this.targetLockingId);
          if (detailTable && detailTable.parentNode) {
              detailTable.parentNode.style.padding = 0;
          }
        }
        tr.addClass('shown');
      }
    },
    // DELETE
    async onDeleteLocking(event) {
      if (!this.display.deleteLockingModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetLockingId = event.target.getAttribute('data-id');
        this.idPedido=[];
        let pedidoLabel= "Identificador del pedido: "+event.target.getAttribute('data-idOrder');
        this.idPedido.push(pedidoLabel);
      }
      else {
        let response = await api.deleteOrder(this.targetLockingId);
        if (response["status-code"] === "200") {
          this.$emit('toggleNewLockingModal');
          window.alert("La reserva ha sido eliminada satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar la reserva.");
        }
      }
      this.toggleDeleteLockingModal();
    },
    //CANCEL
    async onCancelLocking(event) {
      if (!this.display.cancelLockingModal) {
        console.log('CANCEL WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetLockingId = event.target.getAttribute('data-id');
        this.idPedido=[];
        let pedidoLabel= "Identificador del pedido: "+event.target.getAttribute('data-idOrder');
        this.idLocker= event.target.getAttribute('data-idLocker'); 
        this.idPedido.push(pedidoLabel);
      }
      else {
       
        
        let response = await api.cancelOrder(this.targetLockingId,this.idLocker);
        if (response["status-code"] === "200") {
          this.$emit('toggleNewLockingModal');
          window.alert("La reserva ha sido cancelada satisfactoriamente.");
        }
        else {
          window.alert("Error al cancelada la reserva.");
        }
      }
      this.toggleCancelLockingModal();
    }
  },  
  async created() {
    await this.getLockingList()
    this.$emit('getAvailableCompanies',(this.displayLockingsDataTable));
  },
  mounted() {
    $("#LockingsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.lockingColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        //stateSave: this.dataTableOptions.stateSave,
        order: [[4,'desc']]
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>
<style>
#prepareLockingModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.btnCopyId:hover, .btnDetailLocking:hover,  .btnModifyLocking:hover  , .btnDeleteLocking:hover, .btnCancelLocking:hover {
  color: var(--main-dark) !important;
}
*:disabled {
  background-color: dimgrey;
  color: linen;
  opacity: 1;
}
</style>