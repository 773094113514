<template>
<!-- MODAL Edit Bundle -->
  <div id="editBundleModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
<!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="person" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary['Editar conjunto'][session.user.language]+": "+editBundleData.label}}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleEditBundleModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT Edit Bundle -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="col-12 p-3 pe-0 pb-0" style="display: flex; flex-flow: wrap;">



            <div class="col-lg-6 col-md-12 col-12 m-0 p-2">
              <form class="col-12 border rounded m-0 p-1 overflow-hidden">
                <div class="bg-menu text-white p-1 m-0 rounded"> 
                  {{dictionary['Hueco'][session.user.language]}}
                </div>
                <div class="container-fluid col-12 m-0 p-0 pt-2">
                  <div class="row justify-content-around m-0 p-0 ps-1 pe-1">
    <!-- NOMBRE -->
                    <div class="col-lg-12 col-md-6 col-12">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{dictionary['Nombre'][session.user.language]}}
                      </label>
                      <input v-model="editBundleData.label" id="inputEditBundleId" type="text" 
                        class="form-control form-control-line" style="color:black;">
                    </div>
                    
    <!-- Maquina -->
                    <div class="col-lg-6 col-md-6 col-12">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{dictionary['Máquina'][session.user.language]}}
                      </label>
                      <select readonly disabled v-model="editBundleData.parent" 
                        id="selectNewBundleParent"
                        class="form-control form-control-line" style="color:black;">
                          <option v-for="machine in machineList" :key="machine.id" :value="machine.id">{{ machine.label }}</option>
                      </select>
                    </div>
<!-- Tipo -->
                    <div class="col-lg-6 col-md-6 col-12">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{dictionary['Tipo'][session.user.language]}}
                      </label>
                      <div class="col-md-12 p-0">
                        <input readonly v-model="editBundleData.typeName" id="inputEditBundleId" type="text" 
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>

    <!-- Fila -->
                    <div class="col-lg-6 col-md-6 col-12">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{dictionary['Fila'][session.user.language]}}
                      </label>
                      <div class="col-md-12 p-0 m-0">
                        <input v-model="editBundleData.geoJSON.geometry.coordinates[0]" id="inputEditBundleId" type="number" min=0
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>
    <!-- Columna -->

                    <div class="col-lg-6 col-md-6 col-12">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{dictionary['Columna'][session.user.language]}}
                      </label>
                      <div class="col-md-12 p-0 m-0">
                        <input v-model="editBundleData.geoJSON.geometry.coordinates[1]" id="inputEditBundleId" type="number" min=0
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>

                    <div class="form-group col-12 p-0 pt-3 pb-3">
                    <div class="col-12 m-0 p-0">
<!-- SUBMIT -->
                    <button type="button" @click="setEditBundleData"
                    id="btnSubmitBundleData" class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">{{dictionary['Guardar cambios'][session.user.language]}}</span>
                      </span>
                    </button>
                    </div>
                  </div>

                  </div>
                </div>
              </form>

<!--------- Json ----------->
<div class="form-group mb-3 col-12 d-flex flex-wrap">
                <div class="col-12">
                  <div class="m-0 p-0 w-100">
                    <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{dictionary['Configuración'][session.user.language]}}
                      <span class="ps-1 small">(JSON)</span>
                    </label>
                    <textarea v-model="bundleConfigurationForm.value" type="text" 
                    id="inputEditBundleConfiguration"
                    class="form-control form-control-line" style="color:black; text-align: start;"></textarea>
                  </div>
                </div>

                <div class="col-lg-6 col-12 m-0 p-0">
                </div>

                <div class="form-group col-lg-12 col-12 p-0 pb-3">
                  <div class="col-12 mt-4">
                <!-- SUBMIT CONFIGURATION-->
                    <button type="button" @click="setBundleConfiguration"
                    id="btnSubmitBundleConfiguration" class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">{{dictionary['Guardar configuración'][session.user.language]}}</span>
                      </span>
                    </button>
                  </div>
                </div>

                <!-- FORM My Profile (End) -->
                </div>
            <!-- Json (End) -->   
            </div>


            <div class="col-lg-6 col-md-12 col-12 m-0 p-2">
<!-- FORM Machine -->
              <div class="col-12 m-0 p-0">
                <div class="row form-group col-12 border bg-menu rounded-5 col-12 m-0 p-2">
                  <div class="form-group m-0 p-0 pt-0">
                    <div class="row bg-menu text-white p-1 pt-0 ps-4 m-0 rounded-5">

                      <label class="col-12 p-0 pt-3 tx-14 tx-bold tx-clomos-dark" v-if="Object.keys(editBundleData.variables.analog)[0]!=undefined">
                        {{"ANA"}}
                      </label>
                      <hr class="mt-1 mb-1">
                          <div v-for="variable in editBundleData.variables.analog" :key="variable.analogVariableId"
                          class="col-lg-4 col-md-6 col-12 ">
                            <div class="m-0 p-0 w-100">
                              <label class="col-12 p-0 pt-1 tx-white">
                                {{variable.label}}
                              </label>
                              <select v-if="variable.label=='estado'" 
                              v-model="editBundleData.variables.analog[variable.label].value" :id="'inputEditBundle'+variable.label"
                              :data-id="editBundleData.variables.analog[variable.label].variableId"
                              :data-type="editBundleData.variables.analog[variable.label].type"
                              :disabled="(variable.isConfiguration==='false')?true:false" 
                              class="inputEditBundle form-control form-control-line text-dark">
                                <option value="0">
                                  {{dictionary['estadoConjunto0'][session.user.language]}}
                                </option>
                                <option value="1">
                                  {{dictionary['estadoConjunto1'][session.user.language]}}
                                </option>
                                <option value="2">
                                  {{dictionary['estadoConjunto2'][session.user.language]}}
                                </option>
                                <option value="0">
                                  {{dictionary['estadoConjunto3'][session.user.language]}}
                                </option>
                              </select>
                              <input v-else 
                              v-model="editBundleData.variables.analog[variable.label].value" :id="'inputEditBundle'+variable.label" type="text" 
                              :data-id="editBundleData.variables.analog[variable.label].variableId"
                              :data-type="editBundleData.variables.analog[variable.label].type"
                              :disabled="(variable.isConfiguration==='false')?true:false" 
                              class="inputEditBundle form-control form-control-line text-dark">
                            </div>
                          </div>

                          <label class="col-12 p-0 pt-3 tx-14 tx-bold tx-clomos-dark" v-if="Object.keys(editBundleData.variables.digital)[0]!=undefined">
                            {{"DIG"}}
                          </label>
                          <hr class="mt-1 mb-1">

                        <div v-for="variable in editBundleData.variables.digital" :key="variable.digitalVariableId"
                        class="col-lg-6 col-md-6 col-12">
                          <div class="m-0 p-0 w-100">
                            <label class="col-12 p-0 pt-3 text-white text-center">
                              {{variable.label}}
                            </label>
                            <div :id="'inputEditBundle'+variable.label" 
                            :data-id="editBundleData.variables.digital[variable.label].variableId"
                            :data-type="editBundleData.variables.digital[variable.label].type"
                            :data-value="editBundleData.variables.digital[variable.label].value"
                            :disabled="(variable.isConfiguration==='false')?true:false" 
                            class="inputEditBundle form-control form-control-line border-0 tx-bold"
                            :class="editBundleData.variables.digital[variable.label].value"
                            @click="(variable.isConfiguration==='false')?'':(editBundleData.variables.digital[variable.label].value==='true')?editBundleData.variables.digital[variable.label].value='false':editBundleData.variables.digital[variable.label].value='true'">
                              {{ dictionary[editBundleData.variables.digital[variable.label].value][session.user.language] }}
                            </div>
                          </div>
                        </div>

                        <label class="col-12 p-0 pt-3 tx-14 tx-bold tx-clomos-dark" v-if="Object.keys(editBundleData.variables.dynamic)[0]!=undefined">
                          {{"DIN"}}
                        </label>
                        <hr class="mt-1 mb-1">

                        <div v-for="variable in editBundleData.variables.dynamic" :key="variable.dynamicVariableId"
                        class="col-lg-6 col-md-6 col-12" :style="(variable.label!='configuracion')?'display:block':'display:none'">
                          <div class="m-0 p-0 w-100">
                            <label class="col-12 p-0 pt-3">
                              {{variable.label}}
                            </label>
                            <input v-model="editBundleData.variables.dynamic[variable.label].value" 
                            :id="'inputEditBundle'+variable.label" type="text" 
                            :data-id="editBundleData.variables.dynamic[variable.label].variableId"
                            :data-type="editBundleData.variables.dynamic[variable.label].type"
                            :disabled="(variable.isConfiguration==='false')?true:false" 
                            class="inputEditBundle form-control form-control-line" style="color:black;">
                          </div>
                        </div>


                <div class="col-lg-12 col-12 mt-4 mb-4">
                <!-- SUBMIT VARIABLES-->
                  <button type="button" @click="setBundleVariables"
                  id="btnSubmitBundleVariables" class="button-ok gradient-tablas rounded-3">
                    <span class="button-text text-white">
                      <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                      <span class="text-white mt-0">{{dictionary['Guardar variables'][session.user.language]}}</span>
                    </span>
                  </button>
                </div>
              </div>

            </div>
          </div>
            </div>
          </div>  
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "EditBundleModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    bundleId: {
      type: String,
      requiered: true,
    },
    targetMachineId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      editBundleData : {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        typeName: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        geoJSON: { 
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0,0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {},
        }
      },
      bundleConfigurationForm: {
        id: "",
        variableId: "",
        value: ""
      },
      machineList: []
    }
  },
  emits: ["toggleEditBundleModal"],
  methods: {
    // SET
    async setEditBundleData() {
      let response = await api.setBundleData(this.editBundleData);
      if (response["status-code"] === "200") {
        window.alert("El conjunto ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el conjunto.");
      }
      this.getEditBundleData();
    },
    async setBundleVariables() {
      let variablesData = {
        bundleId : this.editBundleData.id
      };
      $(".inputEditBundle").get().forEach(input => {
        variablesData[input.id.replace("inputEditBundle","")] = {
          value: (input.getAttribute("data-type")==="0")
            ?(input.getAttribute("data-value")==="true")
              ?1
              :0
            :input.value,
          id: input.getAttribute("data-id"),
          type: input.getAttribute("data-type")}
      });
      let response = await api.setBundleVariables(variablesData);
      if (response["status-code"] === "200") {
        window.alert("El conjunto ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el conjunto.");
      }
      this.getEditBundleData();
    },
    async setBundleConfiguration() {
      let response = await api.setBundleConfiguration(this.bundleConfigurationForm);
      if (response["status-code"] === "200") {
        window.alert("El hueco ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el hueco.");
      }
      this.getEditBundleData();
    },
    // GET 
    async getEditBundleData(callback) {
      let response = await api.getBundleData(this.bundleId);
      this.editBundleData = api.parseBundleFromResponse(response);
      if (this.editBundleData.variables.dynamic['configuracion']!=undefined)
        this.bundleConfigurationForm = {
          id: this.editBundleData.id,
          variableId: this.editBundleData.variables.dynamic['configuracion'].variableId,
          value: this.editBundleData.variables.dynamic['configuracion'].value
        }
      else 
        this.bundleConfigurationForm = {
          id: this.editBundleData.id,
          variableId: "",
          value: ""
        }
      if (callback && typeof callback === "function") callback(this.editBundleData);
    },
    async getMachineListByCompany() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      for (let i in response.maquinas) [
        this.machineList[i] = {
          id: response.maquinas[i].idDcl,
          label: response.maquinas[i].etiqueta,
        }
      ]
    }
  },
  async created() {
    await this.getEditBundleData();
    await this.getMachineListByCompany();
  }
}
</script>

<style>

  #editBundleModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }
</style>