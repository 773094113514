// Vue 
import { createApp } from 'vue'
// Vue Router
import { createRouter, createWebHistory } from 'vue-router'
// Project version
var pjson = require('/package.json');
console.log("VERSION: "+pjson.version);

var clientConfig = require('/clientConfig.js');
console.log("CLIENT: "+clientConfig.name);
localStorage.setItem('clientConfig', clientConfig);

import dayjs from "/node_modules/dayjs";
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc);
dayjs.extend(timezone);

// Views
import App from './App.vue'
import LogInView from '../src/views/LogInView.vue'
import ClomosView from '../src/views/ClomosView.vue'

import DataTable from 'datatables.net-vue3';
import "/node_modules/datatables.net-buttons";
import "/node_modules/datatables.net-buttons/js/buttons.colVis.js";
import "/node_modules/datatables.net-buttons/js/buttons.flash.js"
import "/node_modules/datatables.net-buttons/js/buttons.html5.js"
import "/node_modules/datatables.net-buttons/js/buttons.print.js"

// Configuración de rutas
const routes = [
    {
        path:'/', redirect: {name: 'LogIn'}
    },
    {
        path:'/:language?/logIn/', name:'LogIn', component: LogInView,
    },
    {
        path:'/:language?/:currentCompanyNifCif?/:currentModule?/:currentDisplay?/:targetEntity?', name:'Home', component: ClomosView,
    },
]

// Instancia de rutas
const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

// Instancia raiz
const app = createApp(App);
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$clientConfig = clientConfig;
app.use(DataTable);
app.use(router);
app.mount('#app');
