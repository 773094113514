<template>
<!-- MODAL New Machine -->
  <div id="newMachineModal" class="col-12 justify-content-center pe-6"  style="padding: 10vh 25%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">

<!-- HEAD New Company -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="cog" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary['Nueva máquina'][session.user.language]}}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewMachineModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT New Machine -->
      <div class="col-12 bg-caja w-100 m-0 p-0 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="row m-0 p-2 card-body">

            <div class="col-lg-12 col-md-12 col-12 m-0 p-2">
              <form class="col-12 border rounded m-0 p-1 overflow-hidden">
                <div class="bg-menu text-white p-1 m-0 rounded"> 
                  {{dictionary['Máquina'][session.user.language]}}
                </div>
                <div class="container-fluid col-12 m-0 p-0 pt-2">
                  <div class="row justify-content-around m-0 p-0 ps-1 pe-1">
                    <!-- NOMBRE -->
                    <div class="col-lg-4 col-md-6 col-12">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{dictionary['Nombre'][session.user.language]}}*
                      </label>
                      <input v-model="newMachineData.label" id="inputNewMachineLabel" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                    
    <!-- Emplazamiento -->
                    <div class="col-lg-4 col-md-6 col-12">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{dictionary['Emplazamiento'][session.user.language]}}*
                      </label>
                      <select v-model="newMachineData.emplacementId" 
                      id="selectNewMachineType"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="emplacement in emplacementList" :key="emplacement.id" :value="emplacement.id">{{emplacement.label}}</option>
                      </select>
                    </div>
<!-- Tipo -->
                    <div class="col-lg-4 col-md-6 col-12">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{dictionary['Tipo'][session.user.language]}}*
                      </label>
                      <div class="col-md-12 p-0">
                        <select v-model="newMachineData.typeId" 
                        id="selectNewMachineType"
                        class="form-control form-control-line" style="color:black;">
                          <option v-for="type in machineTypeList" :key="type.id" :value="type.id">{{type.label}}</option>
                        </select>
                        </div>
                      </div>

                  </div>

                  <div class="form-group col-12 p-0 pt-3 pb-3">
                    <div class="col-12 m-0 p-0">
<!-- SUBMIT -->
                    <button type="button" @click="onCreateNewMachine"
                    id="btnSubmitCreateNewMachine" class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        {{dictionary['Crear nueva máquina'][session.user.language]}}
                      </span>
                    </button>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "NewMachineModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
  },
  data() {
    return {
      newMachineData: {
        nifCif: this.currentCompany.nifCif,
        label: "",
        typeId: "",
        emplacementId: "",
        emplacementLabel: "",
      },
      machineTypeList: [],
      emplacementList: []
    }
  },
  methods: {
    async onCreateNewMachine() {
      let valid = true;
      if (this.newMachineData.label === "") {
        $("#inputNewMachineLabel").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newMachineData.label === "") {
        $("#selectNewMachineType").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewMachineLabel").get()[0].classList.remove("missing")
          $("#selectNewMachineType").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      for (let i in this.emplacementList) {
        if ( this.emplacementList[i].id === this.newMachineData.emplacementId ) {
          this.newMachineData.emplacementLabel = this.emplacementList[i].location;
        }
      }
      let response = await api.postNewMachine(this.newMachineData);
      if (response["status-code"] === "201") {
        this.$emit('toggleNewMachineModal');
        window.alert("La máquina ha sido creado satisfactoriamente.");
      }
      else {
        window.alert("Error al crear la máquina.");
      }
    },
    async getMachineTypeList() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getMachineTypeList(request);
      for (let i in response.tiposDcl) [
        this.machineTypeList[i] = {
          id: response.tiposDcl[i].idTipoDcl,
          label: response.tiposDcl[i].etiqueta,
        }
      ]
    },
    async getEmplacementList() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getEmplacementList(request);
      for (let i in response.plantas) [
        this.emplacementList[i] = {
          id: response.plantas[i].idDcl,
          label: response.plantas[i].etiqueta,
        }
      ]
    }
  },
  async created() {
    await this.getMachineTypeList();
    await this.getEmplacementList();
  }
}
</script>

<style>

#newMachineModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>