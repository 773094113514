<template>
  <!-- MODAL New Bundle -->
    <div id="NewSlotModal" class="col-12 justify-content-center pe-6"  style="padding: 15vh 25%">
      <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
  
  <!-- HEAD New Company -->
        <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
          <div class="col-10">
            <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
              <ion-icon name="grid" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary['Nuevo hueco'][session.user.language]}}
            </h6>
          </div>
  
          <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewSlotModal')">	
            <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
          </div>
        </div>
    
  <!-- CONTENT New Bundle -->
        <div class="col-12 bg-caja w-100 m-0 p-0 rounded-bottom-5 pe-3">
          <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
            <div class="row m-0 p-2 card-body">
  
              <div class="col-12 m-0 p-2 overflow-hidden">
                <form class="col-12 border rounded m-0 p-3 pt-1">
                  <div class="row">
                  
  <!-- NOMBRE -->
                    <div class="form-group col-md-6 col-12 p-1">
                      <label class="col-12 tx-bold tx-clomos-midtone">
                        {{dictionary['Nombre'][session.user.language]}} *
                      </label>
                      <div class="col-md-12">
                        <input v-model="newBundleData.label" id="inputNewBundleLabel" type="text" 
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>
  
  <!-- TIPO -->
                    <div class="form-group col-md-6 col-12 p-1">
                      <label class="col-12 tx-bold tx-clomos-midtone">
                        {{dictionary['Tipo'][session.user.language]}} *
                      </label>
                      <div class="col-md-12">
                        <select v-model="newBundleData.typeId" 
                        id="selectNewBundleType"
                        class="form-control form-control-line" style="color:black;">
                          <option v-for="type in bundleTypeList" :key="type.id" :value="type.id">{{type.label}}</option>
                        </select>
                      </div>
                    </div>
  
                  </div>
        
                  <div class="row">
                    
  <!-- MÁQUINA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Locker'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <select v-model="selectedMachine" 
                      id="selectNewBundleParent"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="machine in machineList" :key="machine.id" :value="machine.id">{{ machine.label }}</option>
                      </select>
                    </div>
                  </div>
  
  
                  </div>
                
                  <div class="row">
                  
                    <div class="col-12 mt-4">
  <!-- SUBMIT -->
                      <button type="button" @click="onCreateNewBundle"
                      id="btnSubmitCreateNewMachine" class="button-ok gradient-tablas rounded-3">
                        <span class="button-text text-white">
                          <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                          {{dictionary['Crear nuevo hueco'][session.user.language]}}
                        </span>
                      </button>
                    </div>
                  </div>
  
                </form>
              </div>
          
            </div>
          </div>
              
        </div>
        
      </div>
    </div>
  
  </template>
  
  <script>
  import $ from 'jquery';
  // Clomos
  import api from '@/services/api';
  
  export default {
    name: "NewSlotModal",
    props: {
      session: {
        type: Object,
        requiered: true,
      },
      dictionary: {
        type: Object,
        requiered: true,
      },
      countries: {
        type: Object,
        requiered: true,
      },
      timeZones: {
        type: Object,
        requiered: true,
      },
      currentCompany: {
        type: Object,
        requiered: true,
      },
      targetMachineId: {
        type: String,
        requiered: true,
      },
    },
    data() {
      return {
        newBundleData: {
          nifCif: this.currentCompany.nifCif,
          label: "",
          typeId: "",
          machineId: "",
          geoJSON: { 
            type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [0,0]
              }
          }
        },
        bundleTypeList: [],
        machineList: [],
        selectedMachine: null // Agrega esta propiedad
      }
    },
    methods: {
      async onCreateNewBundle() {
        let valid = true;
        if (this.newBundleData.label === "") {
          $("#inputNewBundleLabel").get()[0].classList.add("missing")
          valid = false;
        }
        if (this.newBundleData.typeId === "") {
          $("#selectNewBundleType").get()[0].classList.add("missing")
          valid = false;
        }
        if (this.selectedMachine == null) {
          $("#selectNewBundleParent").get()[0].classList.add("missing")
          valid = false;
        }
        if (!valid) {
          setTimeout(() => {
            $("#inputNewBundleLabel").get()[0].classList.remove("missing")
            $("#selectNewBundleType").get()[0].classList.remove("missing")
            $("#selectNewBundleParent").get()[0].classList.remove("missing")
          }, 3000);
          return;
        }
        let response = await api.postNewBundle(this.newBundleData);
        if (response["status-code"] === "201") {
          this.$emit('toggleNewSlotModal');
          window.alert("El conjunto ha sido creado satisfactoriamente.");
        }
        else {
          window.alert("Error al crear el hueco.");
        }
      },
      async getBundleTypeList() {
        let request = {
          nifCif: this.currentCompany.nifCif,
          limit: 0,
          offset: 0,  
          where: "",
        };
        let response = await api.getBundleTypeList(request);
        for (let i in response.tiposDcl) [
          this.bundleTypeList[i] = {
            id: response.tiposDcl[i].idTipoDcl,
            label: response.tiposDcl[i].etiqueta,
          }
        ]    },
      async getMachineListByCompany() {
        let request = {
          nifCif: this.currentCompany.nifCif,
          limit: 0,
          offset: 0,  
          where: "",
        };
        let response = await api.getMachineListByCompany(request);
        for (let i in response.maquinas) [
          this.machineList[i] = {
            id: response.maquinas[i].idDcl,
            label: response.maquinas[i].etiqueta,
          }
        ]
        if (this.machineList.length > 0) {
          if (this.targetMachineId!="")
            this.selectedMachine = this.machineList.find(machine => machine.id === this.targetMachineId);
          else
            this.selectedMachine = this.machineList[0];
          this.newBundleData.machineId = this.selectedMachine.id;
        }
      }
    },
    async created() {
      await this.getBundleTypeList();
      await this.getMachineListByCompany();
    }
  }
  </script>
  
  <style>
  
  #NewSlotModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }
  </style>