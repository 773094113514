<template>
  <!-- SECTION Machines-->
    <div id="machinesSection" class="row col-12 m-0 p-0">
      <div class="row col-12 p-2 m-0 section-container justify-content-center">
  
  <!-- Selector de Sistema -->
        <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
          <div class="bg-menu border-1 rounded-5 box sombra">
            
            <div class="col-12 overflow-hidden justify-content-center">
              <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
              <ion-icon name="cog" class="tx-18 me-2 align-bottom"></ion-icon>
                {{dictionary["Lockers"][session.user.language]}}
                <span v-if="targetEmplacementData.label != undefined"> : <br> {{targetEmplacementData.label}} </span>
              </h6>
            </div>
  
            <div class="p-3 pt-0">
  <!-- Selector de Sistema -->
              <div class="col-12 m-0 p-0">	
                <hr v-if="this.$route.params.currentDisplay==='lockers'" class="m-0 p-0">
                
                <select v-if="this.$route.params.currentDisplay==='lockers'"
                :value="currentCompany.nifCif"
                class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
                style="border: solid 2px white ; color: black;"
                @change="onCurrentCompanyChanged">
                  <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                  <option v-for="company in availableCompanies" :key="company.nifCif"
                  :v-if="company.nifCif != session.user.nifCif
                  && company.relation != undefined
                  && company.relation.referenceCompany === this.session.user.nifCif"
                  :value="company.nifCif">
                    {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                  </option>
                </select>
                
                <hr class="m-0 p-0 mb-3">
  
                <div class="row justify-content-around m-0 p-0">
                  <div class="col-3 m-0 mb-3 p-0">	
                    <button @click="onChangeDisplayType('blueprint')"
                    :style="(displayType==='blueprint')?'opacity=1;':'opacity: 0.7'"
                    class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                      <ion-icon name="locate-outline" 
                      :style="(displayType==='blueprint')?'opacity=1;':'opacity: 0.5'"
                      class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                    </button>
                  </div>
                  <div class="col-3 m-0 mb-3 p-0">	
                    <button @click="onChangeDisplayType('table')"
                    :style="(displayType==='table')?'opacity=1;':'opacity: 0.7'"
                    class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                      <ion-icon name="list-outline" 
                      :style="(displayType==='table')?'opacity=1;':'opacity: 0.5'"
                      class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                    </button>
                  </div>
                  <div class="col-3 m-0 mb-3 p-0">	
                    <button @click="onChangeDisplayType('cards')"
                    :style="(displayType==='cards')?'opacity=1;':'opacity: 0.7'"
                    class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                      <ion-icon name="documents-outline" 
                      :style="(displayType==='cards')?'opacity=1;':'opacity: 0.5'"
                      class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                    </button>
                  </div>
                </div>
  
                <hr class="m-0 p-0">
  
                <div class="col-12 p-0 justify-content-center d-flex">
                  <button id="btnDisplayNewLockerModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3" 
                  @click="toggleNewLockerModal">
                    <span class="button-text text-white">
                      <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                      <span class="text-white mt-0 text-nowrap">{{dictionary["Añadir locker"][session.user.language]}}</span>
                    </span>
                  </button>
                </div>
              </div>
              
            </div>
          </div>
  
        </div>
    
  <!-- COMPONENT Machines -->
        <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
          <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
            <!--<ion-icon v-if="session.user.bookmark==='machines'" name="bookmark" class="bookmark-button"></ion-icon>
            <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
            <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
              <ion-icon name="cog-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
              <span> {{dictionary["Lockers"][session.user.language] }} </span>
              <span v-if="this.$route.params.currentDisplay==='lockers' || this.targetEmplacementId == ''"> 
                {{ ": "+currentCompany.name }} 
              </span>
              <span v-else-if="this.targetEmplacementData.label != undefined"> 
                {{ ": "+targetEmplacementData.label }} 
              </span>
            </div>
  
  <!-- BLUEPRINT Machines -->
            <div v-show="displayType==='blueprint'"
            id="machinesBlueprintContent" class="col-12 m-0 p-2 h-auto"
            :style="(!display.showEverything)?'height: 15rem':''">
              <div class="blueprintCanvas col-9 m-0 p-0">
                <img id="emplacementBlueprintForMachines" class="w-100 m-0 p-2"
                :src="displayMachinesBlueprint()">
                <div v-for="machine in machineList" :key="machine" :data-id="machine.id"
                class="bg-caja rounded-3 p-2 machineBlueprintMarker"
                :style="bluePrintMarkerPosition(machine)">
                  <div class="d-flex flex-column" style="min-width: 9rem;">
                    <span :data-id="machine.id" class="btnTargetMachine c-pointer tx-bold tx-grey m-0 p-0">
                      {{machine.label}}
                    </span>
                    <div class="m-0 p-0 d-flex flex-row justify-content-between">
                      <text class="w-100"> {{machine.location}} </text>
                      <ion-icon name="create-outline" :data-id="machine.id" class="btnEditMachine col-2 m-0 ps-1 p-0 btn tx-24 tx-primary float-end"></ion-icon>
                      <ion-icon name="trash-outline" :data-id="machine.id" class="btnDeleteMachine col-2 m-0 p-0 btn tx-24 tx-danger float-end"></ion-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
  <!-- TABLE Machines -->
            <div v-show="displayType==='table'" class="m-0 p-3 h-auto"
            id="machinesTableContent"
            :style="(!display.showEverything)?'height: 15rem':''">
              <table id="machinesDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
                <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                  <tr>
                    <th v-for="column in machinesColumns" :key="column.data"
                    :width="column.width" class="fw-medium text-nowrap p-2">
                      {{dictionary[column.text][session.user.language]}}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
  
  <!-- CARDS Machines -->
            <div v-show="displayType==='cards'&&this.machineList[0]!=undefined"
            id="machinesCardsContent" class="col-12 m-0 p-2 h-auto"
            :style="(!display.showEverything)?'height: 15rem':''">
              <div class="col-12 m-0 p-0 d-flex flex-row flex-wrap">
                <div v-for="machine in machineCardsByPages[machineCardsPage]" :key="machine"
                class="col-xl-2 col-md-4 col-12 m-0 p-2">
                  <h6 class="tx-left tx-clomos-white text-center text-nowrap m-0 p-2 ps-1 pt-2 gradient-tablas rounded-top-3">
                    <span class="m-auto">{{(machine.label.length>18)?(machine.label.substring(0,18)+'...'):machine.label}}</span>
                  </h6>
  
                  <div class="col-12 border-1 rounded-bottom-3 m-0 p-3">
                    <div class="col-12 m-0 p-0 d-flex space-between">
                      <ion-icon name="pulse-outline" :data-id="machine.id" class="btnTargetMachine col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                      <ion-icon name="create-outline" :data-id="machine.id" class="btnEditMachine col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                      <ion-icon name="trash-outline" :data-id="machine.id" class="btnDeleteMachine col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-danger"></ion-icon>
                    </div>
                    <hr class="col-12 mt-2 mb-2">
                    <div class="col-12 d-flex flex-column tx-left align-middle p-0" style="height: 5rem;">
                      <span class="tx-13 tx-bold">{{dictionary['Tipo'][session.user.language]+": "}}</span>
                      <span class="tx-11 tx-start">{{machine.type}}</span>
                      <span class="tx-13 tx-bold">{{dictionary['Identificador'][session.user.language]+": "}}</span>
                      <span class="tx-11 tx-start">{{machine.id}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex flex-row m-0 p-2">
                  <div v-for="page,index in machineCardsByPages" :key="page" 
                  class="border rounded c-pointer m-0 me-2 tx-13" :class="(machineCardsPage===index)?'border-dark':''" style="height:2.5rem; width:2.5rem; padding: inherit;"
                  @click="onSetCardPage(index)">
                    <span class="tx-bold">{{ index+1  }}</span>
                  </div>
                </div>
              </div>
            </div>
  
            <div v-show="displayType==='cards'&&this.machineList[0]==undefined" class="m-3 p-3 border rounded">
              <span class="tx-13"> 
                {{dictionary['No hay lockers disponibles'][session.user.language]}} 
              </span> 
            </div>
  
          </div>
        </div>
  
      </div>
    </div>
  
  <!-- SECTION Machines -->
    <!-- <StatusComponent v-if="this.$route.params.currentDisplay==='machines'"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :visibility="visibility"
    :currentCompany="currentCompany"
    :requestedMachineId="targetMachineId"
    :dataTableOptions="dataTableOptions">
    </StatusComponent> -->
  
  <!-- MODAL New Machine -->
    <NewLockerModal
    v-if="display.NewLockerModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :timeZones="timeZones"
    :currentCompany="currentCompany"
    v-on:toggleNewLockerModal="toggleNewLockerModal">
    </NewLockerModal> 
  
  <!-- MODAL Edit Machine -->
    <EditLockerModal
    v-if="display.editLockerModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :timeZones="timeZones"
    :currentCompany="currentCompany"
    :machineId="targetMachineId"
    v-on:toggleEditLockerModal="toggleEditLockerModal">
    </EditLockerModal>
  
  <!-- MODAL Delete Text Alert -->
    <TextAlertModal
    v-if="display.deleteMachineModal"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :alertHeader="dictionary['Locker a eliminar'][session.user.language] + ': '"
    :alertTarget="targetMachineId"
    v-on:acceptAlert="onDeleteMachine"
    v-on:dismissAlert="toggleDeleteMachineModal">
    </TextAlertModal>
  
    <!-- SECTION Slots -->
    <SlotsComponent v-if="this.$route.params.currentDisplay==='lockers'"
    :session="session"
    :dictionary="dictionary"
    :countries="countries"
    :visibility="visibility"
    :currentCompany="currentCompany"
    :availableCompanies="availableCompanies"
    :targetMachineId="targetMachineId"
    :dataTableOptions="dataTableOptions">
    </SlotsComponent>
  
  </template>
  
  <script>
  import $ from 'jquery';
  import SlotsComponent from '@/components/lockers/SlotsComponent';
  import NewLockerModal from '@/components/lockers/NewLockerModal';
  import EditLockerModal from '@/components/lockers/EditLockerModal';
  import TextAlertModal from '@/components/other/TextAlertModal';
  // Clomos
  import api from '@/services/api';
  
  export default {
    name: "LockersComponent",
    components: { SlotsComponent, NewLockerModal,  EditLockerModal, TextAlertModal },
    props: {
      session: {
        type: Object,
        requiered: true,
      },
      dictionary: {
        type: Object,
        requiered: true,
      },
      countries: {
        type: Object,
        requiered: true,
      },
      timeZones: {
        type: Object,
        requiered: true,
      },
      visibility: {
        type: Object,
        requiered: true,
      },
      currentCompany: {
        type: Object,
        requiered: true,
      },
      availableCompanies: {
        type: Object,
        requiered: true,
      },
      targetEmplacementId: {
        type: String,
        requiered: true,
      },
      dataTableOptions: {
        type: Object,
        requiered: true,
      },
    },
    watch: { 
      currentCompany: async function() {
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getMachinesDataByCompany();
        }
        else {
          await this.getEmplacementData();
          await this.getMachinesDataByEmplacement();
        }
        this.onChangeDisplayType(this.displayType);
        if (this.machineList[0] != undefined && this.targetMachineId === "")
          this.targetMachineId = this.machineList[0].id
      },
      targetEmplacementId: async function() {
        if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
          await this.getMachinesDataByCompany();
        }
        else {
          await this.getEmplacementData();
          await this.getMachinesDataByEmplacement();
        }
        this.onChangeDisplayType(this.displayType);
        if (this.machineList[0] != undefined && this.targetMachineId === "")
          this.targetMachineId = this.machineList[0].id;
      }
    },
    data() {
      return {
        machinesColumns: [
          {text:"Identificador", data: "machineId", width: "25%", className:"tx-left tx-12 align-middle dt-nowrap",
            render: (data) => {
              return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-14'></ion-icon>"
              +"<span data-id="+data+" class='btnTargetMachine c-pointer c-pointer text-nowrap align-middle'>"+data+"</span>";
            }
          },
          {text:"Nombre", data: "machineLabel", width: "10%", className:"tx-left tx-12 tx-bold align-middle"},
          {text:"Tipo", data: "machineType", width: "15%", className:"tx-left tx-12 align-middle"},
          {text:"Emplazamiento", data: "machineEmplacement", width: "10%", className:"tx-left tx-12 align-middle",
            render: (data) => {
              let emplacementLabel = data;
              if (this.emplacementList.length > 0) {
                for (let emplacement in this.emplacementList)
                  if (this.emplacementList[emplacement].id === data) 
                    emplacementLabel = this.emplacementList[emplacement].label;
              }
              return emplacementLabel;
            }
          },
          {text:"Fecha de comunicación", data: "machineCommunicationDateTime", width: "10%", className:"align-middle tx-12",
            render: (data) => {
              return (data!="")
              ?"<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              :"<span style='display: none;'></span> <spam>-</spam>";
            }
          },
          {text:"Configuración", data: "machineId", width: "5%", className:"align-middle",
            render: (data) => {
              return "<ion-icon name='settings-outline' data-id="+data+" class='btnEditMachine btn tx-16 tx-primary'></ion-icon>"
            }
          },
          {text:"Eliminar", data: "machineId", width: "5%", className:"align-middle",
            render: (data) => {
              return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteMachine btn tx-16 tx-danger'></ion-icon>"
            }
          },
        ],
        targetEmplacementData: {},
        targetMachineId: this.$route.params.targetEntity,
        machineList: [],
        emplacementList: [],
        displayType : (this.$route.params.currentView==='emplacements')?'blueprint':'table',
        machineCardsPage: 0,
        display: {
          NewLockerModal: false,
          editLockerModal: false,
          linkMachineModal: false,
          deleteMachineModal: false,
        },
        linkAction: "link"
      }
    },
    computed: {
      machineCardsByPages(){
        let machineCardsByPages = []
        let i = 0; 
        while (i*12 < this.machineList.length) {
          machineCardsByPages.push(this.machineList.slice(i*12,i*12+12));
          i++;
        }
        return machineCardsByPages;
      }
    },
    emits: ["setCurrentCompany", "getAvailableCompanies"],
    methods: {
      // DISPLAY
      onCurrentCompanyChanged(event) {
        let value = event.target.value;
        this.$router.replace({params: {currentCompanyNifCif: value}})
        this.$emit('setCurrentCompany',value);
      },
      onChangeDisplayType(type) {
        this.displayType = type;
        setTimeout(() => {
          if (type === "blueprint") this.displayMachinesBlueprint();
          if (type === "table") this.displayMachinesDataTable();
          this.setButtonFunctions();
        }, 100);
      },
      onSetCardPage(index) {
        this.machineCardsPage = index;
        this.setButtonFunctions();
      },
      displayMachinesBlueprint() {
        setTimeout(()=>{
          $(".machineBlueprintMarker").get().forEach(marker => {
            let markerId = marker.getAttribute("data-id");
            for (let i in this.machineList) {
              if (this.machineList[i].id === markerId) 
                marker.style = this.bluePrintMarkerPosition(this.machineList[i]);
            }
          })
        },1000);
        let src = "";
        if (this.targetEmplacementData.variables != undefined
        && this.targetEmplacementData.variables.dynamic != undefined
        && this.targetEmplacementData.variables.dynamic.urlPlano != undefined)
          src = this.targetEmplacementData.variables.dynamic.urlPlano.value;
        if (src!=undefined && src!='') {
          try {
            return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
          }
          catch(e) { 
            console.warn(e);
          }
        }
        return require('/src/assets/img/placeholder_image.jpg');
      },
      bluePrintMarkerPosition(machine) {
        let markerX = 0;
        let markerY = 0;
        let blueprintCanvas = document.getElementById('emplacementBlueprintForMachines');
        if (blueprintCanvas != null) {
          let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
          let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;
          
          let domX = machine.geoJSON.geometry.coordinates[0] - blueprintCanvas.offsetLeft + 7 ;
          let domY = machine.geoJSON.geometry.coordinates[1] - blueprintCanvas.offsetTop + 7;
    
          markerX = Math.floor(domX / ratioX);
          markerY = Math.floor(domY / ratioY);
        }
        return "position: absolute; top: " + markerY +"px; left: " + markerX + "px;"
      },
      displayMachinesDataTable() {
        $("#machinesDataTable").DataTable().clear()
        for (let i in this.machineList) {
          $("#machinesDataTable").DataTable().rows.add([{
            machineId: this.machineList[i].id, 
            machineEmplacement: this.machineList[i].parent, 
            machineLabel: this.machineList[i].label, 
            machineLocation: this.machineList[i].parent,
            machineType: this.machineList[i].type, 
            machineStatus: this.machineList[i].status, 
            machineCommunicationDateTime: (this.machineList[i].communicationDateTime != undefined && this.machineList[i].communicationDateTime != "") ? new Date(this.machineList[i].communicationDateTime) : "", 
            machineVersion: this.machineList[i].version, 
          }]);
        }
        $("#machinesDataTable").DataTable().draw();
        this.setButtonFunctions();
        if ($("#machinesDataTable_paginate").get()[0] != undefined) $("#machinesDataTable_paginate").get()[0].addEventListener("click", this.setButtonFunctions);
        $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions)});
      },
      async toggleNewLockerModal() {
        if (this.display.NewLockerModal) {
          if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
            await this.getMachinesDataByCompany();
            this.displayMachinesDataTable();
          }
          else {
            await this.getEmplacementData();
            await this.getMachinesDataByEmplacement();
            this.displayMachinesDataTable();
          }
          this.onChangeDisplayType(this.displayType);
        }
        this.display.NewLockerModal = !this.display.NewLockerModal;
      },
      async toggleEditLockerModal() {
        if (this.display.editLockerModal) {
          if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
            await this.getMachinesDataByCompany();
            this.displayMachinesDataTable();
          }
          else {
            await this.getEmplacementData();
            await this.getMachinesDataByEmplacement();
            this.displayMachinesDataTable();
          }
          this.onChangeDisplayType(this.displayType);
        }
        this.display.editLockerModal = !this.display.editLockerModal;
      },
      async toggleLinkMachineModal() {
        if (this.display.linkMachineModal) {
          if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
            await this.getMachinesDataByCompany();
            this.displayMachinesDataTable();
          }
          else {
            await this.getEmplacementData();
            await this.getMachinesDataByEmplacement();
            this.displayMachinesDataTable();
          }
          this.onChangeDisplayType(this.displayType);
        }
        this.display.linkMachineModal = !this.display.linkMachineModal;
      },
      async toggleDeleteMachineModal() {
        if (this.display.deleteMachineModal) {
          if (this.$route.params.currentDisplay === 'lockers' || this.targetEmplacementId == '') {
            await this.getMachinesDataByCompany();
            this.displayMachinesDataTable();
          }
          else {
            await this.getEmplacementData();
            await this.getMachinesDataByEmplacement();
            this.displayMachinesDataTable();
          }
          if (this.machineList[0] != undefined) this.targetMachineId = this.machineList[0].id;
          this.onChangeDisplayType(this.displayType);
        }
        this.display.deleteMachineModal = !this.display.deleteMachineModal;
      },
      // BUTTONS
      setButtonFunctions() {
        setTimeout(()=>{
          $(".btnTargetMachine").get().forEach(btn => {
            btn.addEventListener('click', this.onTargetMachine);
          });
          $(".btnEditMachine").get().forEach(btn => {
            btn.addEventListener('click', this.onEditMachine);
          });
          $(".btnLinkMachine").get().forEach(btn => {
            btn.addEventListener('click', this.onLinkMachine);
          });
          $(".btnUnlinkMachine").get().forEach(btn => {
            btn.addEventListener('click', this.onUnlinkMachine);
          });
          $(".btnDeleteMachine").get().forEach(btn => {
            btn.addEventListener('click', this.onDeleteMachine);
          });
          $(".btnCopyId").get().forEach(btn => {
            btn.addEventListener('click', this.onCopyId);
          });
        }, 300);
      },
      onCopyId(event) {
        navigator.clipboard.writeText(event.target.getAttribute('data-id'));
      },
      // GET
      async getMachinesDataByCompany(callback) {
        let request = {
          nifCif: this.currentCompany.nifCif,
          limit: 0,
          offset: 0,  
          where: "",
        };
        let response = await api.getMachineListByCompany(request);
        this.machineList = api.parseMachinesFromResponse(response);
        if (callback && typeof callback === "function") callback(this.machineList);
      },
      async getMachinesDataByEmplacement(callback) {
        let request = {
          emplacementId: this.targetEmplacementId,
          limit: 0,
          offset: 0,  
          where: "",
        };
        let response = await api.getMachineListByEmplacement(request);
        this.machineList = api.parseMachinesFromResponse(response);
        if (callback && typeof callback === "function") callback(this.machineList);
      },
      async getEmplacementData(callback) {
        let response = await api.getEmplacementData(this.targetEmplacementId);
        this.targetEmplacementData = api.parseEmplacementFromResponse(response);
        if (callback && typeof callback === "function") callback(this.targetEmplacementData);
      },
      async getEmplacementList(callback) {
        let request = {
          nifCif: this.currentCompany.nifCif,
          limit: 0,
          offset: 0,  
          where: "",
        };
        let response = await api.getEmplacementList(request);
        this.emplacementList = api.parseEmplacementsFromResponse(response);
        if (callback && typeof callback === "function") callback(this.emplacementList);
      },
      // TARGET 
      onTargetMachine(event) {
        console.log('TARGET WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetMachineId = event.target.getAttribute('data-id')
        this.$router.replace({params: {targetEntity: this.targetMachineId}})
      },
      // SET 
      onEditMachine(event) {
        console.log('SHOW WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetMachineId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetMachineId}})
        this.toggleEditLockerModal();
      },
      // LINK 
      onLinkMachine(event) {
        console.log('LINK WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetMachineId = event.target.getAttribute('data-id')
        this.$router.replace({params: {targetEntity: this.targetMachineId}})
        this.linkAction = "link"
        this.toggleLinkMachineModal();
      },
      // UNLINK 
      onUnlinkMachine(event) {
        console.log('UNLINK WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetMachineId = event.target.getAttribute('data-id')
        this.$router.replace({params: {targetEntity: this.targetMachineId}})
        this.linkAction = "unlink"
        this.toggleLinkMachineModal();
      },
      // DELETE
      async onDeleteMachine(event) {
        if (!this.display.deleteMachineModal) {
          console.log('DELETE WHERE ID:');
          console.log(this.targetMachineId);
          this.targetMachineId = event.target.getAttribute('data-id');
          this.$router.replace({params: {targetEntity: this.targetMachineId}})
        }
        else {
          let response = await api.deleteMachine(this.targetMachineId);
          if (response["status-code"] === "200") {
            window.alert("El locker ha sido eliminada satisfactoriamente.");
          }
          else {
            window.alert("Error al eliminar el locker.");
          }
        }
        await this.toggleDeleteMachineModal();
      }
    },
    async created() {
      await this.getEmplacementList();
      if (this.$route.params.currentDisplay === 'lockers') {
        await this.getMachinesDataByCompany();
        this.$emit('getAvailableCompanies',(this.displayMachinesDataTable));
      }
      else if (this.targetEmplacementId != ''){
        await this.getEmplacementData();
        await this.getMachinesDataByEmplacement();
        this.$emit('getAvailableCompanies',this.onChangeDisplayType(this.displayType));
      }
      if (this.machineList[0] != undefined && this.targetMachineId === "")
        this.targetMachineId = this.machineList[0].id;
    },
    mounted() {
      $("#machinesDataTable").DataTable(
        {
          pagingType: 'full_numbers',
          pageLength: 10,
          processing: true,
          dom: 'Bfrtip',
          buttons: [
            { "extend": 'colvis', "className": 'btn' },
            { "extend": 'copy', "className": 'btn ms-auto' },
            { "extend": 'csv', "className": 'btn' },
            { "extend": 'print', "className": 'btn me-0' },
          ],
          columns: this.machinesColumns,
          select: this.dataTableOptions.select,
          language: this.dataTableOptions.language[this.session.user.language],
          stateSave: this.dataTableOptions.stateSave,
        }
      );
      $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
    }
  }
  </script>
  
  <style>
  .btnCopyId:hover, .btnTargetMachine:hover, .btnLinkMachine:hover, .btnUnlinkMachine:hover, .btnEditMachine:hover, .btnDeleteMachine:hover {
    color: var(--main-dark) !important;
  }
  </style>