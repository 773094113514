<template>
<!-- MODAL Edit Product -->
<div id="editProductModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
  <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
    
<!-- HEAD Edit Product -->
    <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
      <div class="col-10">
        <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
          <ion-icon name="cube" class="tx-18 me-2 align-bottom"></ion-icon>
          {{dictionary['Editar producto'][session.user.language]}}
        </h6>
      </div>

      <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleEditProductModal')">	
        <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
      </div>
    </div>

<!-- CONTENT Edit Product -->
    <div class="bg-caja col-12 p-0 w-100 rounded-bottom-5 pe-3">
      <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
        <div class="card-body d-flex flex-row flex-wrap p-2">
          <div class="col-xl-6 col-lg-12 col-12 p-2">
            <form class="p-1 m-0 border rounded overflow-hidden">
              <div class="bg-menu text-white p-1 m-0 rounded"> 
                {{dictionary['Datos del producto'][session.user.language]}}
              </div>

              <div class="row pt-3">

                <div class="row m-0 p-0">

  <!-- NOMBRE -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Nombre'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.label" id="inputEditProductLabel" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

  <!-- REFERENCIA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Referencia'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.reference" id="inputEditProductReference" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>

              
                <div class="row m-0 p-0">

  <!-- DESCRIPCIÓN -->
                  <div class="form-group col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Descripción'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <textarea v-model="editProductData.description" id="inputEditProductDescription" type="text" rows="1"
                      class="form-control form-control-line" style="color:black;"/>
                    </div>
                  </div>
                
                </div>
              
                <div class="row m-0 p-0">
                
  <!-- UNIDAD DE MEDIDA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Unidad de medida'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select v-model="editProductData.unit"
                      id="inputEditProductUnit"
                      class="form-control form-control-line" style="color:black;">
                        <option value="Kg">
                          {{dictionary["Kilo"][session.user.language]}}
                        </option>
                        <option value="M">
                          {{dictionary["Metro"][session.user.language]}}
                        </option>
                        <option value="L">
                          {{dictionary["Litro"][session.user.language]}}
                        </option>
                        <option value="unidades">
                          {{dictionary["Unidad"][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

  <!-- PESO POR UNIDAD -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Peso por unidad'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.unitWeight" id="inputEditProductUnitWeight" type="number" min=0
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                
                </div>

                <div class="row m-0 p-0">
                
  <!-- APILABLE -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap pointer">
                      {{dictionary['Apilable'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <div id="inputEditProductStackable" style="cursor:pointer"
                      class="inputEditDevice form-control border-0 tx-bold w-100"
                      :class="editProductData.stackable"
                      @click="(editProductData.stackable==='true')?editProductData.stackable='false':editProductData.stackable='true'">
                        {{ dictionary[editProductData.stackable][session.user.language] }}
                      </div>
                    </div>
                  </div>

  <!-- DESCATALOGADO -->
                  <div v-if="editProductData.discontinued!=undefined" class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Descatalogado'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <div id="inputEditProductDiscontinued" style="cursor:pointer"
                      class="inputEditDevice form-control border-0 tx-bold w-100"
                      :class="editProductData.discontinued"
                      @click="(editProductData.discontinued==='true')?editProductData.discontinued='false':editProductData.discontinued='true'">
                        {{ dictionary[editProductData.discontinued][session.user.language] }}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row m-0 p-0">

  <!-- TEMERATURA DE CONSERVACIÓN -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Temperatura de conservación'][session.user.language]}}
                    </label>
                    <div class="col-lg-12">
                      <select v-model="editProductData.conservationTemperature"
                      id="selectEditProductConservationTemperature"
                      class="form-control form-control-line" style="color:black;">
                        <option value="frio">
                          {{dictionary['Frío'][session.user.language]}}
                        </option>
                        <option value="ambiente">
                          {{dictionary['Ambiente'][session.user.language]}}
                        </option>
                        <option value="ultracongelado">
                          {{dictionary['Ultracongelado'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

  <!-- CATEGORÍA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Categoría'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select v-model="editProductData.category"
                      id="selectEditProductCategory"
                      class="form-control form-control-line" style="color:black;">
                        <option value="Alimentación">
                          {{dictionary["Alimentación"][session.user.language]}}
                        </option>
                        <option value="Arte">
                          {{dictionary["Arte"][session.user.language]}}
                        </option>
                        <option value="Bebidas">
                          {{dictionary["Bebidas"][session.user.language]}}
                        </option>
                        <option value="Belleza e higiene">
                          {{dictionary["Belleza e higiene"][session.user.language]}}
                        </option>
                        <option value="Calzado">
                          {{dictionary["Calzado"][session.user.language]}}
                        </option>
                        <option value="Complementos">
                          {{dictionary["Complementos"][session.user.language]}}
                        </option>
                        <option value="Conservas">
                          {{dictionary["Conservas"][session.user.language]}}
                        </option>
                        <option value="Decoración">
                          {{dictionary["Decoración"][session.user.language]}}
                        </option>
                        <option value="Disfraces">
                          {{dictionary["Disfraces"][session.user.language]}}
                        </option>
                        <option value="Dulces y chocolates">
                          {{dictionary["Dulces y chocolates"][session.user.language]}}
                        </option>
                        <option value="Electricidad e iluminación">
                          {{dictionary["Electricidad e iluminación"][session.user.language]}}
                        </option>
                        <option value="Embutidos y carnes">
                          {{dictionary["Embutidos y carnes"][session.user.language]}}
                        </option>
                        <option value="Frescos">
                          {{dictionary["Frescos"][session.user.language]}}
                        </option>
                        <option value="Hombre">
                          {{dictionary["Hombre"][session.user.language]}}
                        </option>
                        <option value="Libros">
                          {{dictionary["Libros"][session.user.language]}}
                        </option>
                        <option value="Lácteos">
                          {{dictionary["Lácteos"][session.user.language]}}
                        </option>
                        <option value="Mascotas">
                          {{dictionary["Mascotas"][session.user.language]}}
                        </option>
                        <option value="Moda">
                          {{dictionary["Moda"][session.user.language]}}
                        </option>
                        <option value="Muebles">
                          {{dictionary["Muebles"][session.user.language]}}
                        </option>
                        <option value="Mujer">
                          {{dictionary["Mujer"][session.user.language]}}
                        </option>
                        <option value="Otros">
                          {{dictionary["Otros"][session.user.language]}}
                        </option>
                        <option value="Vinos">
                          {{dictionary["Vinos"][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row m-0 p-0">
  <!-- PRIORIDAD DE SALIDA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Prioridad de salida'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select v-model="editProductData.priority"
                      id="selectEditProductPriority"
                      class="form-control form-control-line" style="color:black;">
                        <option value="FIFO">
                          {{dictionary['Fecha de entrada'][session.user.language]}} (FIFO)
                        </option>
                        <option value="LIFO">
                          {{dictionary['Fecha de salida'][session.user.language]}} (LIFO)
                        </option>
                        <option value="FEFO">
                          {{dictionary['Fecha de caducidad'][session.user.language]}} (FEFO)
                        </option>
                      </select>
                    </div>
                  </div>

  <!-- TIENDA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Empresa'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select
                      v-model="editProductData.nifCif"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="company in availableCompanies" :key="company.nifCif"
                        :v-if="company.nifCif != session.user.nifCif
                        && company.relation != undefined
                        && company.relation.referenceCompany === this.session.user.nifCif"
                        :value="company.nifCif">
                          {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="col-12 m-0 p-0 ps-2 pe-2">
                  <hr class="tx-clomos-dark ms-3 me-3">
                </div>
                
                <div class="row m-0 ps-2 pe-2">
                  <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
                    {{dictionary['Dimensiones'][session.user.language]}} <span class="ps-1 small">(mm)</span>
                  </label>
                </div>

                <div class="row m-0 p-0">
  <!-- ALTO -->
                  <div class="form-group col-md-4 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Alto'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.volume.height" id="inputEditProductHeight" type="number" min=0
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

  <!-- ANCHO -->
                  <div class="form-group col-md-4 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Ancho'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.volume.width" id="inputEditProductWidth" type="number" min=0
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

  <!-- FONDO -->
                  <div class="form-group col-md-4 col-12 p-1">
                    <label class="col-12 tx-13 text-dark text-nowrap">
                      {{dictionary['Fondo'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editProductData.volume.depth" id="inputEditProductDepth" type="number" min=0
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>
                
                <div class="row m-0 p-0">


  <!-- SUBMIT -->
                  <div class="form-group col-12 p-1">
                    <div class="col-12 mt-2 mb-2">
                      <button type="button" @click="setEditProductData"
                      id="btnSetProduct" class="button-ok gradient-tablas rounded-3">
                        <span class="button-text text-white">
                          <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                          <span class="text-white mt-0">{{dictionary['Guardar cambios'][session.user.language]}}</span>
                        </span>
                      </button>
                    </div>
                  </div>

                </div>
                  
              </div>
            </form>
          </div>

<!-- FORM Edit Product-->
          <div class="col-xl-6 col-lg-12 col-12 p-2">
            <form class="p-1 m-0 border rounded overflow-hidden">
              <div class="bg-menu text-white p-1 m-0 rounded"> 
                {{dictionary['Datos de almacenamiento'][session.user.language]}}
              </div>

              <div class="row pt-3">

                <div class="form-group col-xl-6 col-12 p-1">
                  <label class="col-12 tx-bold tx-clomos-dark text-nowrap">
                    {{dictionary['Perfil logístico'][session.user.language]}}
                  </label>

                  <div class="col-md-12 pt-2">
                    <select v-model="editProductData.logisticProfile"
                    :disabled="isValid"
                    @change="onLogisticProfileChange"
                    id="selectEditProductLogisticProfile"
                    class="col-6 form-control form-control-line" style="color:black;">
                      <option value="" selected disabled hidden>
                        {{ "Ninguno" }}
                      </option>
                      <option v-for="profile, key in logisticProfiles" :key="profile" :value="key">
                        {{ key }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row m-0 p-0">
  <!-- LOTE -->
                <div class="form-group col-lg-4 col-12 p-1">
                  <label class="col-12 p-0 ps-2 tx-13 text-dark text-nowrap">
                    {{dictionary['Lote'][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0 ps-2 pe-2">
                    <div id="inputEditProductExpirationDate" 
                    class="form-control border-0 tx-bold w-100"
                    :class="(editProductData.batch!='important')?editProductData.batch:'true'">
                      {{ dictionary[editProductData.batch][session.user.language] }}
                    </div>
                  </div>
                </div>
  <!-- NUMERO DE SERIE -->
                <div class="form-group col-lg-4 col-12 p-1">
                  <label class="col-12 p-0 ps-2 tx-13 text-dark text-nowrap">
                    {{dictionary['Nº de serie'][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0 ps-2 pe-2">
                    <div id="inputEditProductExpirationDate" 
                    class="form-control border-0 tx-bold w-100"
                    :class="(editProductData.serialNumber!='important')?editProductData.serialNumber:'true'">
                      {{ dictionary[editProductData.serialNumber][session.user.language] }}
                    </div>
                  </div>
                </div>
  <!-- FECHA DE CADUCIDAD -->
                <div class="form-group col-lg-4 col-12 p-1">
                  <label class="col-12 p-0 ps-2 tx-13 text-dark text-nowrap">
                    {{dictionary['Fecha de caducidad'][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0 ps-2 pe-2">
                    <div id="inputEditProductExpirationDate" 
                    class="form-control border-0 tx-bold w-100"
                    :class="(editProductData.expirationDate!='important')?editProductData.expirationDate:'true'">
                      {{ dictionary[editProductData.expirationDate][session.user.language] }}
                    </div>
                  </div>
                </div>
  <!-- TALLA -->
                <div class="form-group col-lg-4 col-12 p-1">
                  <label class="col-12 p-0 ps-2 tx-13 text-dark text-nowrap">
                    {{dictionary['Talla'][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0 ps-2 pe-2">
                    <div id="inputEditProductSize" 
                    class="form-control border-0 tx-bold w-100"
                    :class="(editProductData.size!='important')?editProductData.size:'true'">
                      {{ dictionary[editProductData.size][session.user.language] }}
                    </div>
                  </div>
                </div>
  <!-- COLOR -->
                <div class="form-group col-lg-4 col-12 p-1">
                  <label class="col-12 p-0 ps-2 tx-13 text-dark text-nowrap">
                    {{dictionary['Color'][session.user.language]}}
                  </label>
                  <div class="col-12 m-0 p-0 ps-2 pe-2">
                    <div id="inputEditProductColor" 
                    class="form-control border-0 tx-bold w-100"
                    :class="(editProductData.color!='important')?editProductData.color:'true'">
                      {{ dictionary[editProductData.color][session.user.language] }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 m-0 p-0 ps-2 pe-2">
                <hr class="tx-clomos-dark ms-1 me-1">
              </div>
              
              <div class="row m-0 p-0">

  <!-- ZONA  -->
                <div class="form-group col-xl-6 col-12 p-1">
                  <label class="col-12 ps-2 tx-bold tx-clomos-dark text-nowrap">
                    {{dictionary['Zona'][session.user.language]}}
                  </label>

                  <div class="col-md-12 m-0 p-2 pb-0">
                    <select v-model="editProductData.zone"
                    :disabled="isValid"
                    id="selectEditProductZone"
                    class="col-6 form-control form-control-line" style="color:black;"
                    @change="onChangeZone">
                      <option value="" selected disabled hidden>
                        {{ "Ninguna" }}
                      </option>
                      <option value="automatico">
                        {{ "Automático" }}
                      </option>
                      <option value="locker">
                        {{ "Locker" }}
                      </option>
                      <option value="libre">
                        {{ "Libre" }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              
              <div class="col-12 m-0 p-0 ps-2 pe-2">
                <hr class="tx-clomos-dark ms-1 me-1">
              </div>

  <!-- HUECOS HABILITADOS -->
              <div class="form-group col-12 p-1">

                <div class="row ps-2 pe-2">
                  <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
                    {{dictionary['Huecos habilitados'][session.user.language]}}
                  </label>
                </div>

                <span v-if="editProductData.zone ===''" class="tx-13 d-flex p-2">Selecciona una zona</span>
                <span v-else class="tx-13 d-flex p-2">Dispone de los siguientes huecos para la zona indicada.</span>
                <div v-show="availableSlotTypes!=undefined && editProductData.zone != ''" class="m-0 p-0"> 
                  <div v-for="availableSlot, slotType in availableSlotTypes" :key="slotType"> 
                    <div v-if="availableSlot.zone === editProductData.zone" class="row m-0 p-0"> 
                      <div class="col-12 col-lg-4 m-0 p-2 d-flex flex-column">
                        <div class="border-clomos rounded d-flex flex-row gap-2 p-2 mt-auto">
                          <input class="slotTypeCheckbox" :id="slotType+'Checkbox'"
                          :disabled="isValid"
                          type="checkbox" :checked="unitsPerSlot[slotType]!=undefined" 
                          style="width:20px;">
                          <div class="tx-clomos-dark tx-bold tx-13">
                            {{slotType}}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4 m-0 p-2 d-flex flex-column">
                        <label class="m-0 p-0 tx-13">
                          {{dictionary['Dimensiones'][session.user.language]}} <span class="ps-1 small">(mm)</span>
                        </label>
                        <span class="mt-auto tx-13">
                          {{boxTypes.filter(boxType => boxType.id === slotType)[0].volume.width + " x "
                          + boxTypes.filter(boxType => boxType.id === slotType)[0].volume.height + " x "
                          + boxTypes.filter(boxType => boxType.id === slotType)[0].volume.depth}}
                        </span>
                      </div>
                      <div class="col-12 col-lg-4 m-0 p-2 d-flex flex-column">
                        <label class="m-0 p-0 tx-13">
                          {{dictionary['Capacidad'][session.user.language]}} <span class="ps-1 small"> {{ "(u/"+dictionary['Hueco'][session.user.language]+")" }} </span>
                        </label>
                        <input :id="slotType+'Input'" class="slotTypeInput form-control form-control-line mt-auto" style="color: black" 
                        :disabled="isValid"
                        :value="unitsPerSlot[slotType]" :placeholder="computeUnitsPerSlot(slotType)"
                        @change="(e)=>{unitsPerSlot[slotType]=e.target.value;isUpdated=false}" min=0>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row m-0 p-0">

  <!-- SUBMIT -->
                <div class="form-group col-12 p-1">
                  <div class="col-12 mt-2 mb-2">
                    <button type="button" @click="validateProductData"
                    id="btnSetProduct" class="button-ok gradient-tablas rounded-3" :class="(isValid)?'btn-clomos-disabled':'btn-clomos'">
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">{{dictionary[(isValid)?'Validado':'Validar'][session.user.language]}}</span>
                      </span>
                    </button>
                  </div>
                </div>

              </div>
              
            </form>
          </div>

<!-- FORM Product Picture-->
          <div class="col-12 p-2">
            <form class="m-0 p-0 overflow-hidden" v-if="editProductData.imgSrc!=undefined">
              <div class="justify-content-center bg-menu rounded-5">
                
                <label class="col-12 p-3 pb-0 tx-white tx-bold">
                  {{dictionary["Imagen del producto"][session.user.language].toUpperCase()}}
                </label>
                <label class="col-12 p-3 pt-0 pb-0 tx-white tx-light tx-12">
                  {{dictionary["Tamaño recomendado"][session.user.language]+': 1000x1000px'}}
                </label>
                
                <img id="productImage" class="w-100" style="max-width: 750px; width: auto; padding: 0 1rem 1rem 1rem;" :src="displayProductImage(editProductData.imgSrc)">

                <div v-if="editProductImageForm.file!=''" class="myCompanyImagePreview w-100 p-3 m-auto">
                  <div class="d-flex flex-column">
                    <button type="button" @click="onRemoveEditProductImage()" title="Remove file" style="line-height: 0; width: 25px; height: 25px" class="p-1 m-0 bg-clomos-white rounded ms-auto">
                      <ion-icon name="close" class="tx-18 tx-black align-bottom"></ion-icon>
                    </button>
                    <img style="max-width: 750px; width: auto; padding-top: 1rem; margin: auto;" :src="displayEditProductThumbnail(editProductImageForm.file)" />
                  </div>
                </div>

                <div id="productImageUploadFormContainer" class="w-100 p-3 pt-0" style="display: flex; justify-content: space-around; gap: 1rem;">
                  <input type="file" id="productImageUploadSrc" class="w-100 file-button btn btn-secondary rounded" style="height: 3rem; min-width: 180px;"
                  @change="onChangeEditProductImage" accept=".png, .jpg">

                  <button type="button" id="productImageUploadSubmit" @click="setEditProductImage" class="button-ok gradient-tablas rounded-3 m-auto">
                    <span class="button-text text-white">
                      <ion-icon name="image-outline" class="text-white pt-0 me-2 tx-18 hydrated"></ion-icon>
                      <span class="text-white text-nowrap mt-0">{{dictionary['Guardar archivo'][session.user.language]}}</span>
                    </span>
                  </button>
                </div>

              </div>
            </form>
          </div>

          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import boxTypesJson from "/src/assets/json/store_box_types.json";
import logisticProfilesJson from "/src/assets/json/logistic_profiles.json";
// Clomos
import api from '@/services/api';

export default {
  name: "EditProductModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    productId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      editProductData: {
        nifCif: "",
        id: "",
        label: "",
        reference : "",
        description: "",
        unit: "",
        conservationTemperature: "",
        priority:"",
        category:"",
        stackable: "false",
        discontinued: "false",
        batch: "false",
        serialNumber: "false",
        expirationDate: "false",
        size: "false",
        color: "false",
        imgSrc : undefined, 
        unitWeight: 0,
        zone: {},
        logisticProfile: "",
        enabledSlots: {},
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        variableIdList: {}
      },
      isUpdated: false,
      availableSlotTypes: {},
      unitsPerSlot: {},
      editProductImageForm: {
        productId: this.productId,
        variableId: "",
        nifCif: "",
        src: "",
        file: ""
      },
      boxTypes: JSON.parse(JSON.stringify(boxTypesJson)),
      logisticProfiles: JSON.parse(JSON.stringify(logisticProfilesJson))
    }
  },
  computed: {
    isValid() {
      let isValid = true;
      if (!this.isUpdated)
      {
        return false;
      }
      else if (!this.editProductData.stock === 0)
      {
          return false;
      }
      else if (this.editProductData.enabledSlots === undefined || Object.keys(this.editProductData.enabledSlots).length===0)
      {
          return false;
      }
      else if (this.editProductData.logisticProfile === undefined || this.editProductData.logisticProfile === "")
      {
          return false;
      }
      else if (this.editProductData.zone === undefined || this.editProductData.zone === "")
      {
          return false;
      }
      return isValid;
    }
  },
  emits: ["toggleEditProductModal"],
  methods: {
    // DISPLAY
    onLogisticProfileChange(isUpdated = false) {
      this.isUpdated = isUpdated;
      if (this.editProductData.logisticProfile=="") {
        this.editProductData.batch = "false";
        this.editProductData.serialNumber = "false";
        this.editProductData.size = "false";
        this.editProductData.color = "false";
        this.editProductData.expirationDate = "false";
        return
      }
      let variables = {
          batch: "false" ,
          serialNumber: "false" ,
          size: "false" ,
          color: "false",
          expirationDate: "false" 
        }
      for (let i in this.logisticProfiles[this.editProductData.logisticProfile].variables) {
        variables[this.logisticProfiles[this.editProductData.logisticProfile].variables[i]] = "true";
      }
      this.editProductData.batch = variables.batch;
      this.editProductData.serialNumber = variables.serialNumber;
      this.editProductData.size = variables.size;
      this.editProductData.color = variables.color;
      this.editProductData.expirationDate = variables.expirationDate;
    },
    onChangeZone() {
      $('.slotTypeCheckbox').get().forEach(element => {element.checked = false});
      this.isUpdated = false;
    },
    computeUnitsPerSlot(slotType) {
      let slotVolume;
      for (let i in this.boxTypes) {
        if (this.boxTypes[i].id === slotType) {
          slotVolume = this.boxTypes[i].volume; 
          break;
        }
      }
      let w = 0;
      let d = 0;
      let h = 0;
      w = Math.floor(slotVolume.width/this.editProductData.volume.width);
      d = Math.floor(slotVolume.depth/this.editProductData.volume.depth);
      let units = w*d;
      if (this.editProductData.stackable==='true') {
        h = Math.floor(slotVolume.height/this.editProductData.volume.height);
        units = units*h;
      } 
      return units;
    },
    displayProductImage(src) {
      if (src!=undefined && src!='') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) { 
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    displayEditProductThumbnail(file) {
      if (file === '' || file === undefined) return "";
      let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
            URL.revokeObjectURL(fileSrc);
        }, 1000);
      return fileSrc;
    },
    onChangeEditProductImage(event) {
      if (event.target.files[0] != undefined && event.target.files[0].size > 2097152) {
        alert("El tamaño del fichero debe ser inferior a 2Mb.");
        return;
      }
      this.editProductImageForm.nifCif = this.editProductData.nifCif;
      this.editProductImageForm.file = event.target.files[0];
      this.editProductImageForm.src = event.target.files[0].name;
    },
    onRemoveEditProductImage() {
      $("#productImageUploadSrc").get()[0].value="";
      this.editProductImageForm.nifCif = "";
      this.editProductImageForm.file = "";
      this.editProductImageForm.src = "";
    },
    // GET 
    async getEditProductData(callback) {
      let response = await api.getStockTypeData(this.productId);
      api.parseStockTypeFromResponse(response, (product) => {
        if(product != {}) {
          this.editProductData.nifCif = product.nifCif; 
          this.editProductData.id = product.id; 
          this.editProductData.label = product.label; 
          this.editProductData.reference = product.variables.dynamic.referencia.value;
          this.editProductData.description = product.variables.dynamic.descripcion.value; 
          this.editProductData.unit = product.variables.dynamic.unidad.value; 
          this.editProductData.unitWeight = product.variables.analog.pesoPorUnidad.value; 
          this.editProductData.conservationTemperature = product.variables.dynamic.temperaturaConservacion.value; 
          this.editProductData.priority = product.variables.dynamic.prioridadSalida.value;
          this.editProductData.category = product.category;
          this.editProductData.stackable = product.variables.digital.apilable.value;
          this.editProductData.discontinued = (product.variables.digital.descatalogado!=undefined)?product.variables.digital.descatalogado.value:undefined;
          this.editProductData.volume = {height: JSON.parse(product.variables.dynamic.volumen.value).alto, width: JSON.parse(product.variables.dynamic.volumen.value).ancho, depth: JSON.parse(product.variables.dynamic.volumen.value).fondo}
          this.editProductData.stock = Number(product.variables.analog.stockFisico.value); 
          this.editProductData.logisticProfile = (product.variables.dynamic.perfilLogistico!=undefined)?product.variables.dynamic.perfilLogistico.value :undefined; 
          this.editProductData.enabledSlots = (product.variables.dynamic.huecosHabilitados!=undefined&&product.variables.dynamic.huecosHabilitados.value!="")?JSON.parse(product.variables.dynamic.huecosHabilitados.value) :undefined; 
          this.editProductData.zone = (product.variables.dynamic.zona!=undefined)?product.variables.dynamic.zona.value:undefined; 
          this.editProductData.imgSrc = (product.variables.dynamic.urlImagen!=undefined)?product.variables.dynamic.urlImagen.value:undefined; 
          //this.editProductData.serialNumber = (product.variables.dynamic.numeroSerie!=undefined)?"true":"false"; 
          //this.editProductData.size = (product.variables.dynamic.talla!=undefined)?"true":"false"; 
          //this.editProductData.batch = (product.variables.dynamic.lote!=undefined)?"true":"false"; 
          //this.editProductData.expirationDate = (product.variables.dynamic.fechaCaducidad!=undefined)?"true":"false"; 
          //this.editProductData.color = (product.variables.dynamic.color!=undefined)?"true":"false"; 
          this.editProductData.variableIdList = {
            reference : (product.variables.dynamic.referencia!=undefined) ?product.variables.dynamic.referencia.variableId :'',
            description : (product.variables.dynamic.descripcion!=undefined) ?product.variables.dynamic.descripcion.variableId :'',
            unit : (product.variables.dynamic.unidad!=undefined) ?product.variables.dynamic.unidad.variableId :'',
            unitWeight : (product.variables.analog.pesoPorUnidad!=undefined) ?product.variables.analog.pesoPorUnidad.variableId :'',
            conservationTemperature : (product.variables.dynamic.temperaturaConservacion!=undefined) ?product.variables.dynamic.temperaturaConservacion.variableId :'',
            priority : (product.variables.dynamic.prioridadSalida!=undefined) ?product.variables.dynamic.prioridadSalida.variableId :'',
            stackable : (product.variables.digital.apilable!=undefined) ?product.variables.digital.apilable.variableId :'',
            discontinued : (product.variables.digital.descatalogado!=undefined) ?product.variables.digital.descatalogado.variableId :'',
            volume : (product.variables.dynamic.volumen!=undefined) ?product.variables.dynamic.volumen.variableId :'',
            logisticProfile : (product.variables.dynamic.perfilLogistico!=undefined) ?product.variables.dynamic.perfilLogistico.variableId :'',
            enabledSlots : (product.variables.dynamic.huecosHabilitados!=undefined) ?product.variables.dynamic.huecosHabilitados.variableId :'',
            zone : (product.variables.dynamic.zona!=undefined) ?product.variables.dynamic.zona.variableId :'',
            imgSrc : (product.variables.dynamic.urlImagen!=undefined) ?product.variables.dynamic.urlImagen.variableId :'',
          }
        this.editProductImageForm.variableId = this.editProductData.variableIdList.imgSrc;
        }
      });
      this.isUpdated = true;
      if (callback && typeof callback === "function") callback(this.editProductData);
    },
    // SET
    async setEditProductData() {
      let valid = true;
      if (this.editProductData.label === "") {
        $("#inputEditProductLabel").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.editProductData.unit === "") {
        $("#inputEditProductUnit").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.editProductData.unitWeight === "") {
        $("#inputEditProductUnitWeight").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.editProductData.volume.height === 0) {
        $("#inputEditProductHeight").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.editProductData.volume.width === 0) {
        $("#inputEditProductWidth").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.editProductData.volume.depth === 0) {
        $("#inputEditProductDepth").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputEditProductLabel").get()[0].classList.remove("missing")
          $("#inputEditProductAmount").get()[0].classList.remove("missing")
          $("#inputEditProductUnit").get()[0].classList.remove("missing")
          $("#inputEditProductUnitWeight").get()[0].classList.remove("missing")
          $("#inputEditProductHeight").get()[0].classList.remove("missing")
          $("#inputEditProductWidth").get()[0].classList.remove("missing")
          $("#inputEditProductDepth").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      let response = await api.setProductData(this.editProductData);
      if (response["status-code"] === "200") {
        window.alert("El producto ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el producto.");
      }
      this.getEditProductData();
    },
    async setEditProductImage() {
      let response = await api.setProductImage(this.editProductImageForm);
      console.log(response);
      this.onRemoveEditProductImage();
      this.getEditProductData();
    },
    async validateProductData() {
      let valid = true;
      this.editProductData.enabledSlots = {};
      $("#selectEditProductLogisticProfile").get().forEach(element => {
        if (element.value === "") {
          $("#selectEditProductLogisticProfile").get()[0].classList.add("missing")
          valid = false;
        }
      });
      $("#selectEditProductZone").get().forEach(element => {
        if (element.value === "") {
          $("#selectEditProductZone").get()[0].classList.add("missing")
          valid = false;
        }
      });
      $(".slotTypeCheckbox").get().forEach(element => {
        if (element.checked) {
          let slotType = element.id.replace("Checkbox","")
          if (this.unitsPerSlot[slotType] === undefined) {
            $("#"+slotType+"Input").get()[0].classList.add("missing")
            valid = false;
          }
          else {
            this.editProductData.enabledSlots[slotType] = this.unitsPerSlot[slotType];
          }
        }
      });
      if (!valid) {
        this.editProductData.enabledSlots = {};
        setTimeout(() => {
          $(".slotTypeInput").get().forEach(element => {
            element.classList.remove("missing");
          })
          $("#selectEditProductLogisticProfile").get().forEach(element => {
            element.classList.remove("missing");
          })
          $("#selectEditProductZone").get().forEach(element => {
            element.classList.remove("missing");
          })
        }, 3000);
        return;
      }
      let response = await api.validateProductData(this.editProductData);
      if (response["status-code"] === "200") {
        window.alert("El producto ha sido validado satisfactoriamente.");
      }
      else {
        window.alert("Error al validar el producto.");
      }
      this.getEditProductData();
    }
  },
  async created() {
    await this.getEditProductData();
    this.availableSlotTypes = this.currentCompany.params.disponibilidadHuecos;
    for (let i in this.availableSlotTypes) {
      for (let ii in this.boxTypes) {
        if (i === this.boxTypes[ii].id) {
          this.availableSlotTypes[i].zone = this.boxTypes[ii].zone;
        }
      }
    }
    for (let slot in this.editProductData.enabledSlots) {
      this.unitsPerSlot[slot] = this.editProductData.enabledSlots[slot];
    }
    this.onLogisticProfileChange(true);
  }
}
</script>

<style>
#editProductModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

@media (max-width: 767px) {
  #productImageUploadFormContainer {
    flex-direction: column;
  }
}
</style>