import clientConfig from '/clientConfig.js';
export default {
  status: null,
  async request(method, url, data = undefined, expectJSON = false) {
    let prefix = clientConfig.api;
    //prefix = "";
    let bodyHolder;
    if (url==="/ficheros/ficheros.php") {
      url = url+'?identificadorusuario='+JSON.parse(sessionStorage.getItem("ClomosSession")).user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
      bodyHolder = data;
    }
    else {  
      bodyHolder = (data!=undefined) 
      ?JSON.stringify(data) 
      :undefined
    }
    const response = await (this.fetch || fetch)(prefix + url, {
        method,
        headers: {
          Authorization: this.authToken ? "Bearer "+this.authToken : undefined,
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, HEAD, OPTIONS",
        },
        body: bodyHolder
      });
      this.status = response.status;
      if (response.ok) {
        if (expectJSON || method === "GET") {
          return await response.json();
        } else {
          return await response.text();
        }
      }
      else return false;
    },
  
    async get(url, data) {
      let c = 0;
      for (let i in data) {
        (c === 0) ?url+="?" :url+="&";
        url+=i+"="+data[i];
        c++
      }
      return await this.request("GET", url);
    },
  
    async post(url, data, expectJSON = false) {
      return await this.request("POST", url, data, expectJSON);
    },
  
    async put(url, data, expectJSON = false) {
      return await this.request("PUT", url, data, expectJSON);
    },
  
    async delete(url, data, expectJSON = false) {
      return await this.request("DELETE", url, data, expectJSON);
    },
  };