<template>
  <!-- MODAL Edit Machine -->
  <div id="editMachineModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
<!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="cog" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary['Editar locker'][session.user.language]+": "+editMachineData.label}}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleEditLockerModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT Edit Machine -->
<div class="col-12 bg-caja w-100 m-0 p-0 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="row m-0 p-2 card-body">

            <div class="col-lg-6 col-md-12 col-12 m-0 p-2">
              <form class="col-12 border rounded m-0 p-1 overflow-hidden">
                <div class="bg-menu text-white p-1 m-0 rounded"> 
                  {{dictionary['Locker'][session.user.language]}}
                </div>
                <div class="container-fluid col-12 m-0 p-0 pt-2">
                  <div class="row justify-content-around m-0 p-0 ps-1 pe-1">
    <!-- NOMBRE -->
                    <div class="col-lg-6 col-md-6 col-12">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{dictionary['Nombre'][session.user.language]}}
                      </label>
                      <input v-model="editMachineData.label" id="inputEditMachineName" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                    
    <!-- PADRE -->
                    <div class="col-lg-6 col-md-6 col-12">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{dictionary['Emplazamiento'][session.user.language]}}
                      </label>
                      <select readonly disabled v-model="editMachineData.parent" 
                      id="selectNewMachineType"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="emplacement in emplacementList" :key="emplacement.id" :value="emplacement.id">{{emplacement.label}}</option>
                  </select>
                    </div>
                  </div>
              
                  <div class="row justify-content-around m-0 p-0 ps-1 pe-1">
    <!-- LATITUD -->
                    <div class="col-lg-6 col-md-6 col-12">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{dictionary['Latitud'][session.user.language]}}
                      </label>
                      <div class="col-md-12 p-0">
                        <input v-model="editMachineData.geoJSON.geometry.coordinates[0]" id="inputEditBundleId" type="number" min=0
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>

    <!-- LONGITUD -->
                    <div class="col-lg-6 col-md-6 col-12">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{dictionary['Longitud'][session.user.language]}}
                      </label>
                      <div class="col-md-12 p-0 m-0">
                        <input v-model="editMachineData.geoJSON.geometry.coordinates[1]" id="inputEditBundleId" type="number" min=0
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-12 p-0 pt-3 pb-3">
                    <div class="col-12 m-0 p-0">
<!-- SUBMIT -->
                    <button type="button" @click="setEditMachineData"
                    id="btnSubmitMachineData" class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">{{dictionary['Guardar cambios'][session.user.language]}}</span>
                      </span>
                    </button>
                    </div>
                  </div>

                </div>
              </form>
            </div>

            <div class="col-lg-6 col-md-12 col-12 m-0 p-2">
<!-- Variables-->
              <div class="col-12 m-0 p-0">
                <div class="row form-group col-12 border bg-menu rounded-5 col-12 m-0 p-2">
                  <div class="row form-group m-0 p-0 pt-0">
                    <div class="bg-menu text-white p-1 pt-0 ps-4 m-0 rounded-5"> 
                      <label class="col-12 p-0 pt-3 tx-14 tx-bold tx-clomos-dark" v-if="Object.keys(editMachineData.variables.analog)[0]!=undefined">
                        {{"ANA"}}
                      </label>
                      <hr class="mt-1 mb-1">
                    </div>
                  </div>
<!-- CONTENT Ana -->
                  <div v-for="variable in editMachineData.variables.analog" :key="variable.analogVariableId"
                    class="col-lg-4 col-md-6 col-12 " :style="(variable.isConfiguration==='true')?'display:block':'display:none'">
                      <div class="col-lg-12 col-12 m-0 p-0">
                        <label class="col-12 p-0 pt-1 tx-white">
                          {{variable.label}}
                        </label>
                        <input v-model="editMachineData.variables.analog[variable.label].value" :id="'inputEditMachine'+variable.label" type="text" 
                        :data-id="editMachineData.variables.analog[variable.label].variableId"
                        :data-type="editMachineData.variables.analog[variable.label].type" class="inputEditMachine form-control form-control-line text-dark">
                      </div>
                    </div>
                    <div class="col-12"></div>
                    <label class="col-12 p-0 pt-3 tx-14 tx-bold tx-clomos-dark" v-if="Object.keys(editMachineData.variables.digital)[0]!=undefined">
                      {{"DIG"}}
                    </label>

                    <div v-for="variable in editMachineData.variables.digital" :key="variable.digitalVariableId"
                    class="col-lg-3 col-md-6 col-12" :style="(variable.isConfiguration==='false')?'display:none':'display:block'">
                      <div class="m-0 p-0 w-100">
                        <label class="col-12 p-0 pt-1 tx-white">
                          {{variable.label}}
                        </label>
                        <div :id="'inputEditMachine'+variable.label" 
                        :data-id="editMachineData.variables.digital[variable.label].variableId"
                        :data-type="editMachineData.variables.digital[variable.label].type"
                        :data-value="editMachineData.variables.digital[variable.label].value"
                        class="inputEditMachine form-control form-control-line border-0"
                        :class="editMachineData.variables.digital[variable.label].value"
                        @click="(editMachineData.variables.digital[variable.label].value==='true')?editMachineData.variables.digital[variable.label].value='false':editMachineData.variables.digital[variable.label].value='true'">
                          {{ dictionary[editMachineData.variables.digital[variable.label].value][session.user.language] }}
                        </div>
                      </div>
                    </div>
                    <div class="col-12"></div>

                    <label class="col-12 p-0 pt-3 tx-14 tx-bold tx-clomos-dark" v-if="Object.keys(editMachineData.variables.dynamic)[0]!=undefined&&Object.keys(editMachineData.variables.dynamic)[0]!='configuracion'">
                      {{"DIN"}}
                    </label>

                    <div v-for="variable in editMachineData.variables.dynamic" :key="variable.dynamicVariableId"
                    class="col-lg-3 col-md-6 col-12" :style="((variable.isConfiguration==='false')||(variable.isConfiguration==='true' && variable.label==='configuracion'))?'display:none':'display:block'">
                      <div class="m-0 p-0 w-100">
                        <label class="col-12 p-0 pt-1 tx-white">
                          {{variable.label}}
                        </label>
                        <input v-model="editMachineData.variables.dynamic[variable.label].value" 
                        :id="'inputEditMachine'+variable.label" type="text" 
                        :data-id="editMachineData.variables.dynamic[variable.label].variableId"
                        :data-type="editMachineData.variables.dynamic[variable.label].type" class="inputEditMachine form-control form-control-line">
                      </div>
                    </div>
                    <div class="col-12"></div>

                    <div class="col-lg-6 col-12 m-0 p-0">
                    </div>

                    <div class="col-12 mt-4 pb-4">
    <!-- SUBMIT SETTINGS-->
                        <button type="button" @click="setMachineVariables"
                        id="btnSubmitMachineVariables" class="button-ok gradient-tablas rounded-3">
                          <span class="button-text text-white">
                            <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                            <span class="text-white mt-0">{{dictionary['Guardar variables'][session.user.language]}}</span>
                          </span>
                        </button>
                      </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-3 col-12 d-flex flex-wrap">
            <div class="col-12">
              <div class="m-0 p-0 w-100">
                <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                  {{dictionary['Configuración'][session.user.language]}}
                  <span class="ps-1 small">(JSON)</span>
                </label>
                <textarea v-model="machineConfigurationForm.value" type="text" 
                id="inputEditMachineConfiguration"
                class="form-control form-control-line" style="color:black; text-align: start;"></textarea>
              </div>
            </div>
            <div class="col-lg-6 col-12 m-0 p-0">
            </div>
            <div class="form-group col-lg-6 col-12 p-0 pb-3">
                <div class="col-12 mt-4">
              <!-- SUBMIT CONFIGURATION-->
                  <button type="button" @click="setMachineConfiguration"
                  id="btnSubmitMachineConfiguration" class="button-ok gradient-tablas rounded-3">
                    <span class="button-text text-white">
                      <ion-icon name="create-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                      <span class="text-white mt-0">{{dictionary['Guardar configuración'][session.user.language]}}</span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "EditLockerModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    machineId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      editMachineData : {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        typeName: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        geoJSON: { 
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0,0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {},
        }
      },
      machineConfigurationForm: {
        id: "",
        variableId: "",
        value: ""
      },
      emplacementList: []
    }
  },
  emits: ["toggleEditLockerModal"],
  methods: {
    // SET
    async setEditMachineData() {
      let response = await api.setMachineData(this.editMachineData);
      if (response["status-code"] === "200") {
        window.alert("El locker ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el locker.");
      }
      this.getEditMachineData();
    },
    async setMachineVariables() {
      let variablesData = {
        machineId : this.editMachineData.id
      };
      $(".inputEditMachine").get().forEach(input => {
        variablesData[input.id.replace("inputEditMachine","")] = {
          value: (input.getAttribute("data-type")==="0")
            ?(input.getAttribute("data-value")==="true")
              ?1
              :0
            :input.value,
          id: input.getAttribute("data-id"),
          type: input.getAttribute("data-type")}
      });
      let response = await api.setMachineVariables(variablesData);
      if (response["status-code"] === "200") {
        window.alert("El locker ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el locker.");
      }
      this.getEditMachineData();
    },
    async setMachineConfiguration() {
      let response = await api.setMachineConfiguration(this.machineConfigurationForm);
      if (response["status-code"] === "200") {
        window.alert("El locker ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el locker.");
      }
      this.getEditMachineData();
    },
    // GET 
    async getEditMachineData(callback) {
      let response = await api.getMachineData(this.machineId);
      this.editMachineData = api.parseMachineFromResponse(response);
      if (this.editMachineData.variables.dynamic['configuracion']!=undefined)
        this.machineConfigurationForm = {
          id: this.editMachineData.id,
          variableId: this.editMachineData.variables.dynamic['configuracion'].variableId,
          value: this.editMachineData.variables.dynamic['configuracion'].value
        }
      else 
        this.machineConfigurationForm = {
          id: this.editMachineData.id,
          variableId: "",
          value: ""
        }
      if (callback && typeof callback === "function") callback(this.machineData);
    },
    async getEmplacementList() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getEmplacementList(request);
      for (let i in response.plantas) [
        this.emplacementList[i] = {
          id: response.plantas[i].idDcl,
          label: response.plantas[i].etiqueta,
        }
      ]
    }
  },
  async created() {
    await this.getEditMachineData();
    await this.getEmplacementList();
  }
}
</script>

<style>

#editMachineModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>