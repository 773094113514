<template>
  <!-- MODAL New Incidence -->
  <div id="newIncidenceModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">

<!-- HEAD New Incidence -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="warning-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            <!--  {{dictionary['Nueva incidencia'][session.user.language]}} -->{{ "Abrir Incidencia" }}
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleOpenNewIncidence')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

  <!-- CONTENT New Product -->
      <div class="bg-caja col-12 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

            <form class="p-1 m-0 border rounded form-material">
             

              <div class="row ">

 <!-- NOMBRE -->
                <div class="fform-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Nombre'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <input v-model="openNewIncidenceData.label" id="inputNewProductLabel" type="text"
                      class="form-control form-control-line" style="color:black;">
                  </div>
                </div>




              </div>

              <div class="row">

<!-- DESCRIPCIÓN -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    {{ dictionary['Observaciones'][session.user.language] }}
                  </label>
                  <div class="col-12">
                    <textarea v-model="openNewIncidenceData.observations" id="inputNewProductDescription" type="text"
                      rows="1" class="form-control form-control-line" style="color:black;" />
                  </div>
                </div>

              </div>

              <div class="row">
<!-- roles -->
                <div class="form-group col-12 p-1">
                  <label class="col-12 tx-13 text-dark text-nowrap">
                    <!-- {{dictionary['Unidad de medida'][session.user.language]}} -->{{ "Incidencia" }}
                  </label>
                  <div class="col-12">
                    <select v-model="openNewIncidenceData.rol" id="inputNewIncidenceRol"
                      class="form-control form-control-line" style="color:black;" >
                      <option value="128">
                        {{ dictionary["Administrador de la empresa"][session.user.language] }}
                      </option>
                      <option value="32">
                        {{ dictionary["Autorizado/Editor"][session.user.language] }}
                      </option>
                      <option value="2">
                        {{ dictionary["Administrador general"][session.user.language] }}
                      </option>
                    </select>
                  </div>
                </div>


              </div>
              <div class="row">

                <!-- SUBMIT -->
                <div class="form-group col-12 p-1">
                  <div class="col-12 mt-2 mb-2">
                    <button type="button" @click="onCreateNewProduct" id="btnSubmitCreateNewProduct"
                      class="button-ok gradient-tablas rounded-3">
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                        <span class="text-white mt-0">{{"Abrir nueva incidencia"}}</span>
                      </span>
                    </button>
                  </div>
                </div>

              </div>

            </form>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "OpenNewIncidenceModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    }
  },
  data() {
    return {
      openNewIncidenceData: {
        nifCif: this.$route.params.currentCompanyNifCif,
        label: "",
        observations: "",
        rol: [],
        sms: "false",
        email: "false",
        level:"",
        days:null,
        minutes:null,
        seconds:null,
        repetitionTime:null
      },
    }
  },
  emits: ["toggleOpenNewIncidence"],
  methods: {
    async onCreateNewProduct() {
      let valid = true;
      if (this.openNewIncidenceData.label === "") {
        $("#inputNewProductLabel").addClass("missing");
        valid = false;
      }
      if (this.openNewIncidenceData.description === "") {
        $("#inputNewProductDescription").addClass("missing");
        valid = false;
      }
      if (this.openNewIncidenceData.rol.length === 0) {
        $("#inputNewIncidenceRol").addClass("missing");
        valid = false;
      }
      if(this.openNewIncidenceData.days===null&&this.openNewIncidenceData.minutes===null&& this.openNewIncidenceData.seconds===null){
        $("#inputNewIncidenceDays").addClass("missing");~
        $("#inputNewIncidenceMinutes").addClass("missing");
        $("#inputNewIncidenceSeconds").addClass("missing");
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $(".missing").removeClass("missing");
        }, 3000);
        return;
      }
     
    const totalSeconds = (this.openNewIncidenceData.days !== null||this.openNewIncidenceData.days===""?this.openNewIncidenceData.days * 86400:0) + (this.openNewIncidenceData.minutes !== null||this.openNewIncidenceData.minutes===""?this.openNewIncidenceData.minutes * 60:0) + (this.openNewIncidenceData.seconds !== null||this.openNewIncidenceData.seconds===""?this.openNewIncidenceData.seconds:0);
    this.openNewIncidenceData.repetitionTime = totalSeconds.toString();


      try {
        console.log(this.openNewIncidenceData)
        let response = await api.postNewProduct(this.openNewIncidenceData);
        if (response["status-code"] === "201") {
          this.$emit('toggleOpenNewIncidence');
          window.alert("El producto ha sido creado satisfactoriamente.");
        } else {
          window.alert("Error al crear el producto.");
        }
      } catch (error) {
        console.error("Error creating product:", error);
        window.alert("Error al crear el producto.");
      }
    },

  }
}
</script>


<style>
#newIncidenceModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>