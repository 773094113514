<template>
  <!-- MODAL Prepare Expedition -->
    <div id="prepareExpeditionModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
      <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
  <!-- HEAD -->
        <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
          <div class="col-10">
            <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
              <ion-icon name="arrow-redo-outline" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary["Preparación"][session.user.language]}}
              <span class="tx-13 tx-light"> {{ (isProcessed===undefined)?"/ "+dictionary["Sin procesar"][session.user.language]:(!isProcessed)?"/ "+dictionary["Procesando"][session.user.language]:'' }} </span>
            </h6>
          </div>
  
          <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('togglePrepareExpeditionModal')">	
            <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
          </div>
        </div>
  
  <!-- CONTENT Prepare Expedition -->
        <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
          <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;" class="p-3">
            <div class="col-12 p-3 border rounded form-material" style="overflow: auto; overflow-y: hidden; display: flex; flex-flow: wrap;">

            <div v-if="expeditionData.params != undefined" id="expeditionCheckListContainer">
              
              <div class="col-12 tx-bold tx-left text-nowrap m-0 p-0">
                  <span class="tx-clomos-dark">
                    {{dictionary['Expedición'][session.user.language] + ": "}}
                  </span>
                  <span>
                    {{ expeditionData.id }}
                  </span>
              </div>

              <div v-if="expeditionData.summary!=undefined&&expeditionData.summary!=''" class="col-12 tx-bold tx-left text-nowrap m-0 p-0">
                  <span class="tx-clomos-dark">
                    {{dictionary['Pedido'][session.user.language] + ": "}}
                  </span>
                  <span>
                    {{ JSON.parse(expeditionData.summary)["idPedido"] }}
                  </span>
              </div>

              <div class="col-12 m-0 p-0">
                <hr class="tx-clomos-dark">
              </div>

              <table id="expeditionCheckList" cellspacing="0" border="0" class="col-12 m-0 p-0">
                <tr class="border-bottom">
                  <td class="tx-bold gap-1" style="display:flex; flex-direction:row; justify-content-space-between;">
                    <div v-show="expeditionData.status==='1'||expeditionData.status==='2'"
                    class="bg-clomos rounded-3 tx-white m-0" style="margin:auto; cursor:pointer; width: 30px; height: 30px; padding: 5px 5px;"
                    :class="(isRequesting)?'opacity-50':''"
                    @click="(!isRequesting)?getExpeditionData():''">
                      <ion-icon name="reload-outline" class="loader tx-20 tx-white align-bottom" :class="(isRequesting===true)?'requesting':''"></ion-icon>
                    </div>
                    <span class="m-auto pt-1"> {{dictionary['Identificador'][session.user.language]}} </span>
                  </td>
                  
                  <td class="tx-bold"> {{dictionary['Nombre'][session.user.language]}} </td>
              <!--
                  <td class="tx-bold"> {{dictionary['Talla'][session.user.language]}} </td>
                  <td class="tx-bold"> {{dictionary['Color'][session.user.language]}} </td>
                  -->
                  <td class="tx-bold"> {{dictionary['Caducidad'][session.user.language]}} </td>
                  <td class="tx-bold"> {{dictionary['Zona'][session.user.language]}} </td>

                  <td class="tx-bold"> {{dictionary['Unidades'][session.user.language]}} </td>

                  <td class="tx-bold text-nowrap"> {{dictionary['Encontrados'][session.user.language] +' / '+ dictionary['Procesados'][session.user.language] +' / '+ dictionary['Hueco'][session.user.language]}} </td>

                  <td class="tx-bold" v-show="expeditionData.status==='1'||expeditionData.status==='2'"> {{dictionary['Editar'][session.user.language]}} </td>
                  
                  <td class="tx-bold"> 
                    {{dictionary['Cajas'][session.user.language]}}
                    <ion-icon  v-show="expeditionData.status==='1'||expeditionData.status==='2'" 
                    name="add-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 tx-24" style="vertical-align: middle;"
                    @click="addBulge()">
                    </ion-icon>
                    <ion-icon  v-show="expeditionData.status==='1'||expeditionData.status==='2'" 
                    name="remove-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 tx-24" style="vertical-align: middle;"
                    @click="removeBulge()">
                    </ion-icon>
                  </td>
                  
                </tr>
                
                <tr class="border-bottom" v-for="product,index of expeditionData.params['productos']" :key="product" :id="'rowNumber'+index+'For'+product.id">
                  
                  <td class="text-nowrap p-1"> 
                    {{ (product.id!=undefined)?product.id:'-' }}
                  </td>

                  <td class="text-nowrap p-1"> 
                    {{ (product.etiqueta!=undefined)?product.etiqueta:'-' }}
                  </td>

              <!--
                  <td class="text-nowrap p-1"> 
                    {{ (product.talla!=undefined)?product.talla:'-' }}
                  </td>
                  <td class="text-nowrap p-1"> 
                    {{ (product.color!=undefined)?product.color:'-' }}
                  </td>
                  -->
                  <td class="text-nowrap p-1"> 
                    {{ (product.fechaCaducidad!=undefined)?product.fechaCaducidad:'-' }}
                  </td>

                  <td class="text-nowrap p-1"> 
                    <span v-show="(product.zona==='automatico')"> Automático </span>
                    <span v-show="(product.zona==='locker')"> Locker </span>
                    <span v-show="(product.zona==='libre')"> Libre </span>
                    <span v-show="(product.zona!=undefined && product.zona!='automatico' && product.zona!='locker' && product.zona!='libre')"> {{product.zona}} </span>
                    <span v-show="(product.zona===undefined)"> - </span>
                  </td>

                  <td class="text-nowrap p-1"> 
                    {{ (product.unidades!=undefined)?product.unidades:'-' }}
                  </td>

                  <td class="m-0 p-0 border-start">
                    <div v-if="expeditionData.params['procesadosRaw']!=undefined
                    && expeditionData.params['procesadosRaw'][product.id]!=undefined">
                      <div v-for="productRaw,raw of expeditionData.params['procesadosRaw'][product.id]" :key="productRaw"
                      class="m-0 p-0">
                        <tr v-show="(product.lote === undefined || productRaw.lote == product.lote)
                        && (product.numeroSerie === undefined || productRaw.numeroSerie == product.numeroSerie)
                        && (product.fechaCaducidad === undefined || productRaw.fechaCaducidad == product.fechaCaducidad)
                        && (product.talla === undefined || productRaw.talla == product.talla)
                        && (product.color === undefined || productRaw.color == product.color)"
                        class="text-nowrap h-100 p-1 border-bottom align-middle">
                          <td 
                          :class="((expeditionData.status==='1'||expeditionData.status==='2') && product.zona==='libre')?'':'opacity-50'"
                          class="text-nowrap p-0"> 
                            <div class="p-1"
                            @click="((expeditionData.status==='1'||expeditionData.status==='2') && product.zona==='libre')?removeRaw(productRaw,raw):''"
                            style="margin:auto; cursor:pointer; padding:auto;">
                              <ion-icon name="remove-circle-outline" class="tx-26 tx-clomos-dark align-middle"></ion-icon>
                            </div>
                          </td>

                          <td class="text-nowrap w-100" style="width:30%; min-width:110px; height:30px; vertical-align:middle;">
                            <span class="m-auto"> 
                              {{ (productRaw!=undefined&&productRaw.unidadesProcesables!=undefined&&productRaw.unidadesProcesables!="")?productRaw.unidadesProcesables:"-" }} 
                            </span>
                          </td>

                          <td class="text-nowrap w-100" style="width:30%; min-width:110px; vertical-align:middle;">
                            <span class="m-auto" :class="(processedOk(product)===false)?'tx-danger':''"> 
                              {{ (productRaw!=undefined&&processedOk(product)!=undefined)?(productRaw!=undefined&&processedOk(product)===false)?productRaw.unidades+' '+product.unidad+' ⚠':productRaw.unidades+' '+product.unidad:"-" }} 
                            </span>
                            <div class="m-0 p-0">
                              <input v-show="(product.zona==='locker'||product.zona==='libre') && (expeditionData.status==='1'||expeditionData.status==='2')"
                              :disabled="(expeditionData.status!='1'&&expeditionData.status!='2')"
                              :id="'inputProcessedUnits'+raw+'For'+product.id" value="" :placeholder="product.unidad" type="number" min=0 class="w-100 tx-14 border-1 p-1 rounded-3 m-auto text-center" style="color:black">
                            </div>
                          </td>

                          <td class="text-nowrap w-100" style="width:30%; min-width:110px; height:30px; vertical-align:middle;"> 
                            <span class="m-auto">
                              {{ processedSlot(product,raw) }}
                            </span>
                            <select v-show="(expeditionData.status==='1'||expeditionData.status==='2') && product.zona==='libre'" :id="'selectSlotId'+raw+'For'+product.id" value="A1" 
                            :disabled="(expeditionData.status!='1'&&expeditionData.status!='2')"
                            class="form-control w-100 m-auto" style="color:black"> 
                              <option v-for="slot in freeSlotList" :key="slot" :value="slot.id"> {{slot.label}} </option>
                            </select>
                          </td>
                        </tr>
                      </div>
                    </div>

                    <div 
                    :class="((expeditionData.status==='1'||expeditionData.status==='2') && product.zona==='libre')?'':'opacity-50'"
                    class="p-1" style="margin:auto; cursor:pointer;">
                      <ion-icon @click="((expeditionData.status==='1'||expeditionData.status==='2') && product.zona==='libre')?addRaw(product):''" name="add-circle-outline" class="tx-26 tx-clomos-dark align-middle"></ion-icon>
                    </div>

                  </td>

                  <td class="p-2 border-start"
                  v-show="(expeditionData.status==='1'||expeditionData.status==='2')">
                    <div :class="((product.zona==='locker'||product.zona==='libre')
                    &&expeditionData.params['procesadosRaw']!=undefined
                    &&expeditionData.params['procesadosRaw'][product.id]!=undefined
                    &&expeditionData.params['procesadosRaw'][product.id].length>0)?'':'opacity-50'"
                    @click="((product.zona==='locker'||product.zona==='libre')
                    &&expeditionData.params['procesadosRaw']!=undefined
                    &&expeditionData.params['procesadosRaw'][product.id]!=undefined
                    &&expeditionData.params['procesadosRaw'][product.id].length>0)?process(product):''"
                    class="bg-clomos rounded-3 tx-white m-auto"
                    style="width:30px; height:30px; margin:auto; cursor:pointer; padding:auto; padding: 3px 0 0 2px;">
                      <ion-icon name="create-outline" class="tx-20 tx-white align-middle"></ion-icon>
                    </div> 
                  </td>

                  <td class="p-1 border-start">
                    <div style="display:flex; flex-direction:row; flex-flow:wrap; gap:0.3rem; justify-content:center; width:310px; margin:auto;">
                      <div v-for="bulge, index in bulgeList" :key="bulge" class="m-0 p-0">
                        <div 
                        class="m-0 border-clomos rounded-3 p-1" 
                        style="justify-content-center-content: space-around; width: 100%; white-space:nowrap">
                          <span class="tx-14 tx-bold tx-clomos-dark">
                            {{ ((index+1)+'').padStart(2,'0') }}
                          </span>
                          <span style="position:relative; left:60px; bottom: 0px; z-index: 10;" class="tx-clomos-midtone">
                            {{ "u" }}
                          </span>
                          <input v-model="product.bultos[index]" type="number" min=0 :max="product.unidades" placeholder=0 :disabled="expeditionData.status!='1'&&expeditionData.status!='2'"
                          class="inputAmmountPerBulge border-2 rounded-1 text-center"
                          :class="(isBulgeEmpty(index)&&(expeditionData.status==='1'||expeditionData.status==='2'))?'missing':''">
                        </div>
                      </div>
                    </div>
                  </td>
                  
                </tr>

                <tr>
                  <td></td>
                  <td></td>
              <!--
                  <td></td>
                  <td></td>
                  -->
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td v-show="expeditionData.status==='1'||expeditionData.status==='2'"> 
                    <div @click="assignBulgeAll()" class="gradient-tablas rounded-3 p-2 m-0 d-flex justify-content-center text-nowrap" style="width:310px; margin: auto; cursor: pointer;"> 
                      <span class="tx-white tx-14"> 
                        {{dictionary["Asignar todos"][session.user.language]}}
                      </span>
                    </div>
                  </td>
                </tr>
              </table>

            </div>

            <div class="col-12 m-0 p-0">
              <hr class="tx-clomos-dark">
            </div>
            
            <div class="col-12 m-0 p-0">
              <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
                {{dictionary['Cajas'][session.user.language]}}
              </label>
            </div>

            <div class="col-12 row m-0 p-0">
              <div class="d-flex">
<!-- BULTO -->
                <div class="col-2 col-2 p-1 text-center">
                  <label class="tx-13 tx-bold tx-clomos-black text-nowrap p-0">
                    {{dictionary['Bulto'][session.user.language]}}
                  </label>
                </div>

<!-- TIPO -->
                <div class="col-2 col-6 p-1 text-center">
                  <label class="tx-13 tx-bold tx-clomos-black text-nowrap p-0">
                    {{dictionary['Tipo'][session.user.language]}}
                  </label>
                </div>

<!-- TOTAL REFERENCIAS -->
                <div class="col-2 col-2 p-1 text-center">
                  <label class="tx-13 tx-bold tx-clomos-black p-0">
                    {{dictionary['Productos'][session.user.language]+" /"+dictionary['Bulto'][session.user.language]}}
                  </label>
                </div>

<!-- TOTAL UNIDADES -->
                <div class="col-2 col-2 p-1 text-center">
                  <label class="tx-13 tx-bold tx-clomos-black p-0">
                    {{dictionary['Uds.'][session.user.language]+dictionary['Producto'][session.user.language]+" /"+dictionary['Bulto'][session.user.language]}}
                  </label>
                </div>

              </div>

              <div class="d-flex" v-for="(bulge,index) in bulgeList" :key="bulge">
<!-- BULTO -->
                <div class="col-2 col-2 p-1">
                  <div class="tx-20 tx-bold tx-clomos-dark pb-1">
                  {{ ((index+1)+'').padStart(2,'0') }}
                  </div>
                </div>

<!-- TIPO -->
                <div class="col-2 col-6 p-1">
                    <select v-model="bulgeList[index].boxType" :id="'inputBulgeType'+index" 
                    class="form-control form-control-line pt-2 pb-2 w-100"
                    :disabled="(expeditionData.status!='1'&&expeditionData.status!='2')?true:false"
                    style="color:black;"
                    @change="assignBoxType(index)">
                      <option class="text-center"  v-for="boxType in boxTypes" :key="boxType" :value="boxType.id"> {{ boxType.id + ' ' + boxType.volume.width + 'x' + boxType.volume.height + 'x' + boxType.volume.depth+'(mm)' }} </option>
                    </select>
                </div>

<!-- TOTAL REFERENCIAS -->
                <div class="col-2 col-2 p-1">
                  <span type="number" min=0 disabled class="tx-12" style="color:black;">
                    {{ totalProductsPerBulge(index) }}
                  </span>
                </div>

<!-- TOTAL UNIDADES -->
                <div class="col-2 col-2 p-1">
                  <span type="number" min=0 disabled class="tx-12" style="color:black;">
                    {{ totalUnitsPerBulge(index) }}
                  </span>
                </div>

              </div>
              
            </div>
    
            <div v-show="expeditionData.status==='1'||expeditionData.status==='2'" class="w-100 m-0 p-0 pt-3 row">

<!-- SUBMIT -->
              <button type="button" id="btnSubmitExpedition" class="col-lg-7 col-12 button-ok gradient-tablas rounded-3"
              :class="(isProcessed!=undefined&&expeditionData.type==='1'||isProcessed===true&&expeditionData.type==='4')?'btn-clomos':'btn-clomos-disabled'"
              @click="prepareExpedition">
              {{dictionary['Preparar'][session.user.language]}}
              </button>

              <div class="col-lg-1 col-12 p-2"></div>

              <button type="button" id="btnCancelExpedition" class="col-lg-3 col-12 ms-auto button-ok gradient-tablas rounded-3"
              :class="(expeditionData.type==='4')?'btn-clomos':'btn-clomos-disabled'"
              @click="cancelExpedition">
              {{dictionary['Cancelar'][session.user.language]}}
              </button>

            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import boxTypesJson from "/src/assets/json/transport_box_types.json";
// Clomos
import api from '@/services/api';

export default {
  name: "ExpeditionsComponent",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    },
    expeditionId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      boxTypes: JSON.parse(JSON.stringify(boxTypesJson)),
      expeditionData: {},
      lockerSlotList: [],
      freeSlotList: [],
      autoSlotList: [],
      bulgeModel: {
        boxType: "",
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
      },
      bulgeList: [
        {
          boxType: "",
          volume: {
            height: 0,
            width: 0,
            depth: 0
          },
        }
      ],
      isRequesting: false
    }
  },
  emits: ["togglePrepareExpeditionModal", "setCurrentCompany"],
  computed: {
    totalBulges() {
      let totalBulges = {};
      for (let i in this.bulgeList) {
        if (totalBulges[this.bulgeList[i].boxType] === undefined)
          
          totalBulges[this.bulgeList[i].boxType] = {
            volume: {
              height: this.bulgeList[i].volume.height,
              width: this.bulgeList[i].volume.width,
              depth: this.bulgeList[i].volume.depth
            },
            total: 1
          }

        else
          totalBulges[this.bulgeList[i].boxType].total += 1;

      }
      return totalBulges;
    },
    isProcessed() {
      let isProcessed = undefined;
      if (this.expeditionData.params != undefined && this.expeditionData.params["procesadosRaw"] != undefined) {
        isProcessed = true;
        for (let i in this.expeditionData.params['productos']) {
          if (Number(this.expeditionData.params['productos'][i].unidades) != this.processedUnits(this.expeditionData.params['productos'][i])) {
            isProcessed = false;
          }
        }
      }
      return isProcessed;
    }
  },
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    processedOk(product) {
      let ok = undefined;
      let n = 0; 
      if (this.expeditionData.params["procesadosRaw"] != undefined) {
        for (let i in this.expeditionData.params["procesadosRaw"]) {
          for (let r in this.expeditionData.params["procesadosRaw"][i]) {
            if (this.expeditionData.params["procesadosRaw"][i][r]!=null 
            && this.expeditionData.params["procesadosRaw"][i][r]!=undefined 
            && this.expeditionData.params["procesadosRaw"][i][r].id == product.id
            && (product.lote === undefined || product.lote === "" || this.expeditionData.params["procesadosRaw"][i][r].lote == product.lote)
            && (product.numeroSerie === undefined || product.numeroSerie === "" || this.expeditionData.params["procesadosRaw"][i][r].numeroSerie == product.numeroSerie)
            && (product.fechaCaducidad === undefined || product.fechaCaducidad === "" || this.expeditionData.params["procesadosRaw"][i][r].fechaCaducidad == product.fechaCaducidad)
            && (product.talla === undefined || product.talla === "" || this.expeditionData.params["procesadosRaw"][i][r].talla == product.talla)
            && (product.color === undefined || product.color === "" || this.expeditionData.params["procesadosRaw"][i][r].color == product.color)) {
              ok = false;
              n += Number(this.expeditionData.params["procesadosRaw"][i][r].unidades);
            }
          }
        }
      }
      if (Number(product.unidades) === n)
        ok = true;
      return ok;
    },
    processedUnits(product) {
      let units = 0;
      if (this.expeditionData.params["procesadosRaw"] != undefined) {
        for (let i in this.expeditionData.params["procesadosRaw"]) {
          for (let r in this.expeditionData.params["procesadosRaw"][i]) {
            if (this.expeditionData.params["procesadosRaw"][i][r]!=null 
            && this.expeditionData.params["procesadosRaw"][i][r]!=undefined 
            && this.expeditionData.params["procesadosRaw"][i][r].id == product.id
            && (product.lote === undefined || product.lote === "" || this.expeditionData.params["procesadosRaw"][i][r].lote == product.lote)
            && (product.numeroSerie === undefined || product.numeroSerie === "" || this.expeditionData.params["procesadosRaw"][i][r].numeroSerie == product.numeroSerie)
            && (product.fechaCaducidad === undefined || product.fechaCaducidad === "" || this.expeditionData.params["procesadosRaw"][i][r].fechaCaducidad == product.fechaCaducidad)
            && (product.talla === undefined || product.talla === "" || this.expeditionData.params["procesadosRaw"][i][r].talla == product.talla)
            && (product.color === undefined || product.color === "" || this.expeditionData.params["procesadosRaw"][i][r].color == product.color)) {
              units += this.expeditionData.params["procesadosRaw"][i][r].unidades;
            }
          }
        }
      }
      return units;
    },
    processedSlot(product,r) {
      let slot = "-";
      if (this.expeditionData.params["procesadosRaw"] != undefined) {
        for (let i in this.expeditionData.params["procesadosRaw"]) {
          if (this.expeditionData.params["procesadosRaw"][i][r]!=null 
          && this.expeditionData.params["procesadosRaw"][i][r]!=undefined 
          && this.expeditionData.params["procesadosRaw"][i][r].id == product.id
          && (product.lote === undefined || product.lote === "" || this.expeditionData.params["procesadosRaw"][i][r].lote == product.lote)
          && (product.numeroSerie === undefined || product.numeroSerie === "" || this.expeditionData.params["procesadosRaw"][i][r].numeroSerie == product.numeroSerie)
          && (product.fechaCaducidad === undefined || product.fechaCaducidad === "" || this.expeditionData.params["procesadosRaw"][i][r].fechaCaducidad == product.fechaCaducidad)
          && (product.talla === undefined || product.talla === "" || this.expeditionData.params["procesadosRaw"][i][r].talla == product.talla)
          && (product.color === undefined || product.color === "" || this.expeditionData.params["procesadosRaw"][i][r].color == product.color)
          && (this.expeditionData.params["procesadosRaw"][i][r].idHueco != undefined && this.expeditionData.params["procesadosRaw"][i][r].idHueco != "")) {
            for (slot in this.lockerSlotList) {
              if (this.lockerSlotList[slot].id === this.expeditionData.params["procesadosRaw"][i][r].idHueco) {
                return this.lockerSlotList[slot].label;
              }
            }
            for (slot in this.freeSlotList) {
              if (this.freeSlotList[slot].id === this.expeditionData.params["procesadosRaw"][i][r].idHueco) {
                return this.freeSlotList[slot].label;
              }
            }
            for (slot in this.autoSlotList) {
              if (this.autoSlotList[slot].id === this.expeditionData.params["procesadosRaw"][i][r].idHueco) {
                return this.autoSlotList[slot].label;
              }
            }
            return this.expeditionData.params["procesadosRaw"][i][r].idHueco;
          }
        }
      }
      return slot;
    },
    addBulge() {
        this.bulgeList.push(JSON.parse(JSON.stringify({...this.bulgeModel, id: this.bulgeList.length})));
        this.bulgeList[this.bulgeList.length-1].boxType = this.boxTypes[0].id;
        this.assignBoxType(this.bulgeList.length-1);
    },
    removeBulge() {
      if (this.bulgeList[1] === undefined) return;
      for (let i in this.expeditionData.params['productos']) {
        delete this.expeditionData.params['productos'][i].bultos[this.bulgeList.length-1]
      }
      this.bulgeList.pop();
    },
    assignBulgeAll() {
      if (this.bulgeList.length>0) for (let i in this.expeditionData.params['productos']) {
        if (this.expeditionData.params['productos'][i].bultos === undefined
        || this.expeditionData.params['productos'][i].bultos === "") {
          this.expeditionData.params['productos'][i].bultos = [];
        }
        this.expeditionData.params['productos'][i].bultos[0] = this.expeditionData.params['productos'][i].unidades;
      }
    },
    assignBoxType(index) {
      for (let i in this.boxTypes) {
        if (this.boxTypes[i].id === this.bulgeList[index].boxType) {
          this.bulgeList[index].volume = this.boxTypes[i].volume;
          return;
        }
      }
      this.prepareExpedition();
    },
    totalProductsPerBulge(index) {
      let products = 0;
      if (this.expeditionData.params != undefined) for (let product in this.expeditionData.params['productos']) {
        if (this.expeditionData.params['productos'][product]['bultos'] != undefined
        && this.expeditionData.params['productos'][product]['bultos'][index] != undefined) {
          products += 1
        }
      }
      return products;
    },
    totalUnitsPerBulge(index) {
      let units = 0;
      if (this.expeditionData.params != undefined) for (let product in this.expeditionData.params['productos']) {
        if (this.expeditionData.params['productos'][product]['bultos'] != undefined
        && this.expeditionData.params['productos'][product]['bultos'][index] != undefined) {
          units += Number(this.expeditionData.params['productos'][product]['bultos'][index])
        }
      }
      return units;
    },
    isBulgeEmpty(index) {
      let isEmpty = true; 
      for (let product in this.expeditionData.params['productos']) {
        if (this.expeditionData.params['productos'][product]['bultos'] != undefined 
        && this.expeditionData.params['productos'][product]['bultos'][index] != undefined )
          isEmpty = false;
      }
      return isEmpty;
    },
    // GET
    async getExpeditionData(callback) {
      this.isRequesting = true;
      let response = await api.getOrderData(this.expeditionId);
      this.expeditionData = api.parseOrderFromResponse(response);
      await this.getAvailableSlots();
      this.expeditionData.userId = this.session.user.id;
      this.isRequesting = false;
      if (callback && typeof callback === "function") callback(this.expeditionData);
    },
    async getAvailableSlots() {
      this.lockerSlotList = [];
      this.freeSlotList = [];
      this.autoSlotList = [];
      let machineListRequest = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      }
      let machineListResponse = await api.getMachineListByCompany(machineListRequest);
      let parsedMachineList = api.parseMachinesFromResponse(machineListResponse);
      for (let i in parsedMachineList) {
        let slotListRequest = {
          machineId: parsedMachineList[i].id,
          limit: 0,
          offset: 0,
          where: ""
        }
        let slotListResponse = await api.getBundleListByMachine(slotListRequest);
        let parsedSlotListResponse = api.parseBundlesFromResponse(slotListResponse);
        for (let ii in parsedSlotListResponse) {
          if (parsedMachineList[i].dclTypeId === "locker_frio") this.lockerSlotList.push(parsedSlotListResponse[ii]);
          if (parsedMachineList[i].dclTypeId === "zona_libre") this.freeSlotList.push(parsedSlotListResponse[ii]);
          if (parsedMachineList[i].dclTypeId === "zona_automatico") this.autoSlotList.push(parsedSlotListResponse[ii]);
        }
      }
    },
    // SET
    addRaw(product) {
      if (this.expeditionData.params['procesadosRaw'] === undefined)
        this.expeditionData.params['procesadosRaw'] = {};
      if (this.expeditionData.params['procesadosRaw'][product.id] === undefined) 
        this.expeditionData.params['procesadosRaw'][product.id] = [];
      let raw = {
        id: (product.id!=undefined)?product.id:"",
        zona: (product.zona!=undefined)?product.zona:"",
        lote: (product.lote!=undefined)?product.lote:"",
        numeroSerie: (product.numeroSerie!=undefined)?product.numeroSerie:"",
        fechaCaducidad: (product.fechaCaducidad!=undefined)?product.fechaCaducidad:"",
        talla: (product.talla!=undefined)?product.talla:"",
        color: (product.color!=undefined)?product.color:"",
        unidad: (product.unidad!=undefined)?product.unidad:"",
        unidadesPedidas: (product.unidades!=undefined)?product.unidades:"",
        unidadesProcesables: "",
        unidades: 0,
        idHueco: "",
      }
      this.expeditionData.params['procesadosRaw'][product.id].push(raw);
    },
    removeRaw(product, raw) {
      if (product.zona!='locker' && product.zona!='libre') return;
      if (this.expeditionData.params['procesadosRaw'][product.id][raw] != undefined) {
        this.expeditionData.params['procesadosRaw'][product.id].splice(raw,1);
      }
    },
    async process(product,index) {
    // Procesados Raw
      if (this.expeditionData.params['procesadosRaw'] === undefined)
        this.expeditionData.params['procesadosRaw'] = {};
      if (this.expeditionData.params['procesadosRaw'][product.id] === undefined) {
        this.expeditionData.params['procesadosRaw'][product.id] = [];
        let raw = {
          id: product.id,
          zona: product.zona,
          lote: product.lote,
          numeroSerie: product.numeroSerie,
          fechaCaducidad: product.fechaCaducidad,
          talla: product.talla,
          color: product.color,
          unidadesPedidas: product.unidades,
          unidadesProcesables: ""
        }
        this.expeditionData.params['procesadosRaw'][product.id].push(raw);
        for (let rawIndex in this.expeditionData.params['procesadosRaw'][product.id]) {
          if (product.zona==='libre')
            this.expeditionData.params['procesadosRaw'][product.id][rawIndex].idHueco = $("#selectSlotId"+(Number(index)+Number(rawIndex))+'For'+product.id).get()[0].value;
          if (product.zona==='locker'||product.zona==='libre')
            if($("#inputProcessedUnits"+(Number(index)+Number(rawIndex))+'For'+product.id).get()[0].value!="")
              this.expeditionData.params['procesadosRaw'][product.id][rawIndex].unidades = Number($("#inputProcessedUnits"+(Number(index)+Number(rawIndex))+'For'+product.id).get()[0].value);
        }
      }
      else {
        for (let rawIndex in this.expeditionData.params['procesadosRaw'][product.id]) {
          if (this.expeditionData.params['procesadosRaw'][product.id][rawIndex] === null )
            this.expeditionData.params['procesadosRaw'][product.id].splice(rawIndex,1);
          else if (this.expeditionData.params['procesadosRaw'][product.id][rawIndex].id === product.id
          && (product.lote === undefined || product.lote === "" || this.expeditionData.params["procesadosRaw"][product.id][rawIndex].lote == product.lote)
          && (product.numeroSerie === undefined || product.numeroSerie === "" || this.expeditionData.params["procesadosRaw"][product.id][rawIndex].numeroSerie == product.numeroSerie)
          && (product.fechaCaducidad === undefined || product.fechaCaducidad === "" || this.expeditionData.params["procesadosRaw"][product.id][rawIndex].fechaCaducidad == product.fechaCaducidad)
          && (product.talla === undefined || product.talla === "" || this.expeditionData.params["procesadosRaw"][product.id][rawIndex].talla == product.talla)
          && (product.color === undefined || product.color === "" || this.expeditionData.params["procesadosRaw"][product.id][rawIndex].color == product.color)) {
            if (product.zona==='libre') 
              this.expeditionData.params['procesadosRaw'][product.id][rawIndex].idHueco = $("#selectSlotId"+(Number(index)+Number(rawIndex))+'For'+product.id).get()[0].value;
            if (product.zona==='locker'||product.zona==='libre')
              if($("#inputProcessedUnits"+(Number(index)+Number(rawIndex))+'For'+product.id).get()[0].value!="")
                this.expeditionData.params['procesadosRaw'][product.id][rawIndex].unidades = Number($("#inputProcessedUnits"+(Number(index)+Number(rawIndex))+'For'+product.id).get()[0].value);
          }
        }
      }
      let orderData = {
        id: this.expeditionData.id,
        userId: this.session.user.id,
        params: this.expeditionData.params,
      };
      let response = await api.setOrderData(orderData);
      if (response["status-code"] === "200") {
        window.alert("El producto ha sido procesado satisfactoriamente.");
      }
      else if (response["status-code"] === "304") {
        window.alert("El producto no ha sido modificado.");
      }
      else {
        window.alert("Error al procesar el producto.");
      }
    },
    async prepareExpedition() {
      // Procesados
      if (this.expeditionData.params['procesados'] === undefined)
        this.expeditionData.params['procesados'] = {};
      for (let productId in this.expeditionData.params['procesadosRaw']) {
        let entry = this.expeditionData.params['procesadosRaw'][productId];
        for (let rawIndex in entry) {
          let productRaw = entry[rawIndex];
          let dominantKey;
          if (productRaw.zona==="locker" || productRaw.zona==="libre") {
            if (this.expeditionData.params['procesados'][productRaw.idStock]!=undefined) 
              dominantKey = productRaw.idStock;
            else
              dominantKey = String(Object.keys(this.expeditionData.params['procesados']).length);
            if (this.expeditionData.params['procesados'][dominantKey] === undefined) {
              this.expeditionData.params['procesados'][dominantKey] = {
                id: productRaw.id,
                unidades: productRaw.unidades, 
                fechaEntrada: api.parseDateTimeToString(Date.now()),
                lote: (productRaw.lote!=undefined)?productRaw.lote:"",
                numeroSerie: (productRaw.numeroSerie!=undefined)?productRaw.numeroSerie:"",
                fechaCaducidad: (productRaw.fechaCaducidad!=undefined)?productRaw.fechaCaducidad:"",
                talla: (productRaw.talla!=undefined)?productRaw.talla:"",
                color: (productRaw.color!=undefined)?productRaw.color:"",
                idHueco: (productRaw.idHueco!=undefined)?productRaw.idHueco:"", 
                idStock: (productRaw.idStock!=undefined)?productRaw.idStock:"", 
              }
            }
            else {
              this.expeditionData.params['procesados'][dominantKey].unidades += productRaw.unidades;
            }
          }
        }
      }
      let valid = true;
      for (let i in this.bulgeList) {
        if (this.bulgeList[i].boxType === "") {
          $("#inputBulgeType"+i).get()[0].classList.add("missing")
          valid = false;
        }
      }
      if (!valid) {
        setTimeout(() => {
          for (let i in this.bulgeList) {
            $("#inputBulgeType"+i).get()[0].classList.remove("missing")
          }
        }, 3000);
        return;
      }
      this.expeditionData.params['bultos'] = [];
      for (let i in this.bulgeList) {
        if (this.isBulgeEmpty(i)) return; 
        this.expeditionData.params['bultos'][i] = {
          "ancho": this.totalBulges[this.bulgeList[i].boxType].volume.width,
          "alto": this.totalBulges[this.bulgeList[i].boxType].volume.height,
          "fondo": this.totalBulges[this.bulgeList[i].boxType].volume.depth,
        }
      }
      if (this.expeditionData.executionDateTime === "") {
        this.expeditionData.executionDateTime = new Date();
      }
      let response = await api.setOrderData(this.expeditionData);
      if (response["status-code"] === "200" || response["status-code"] === "304") {
        let statusData = {
          orderId : this.expeditionId,
          dateTime : new Date(),
          status : 3
        }
        response = await api.setOrderStatus(statusData);
        if (response["status-code"] === "200") {
          window.alert("La expedición ha sido preparada satisfactoriamente.");
          this.$emit('togglePrepareExpeditionModal');
        }
        else {
          window.alert("Error al preparar la expedición.");
        }
      }
      else {
        window.alert("Error al procesar la expedición.");
      }
    },
    // CANCEL
    async cancelExpedition() {
      if (this.expeditionData.status == 2) {
        let statusData = {
          orderId : this.expeditionId,
          dateTime : new Date(),
          status : 5
        }
        let response = await api.setOrderStatus(statusData);
        if (response["status-code"] === "200") {
          window.alert("La expedición ha sido cancelada satisfactoriamente.");
          this.$emit('togglePrepareExpeditionModal');
        }
        else {
          window.alert("Error al cancelar la expedición.");
        }
      }
    }
  },
  async created() {
    this.bulgeList[0].boxType = this.boxTypes[0].id;
    this.assignBoxType(0);
    await this.getExpeditionData(async(expeditionData)=>{
      if (expeditionData.status == 1) {
        let statusData = {
          orderId : expeditionData.id,
          dateTime : new Date(),
          status : 2
        }
        let response = await api.setOrderStatus(statusData);
        if (response["status-code"] === "200") {
          window.alert("La expedición está siendo ejecutada.");
        }
      }
      if (expeditionData.status == 1 || expeditionData.status == 2) {
        for (let i in this.expeditionData.params['productos']) {
          this.expeditionData.params['productos'][i]["bultos"] = [];
        }
      }
    });
  }
}
</script>

<style>
#prepareExpeditionModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.inputAmmountPerBulge {
  color:black;
  background:none;
  min-width:65px;
  max-width:65px;
  padding: 3px 15px 1px 1px;
}

.inputAmmountPerBulge.missing {
  box-shadow: inset 0px 0px 0px 2px #ce7d0a;
}

.inputAmmountPerBulge::-webkit-outer-spin-button,
.inputAmmountPerBulge::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputAmmountPerBulge[type=number] {
  -moz-appearance: textfield;
}

.order-button {
  color: var(--main-dark) !important;
}

.order-button:hover {
  color: var(--main-dark-50) !important;
}

.order-button-disabled {
  cursor: none;
  color: var(--main-dark-50) !important;
  pointer-events: none
  ;
}

.loader {
  color: white !important;
  visibility: visible;
  transition: all 0.3s;
}

.requesting {
  animation: spin 3s infinite linear;
  transition: all 0.3s;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>