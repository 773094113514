<template>
<!-- SECTION Bundles-->
  <div id="bundlesSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
  
<!-- HEADER Bundles -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
        <div class="bg-menu border-1 rounded-5 box sombra">
          <!--<ion-icon v-if="session.user.bookmark==='bundles'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->          
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
            <ion-icon name="grid" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary["Huecos"][session.user.language]}}
            </h6>
          </div>

          <div class="p-3 pt-0">
<!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">	
              <hr v-if="this.$route.params.currentDisplay==='slots'" class="m-0 p-0">
              
              <select v-if="this.$route.params.currentDisplay==='slots'"
              :value="currentCompany.nifCif"
              class="form-control mt-3 mb-3 h-100 text-nowrap bg-white" 
              style="border: solid 2px white ; color: black;"
              @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif"
                :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif"
                :value="company.nifCif">
                  {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                </option>
              </select>

              <hr class="m-0 p-0 mb-3">

              <div class="row justify-content-around m-0 p-0">
                <div class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('locker')"
                  :style="(displayType==='locker')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="grid-outline" 
                    :style="(displayType==='locker')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
                <div class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('table')"
                  :style="(displayType==='table')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="list-outline" 
                    :style="(displayType==='table')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
                <div class="col-3 m-0 mb-3 p-0">	
                  <button @click="onChangeDisplayType('cards')"
                  :style="(displayType==='cards')?'opacity=1;':'opacity: 0.7'"
                  class="border-white col-12 header-tab-button border-1 btn gradient-tablas rounded-5 p-0 text-nowrap">
                    <ion-icon name="documents-outline" 
                    :style="(displayType==='cards')?'opacity=1;':'opacity: 0.5'"
                    class="border-white tx-23 pt-3 pb-3" style="vertical-align: middle;"></ion-icon>
                  </button>
                </div>
              </div>

              <hr class="m-0 p-0">

              <div class="col-12 p-0 justify-content-center d-flex">
                <button id="btnDisplayNewSlotModal" type="button" class="button-ok gradient-tablas mt-3 rounded-3" 
                @click="toggleNewSlotModal">
                  <span class="button-text text-white">
                    <ion-icon name="add-circle-outline" class="text-white pt-0 me-2 tx-24 hydrated"></ion-icon>
                    <span class="text-white mt-0 text-nowrap">{{dictionary["Añadir hueco"][session.user.language]}}</span>
                  </span>
                </button>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>

      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
          <!--<ion-icon v-if="session.user.bookmark==='bundles'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
            <ion-icon name="grid-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            <span> {{dictionary["Huecos"][session.user.language] }} </span>
            <span v-if="this.$route.params.currentDisplay==='slots' || this.targetMachineId == ''"> 
              {{ ": "+currentCompany.name }} 
            </span>
            <span v-else-if="this.targetMachineData.label != undefined"> 
              {{ ": "+targetMachineData.label }} 
            </span>
          </div>

<!-- LOCKERS Bundles -->
          <div v-show="displayType==='locker'" :class="(displayType==='locker')?'d-flex':''" class="form-group p-3 m-3 border rounded"
          style="overflow: scroll; overflow-x: auto; overflow-y: hidden;">
            <div v-if="lockerSlotsGrid.length>0" class="row col-8 rounded-3 justify-content-around"> 
              <table class="row tx-black fw-medium justify-content-around h-100" styles="border: solid white 3px; border-radius: 10px;">
                <tr v-for="row in lockerSlotsGrid" :key="row">
                  <td v-for="slot in row" :key="slot"
                  class="tx-white p-0 m-0" style="border: solid white 3px; border-radius: 8px; width: 100px;height: 100px;"
                  :style="{  backgroundColor:
                  (slot.variables.analog.estado.value==='') ? 'silver' :
                  Number(slot.variables.analog.estado.value) === 0 ? 'gray':
                  Number(slot.variables.analog.estado.value) === 1 ? 'orange' : 
                  Number(slot.variables.analog.estado.value) === 2 ? 'red' : 
                  Number(slot.variables.analog.estado.value) === 3 ? 'orange':
                  'transparent' }"> 
                    <div v-if="slot.label!=undefined">
                      {{ slot.label }} <img v-if="slot.variables.digital['accesible'] != undefined && slot.variables.digital.accesible.value==='true'" src="@/assets/img/discapacidades.png" :data-id="slot.id" class=" m-0 ms-1 p-0 icon-size tx-white" style="width: 18px; height: 18px; filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);">
                    </div>
                    <div>
                      {{ (slot.variables.analog.estado.value==='') ? "No hay ningún hueco." :
                      (Number(slot.variables.analog.estado.value) === 0) ?"Vacío" :
                      (Number(slot.variables.analog.estado.value) === 1) ?"Reservado" :
                      (Number(slot.variables.analog.estado.value) === 2) ?"Ocupado" :
                      (Number(slot.variables.analog.estado.value) === 3) ?"Espera" : 
                      "" }}
                    </div>
                    <div v-if="slot.id!=''" class="col-12 d-flex justify-content-around m-0 p-0 pt-2 pb-1">
                    <ion-icon v-if="slot.variables.dynamic['tipo'] != undefined" :name='slot.variables.dynamic.tipo.value==="frio" ? "snow-outline" : "sunny-outline"' :data-id="slot.id" class='m-0 p-0 tx-18 tx-white'></ion-icon>
                    <ion-icon v-if="slot.variables.digital['cerrado'] !== undefined" :name='slot.variables.digital["cerrado"].value === "false" ? "lock-open-outline" : "lock-closed-outline"' class='m-0 p-0 tx-18 tx-white'></ion-icon> 
                    <ion-icon name='settings-outline' :data-id="slot.id" class='btnEditBundle btn m-0 p-0 tx-18 tx-white'></ion-icon> 
                  </div>
                  </td> 
                </tr>
              </table>
            </div>

            <span v-else class="tx-13"> {{ dictionary["No hay huecos disponibles"][session.user.language]+"." }} </span>

            <div v-if="targetMachineData.variables" class="col-4 border-start text-start tx-24 ms-3 ps-3">
              <ion-icon @click="onRefresh" name="reload-outline" class="border-3 rounded tx-20 tx-dark p-2" style="cursor:pointer;"></ion-icon>
              <br>
              <label class="tx-white tx-18 text-nowrap border-1 rounded-5 p-2 ps-4 pe-4 mb-2 bg-dark" style="max-width: 280px;"> <span class="ps-4" style="width: 180px;">Huecos totales:</span> <span class="ps-4" style="width: 40px;"> {{targetMachineData.variables.analog.huecosTotales.value}} </span> </label>
              <br>
              <label class="tx-white tx-18 text-nowrap border-1 rounded-5 p-2 ps-4 pe-4 mb-2 bg-secondary" style="max-width: 280px;"> <span class="ps-4" style="width: 180px;">Huecos libres:</span> <span class="ps-4"> {{targetMachineData.variables.analog.huecosLibres.value}} </span> </label>
              <br>
              <label class="tx-white tx-18 text-nowrap border-1 rounded-5 p-2 ps-4 pe-4 mb-2 bg-danger" style="max-width: 280px;"> <span class="ps-4" style="width: 180px;">Huecos ocupados:</span> <span class="ps-4"> {{targetMachineData.variables.analog.huecosOcupados.value}} </span> </label>
              <br>
            </div>
          </div>

<!-- TABLE Bundles -->
          <div v-show="displayType==='table'" class="m-0 p-3 h-auto">
            <table id="bundlesDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in bundlesColumns" :key="column.data"
                  :width="column.width" class="fw-medium text-nowrap p-2">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>

<!-- CARDS Bundles -->
          <div v-show="displayType==='cards'&&this.bundleList[0]!=undefined"
          id="bundlesCardsContent" class="col-12 m-0 p-2 h-auto"
          :style="(!display.showEverything)?'height: 15rem':''">
            <div class="col-12 m-0 p-0 d-flex flex-row flex-wrap">
              <div v-for="bundle in bundleCardsByPages[bundleCardsPage]" :key="bundle"
              class="col-xl-2 col-md-4 col-12 m-0 p-2">
                <h6 class="tx-left tx-clomos-white text-center text-nowrap m-0 p-2 ps-1 pt-2 gradient-tablas rounded-top-3">
                  <span class="m-auto">{{(bundle.label.length>18)?(bundle.label.substring(0,18)+'...'):bundle.label}}</span>
                </h6>

                <div class="col-12 border-1 rounded-bottom-3 m-0 p-3">
                  <div class="col-12 m-0 p-0 d-flex space-between">
                    <ion-icon name="pulse-outline" :data-id="bundle.id" class="btnTargetBundle col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                    <ion-icon name="create-outline" :data-id="bundle.id" class="btnEditBundle col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-primary"></ion-icon>
                    <ion-icon name="trash-outline" :data-id="bundle.id" class="btnDeleteBundle col-2 m-0 mt-auto mb-auto p-0 btn tx-24 tx-danger"></ion-icon>
                  </div>
                  <hr class="col-12 mt-2 mb-2">
                  <div class="col-12 d-flex flex-column tx-left align-middle p-0" style="height: 5rem;">
                    <span class="tx-13 tx-bold">{{dictionary['Tipo'][session.user.language]+": "}}</span>
                    <span class="tx-11 tx-start">{{bundle.type}}</span>
                    <span class="tx-13 tx-bold">{{dictionary['Identificador'][session.user.language]+": "}}</span>
                    <span class="tx-11 tx-start">{{bundle.id}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex flex-row m-0 p-2">
                <div v-for="page,index in bundleCardsByPages" :key="page" 
                class="border rounded c-pointer m-0 me-2 tx-13" :class="(bundleCardsPage===index)?'border-dark':''" style="height:2.5rem; width:2.5rem; padding: inherit;"
                @click="onSetCardPage(index)">
                  <span class="tx-bold">{{ index+1  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-show="displayType==='cards'&&this.bundleList[0]==undefined" class="m-3 p-3 border rounded">
            <span class="tx-13"> 
              {{dictionary['No hay dispositivos disponibles'][session.user.language]}} 
            </span> 
          </div>

        </div>
      </div>

    </div>
  </div>

<!-- SECTION Bundles -->
  <!-- <StatusComponent v-if="this.$route.params.currentDisplay==='slots'"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :requestedBundleId="targetBundleId"
  :dataTableOptions="dataTableOptions">
  </StatusComponent> -->

<!-- MODAL New Bundle -->
  <NewSlotModal
  v-if="display.newSlotModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :targetMachineId="targetMachineId" 
  v-on:toggleNewSlotModal="toggleNewSlotModal">
  </NewSlotModal>


  <!-- MODAL Edit Slot -->
  <EditSlotModal
  v-if="display.editSlotModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :targetLockerId="targetMachineId"
  :slotId="targetBundleId"
  v-on:toggleEditSlotModal="toggleEditSlotModal">
  </EditSlotModal> 

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteSlotModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Hueco a eliminar'][session.user.language] + ': '"
  :alertTarget="targetBundleId"
  v-on:acceptAlert="onDeleteBundle"
  v-on:dismissAlert="toggleDeleteSlotModal">
  </TextAlertModal>
</template>

<script>
import $ from 'jquery';
//import StatusComponent from '@/components/StatusComponent';
import NewSlotModal from '@/components/lockers/NewSlotModal';
import EditSlotModal from '@/components/lockers/EditSlotModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "SlotComponent",
  components: { /* StatusComponent,*/ EditSlotModal, NewSlotModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    targetMachineId: {
      type: String,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      if (this.$route.params.currentDisplay === 'slots' || this.targetMachineId == '') {
        await this.getBundleListByCompany();
      }
      else {
        await this.getMachineData();
        await this.getBundleListByMachine();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.bundleList[0] != undefined && this.targetBundleId === "")
        this.targetBundleId = this.bundleList[0].id;
    },
    targetMachineId: async function() {
      if (this.$route.params.currentDisplay === 'slots' || this.targetMachineId == '') {
        await this.getBundleListByCompany();
      }
      else {
        await this.getMachineData();
        await this.getBundleListByMachine();
      }
      this.onChangeDisplayType(this.displayType);
      if (this.bundleList[0] != undefined && this.targetBundleId === "")
        this.targetBundleId = this.bundleList[0].id;
    }
  },
  data() {
    return {
      bundlesColumns: [
        {text:"Identificador", data: "bundleId", width: "20%", className:"tx-12 tx-left align-middle dt-nowrap",
          render: (data) => {
            return "<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-14'></ion-icon>"
            +"<span data-id="+data+" class='btnTargetProduct c-pointer tx-12 text-nowrap align-middle'>"+data+"</span>";
          }
        },
        {text:"Etiqueta", data: "bundleLabel", width: "10%", className:"tx-12 tx-left tx-bold align-middle"},
        {text:"Tipo", data: "bundleType", width: "15%", className:"tx-12 tx-left align-middle"},
        {text:"Emplazamiento", data: "bundleEmplacement", width: "5%", className:"tx-12 tx-left align-middle",
          render: (data) => {
            let emplacementLabel = data;
            if (this.machineList.length > 0) {
              for (let emplacement in this.machineList)
                if (this.machineList[emplacement].id === data) 
                  emplacementLabel = this.machineList[emplacement].label;
            }
            return emplacementLabel;
          }
        },
        {text:"Token", data: "bundleToken", width: "10%", className:"tx-12 tx-left tx-bold align-middle"},
        {text:"Fecha de comunicación", data: "bundleCommunicationDateTime", width: "10%", className:"tx-12 align-middle",
          render: (data) => {
            return (data!="")
            ?"<span style='display: none;' class=' tx-12'>" + data.getTime() + "</span> <spam class=' tx-12'>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
            :"<span style='display: none;' class=' tx-12'></span> <spam class=' tx-12'>-</spam>";
          }
        },
        {text:"Coordenadas", data: "bundleGeoJSON", width: "15%",className:"tx-12 tx-left align-middle",
        render: (data) => {
            if (data.geometry!=undefined && data.geometry.type==="Point") return "<div class='d-flex pt-1'><span class='me-2 ms-2 tx-primary'>Lat:</span> <span>"+Number(data.geometry.coordinates[0])+"</span> <span class='me-2 ms-2 tx-primary'>Lon:</span> <span>"+Number(data.geometry.coordinates[1]) 
            else return "<span class='me-2 ms-2 tx-primary'>Lat:</span> <span>"+0+"</span>  <span class='me-2 ms-2 tx-primary'> Lon:</span></div> "+0
          }
        },
        {text:"Configuración", data: "bundleId", width: "5%", className:"align-middle",
          render: (data) => {
            return "<ion-icon name='settings-outline' data-id="+data+" class='btnEditBundle btn tx-16 tx-primary'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "bundleId", width: "5%", className:"align-middle",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteBundle btn tx-16 tx-danger'></ion-icon>"
          }
        },
      ],
      targetMachineData: {},
      targetBundleId: this.$route.params.targetEntity,
      bundleList: [],
      machineList: [],
      lockerSlotsGrid: [],
      displayType : 'locker',
      bundleCardsPage: 0,
      display: {
        newSlotModal: false,
        editSlotModal: false,
        linkSlotModal: false,
        deleteSlotModal: false,
      },
      linkAction: "link"
    }
  },
  computed: {
    bundleCardsByPages(){
      let bundleCardsByPages = []
      let i = 0; 
      while (i*12 < this.bundleList.length) {
        bundleCardsByPages.push(this.bundleList.slice(i*12,i*12+12));
        i++;
      }
      return bundleCardsByPages;
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    onChangeDisplayType(type) {
      this.displayType = type;
      setTimeout(()=>{
        if (type === "locker") this.displayBundlesLocker();
        if (type === "table") this.displayBundlesDataTable();
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.bundleCardsPage = index;
      this.setButtonFunctions();
    },
    displayBundlesLocker() {
      if (this.targetMachineData.variables === undefined) return; 
      if (this.targetMachineData.variables.analog.numeroFilas === undefined
      || this.targetMachineData.variables.analog.numeroColumnas === undefined) 
        return;
      let rows = parseInt(this.targetMachineData.variables.analog.numeroFilas.value);
      let columns = parseInt(this.targetMachineData.variables.analog.numeroColumnas.value);
      this.lockerSlotsGrid = [];
      let r0 = 0;
      while (r0 < rows) {
        this.lockerSlotsGrid.push([]);
        let c0 = 0;
        while (c0 < columns) {
          this.lockerSlotsGrid[r0][c0] = {"id": "", "label": "", "variables": {"analog": {"estado": {"value": ""}}, "digital": {"cerrado": {"value": ""}}}};
          for (let bundle in this.bundleList) {
            if (this.bundleList[bundle].geoJSON != undefined
            && this.bundleList[bundle].geoJSON.geometry != undefined
            && this.bundleList[bundle].geoJSON.geometry.coordinates != undefined
            && this.bundleList[bundle].geoJSON.geometry.coordinates[0] == r0
            && this.bundleList[bundle].geoJSON.geometry.coordinates[1] == c0) {
              this.lockerSlotsGrid[r0][c0] = this.bundleList[bundle]
            }
          }
          c0 += 1;
        }
        r0 += 1;
      }
      this.setButtonFunctions();
    },
    onRefresh() {
      this.getMachineData();
      this.getBundleListByMachine(this.displayBundlesLocker);
    },
    displayBundlesDataTable() {
      $("#bundlesDataTable").DataTable().clear()
      for (let i in this.bundleList) {
        $("#bundlesDataTable").DataTable().rows.add([{
          bundleId: this.bundleList[i].id, 
          bundleEmplacement: this.bundleList[i].parent,
          bundleLocation: this.bundleList[i].parent,
          bundleLabel: this.bundleList[i].label, 
          bundleType: this.bundleList[i].type, 
          bundleStatus: this.bundleList[i].variables.analog.estado.value, 
          bundleCommunicationDateTime: (this.bundleList[i].communicationDateTime != undefined && this.bundleList[i].communicationDateTime != "") ? new Date(this.bundleList[i].communicationDateTime) : "", 
          bundleGeoJSON: this.bundleList[i].geoJSON,
          bundleToken: this.bundleList[i].token
        }]);
      }
      $("#bundlesDataTable").DataTable().draw();
      this.setButtonFunctions();
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
      if($("#bundlesDataTable_paginate").get()[0] != undefined) $("#bundlesDataTable_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
    },
    async toggleNewSlotModal() {
      if (this.display.newSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetMachineId == '') {
          await this.getBundleListByCompany();
          this.displayBundlesDataTable();
        }
        else {
          await this.getMachineData();
          await this.getBundleListByMachine();
          this.displayBundlesDataTable();
        }
      }
      this.display.newSlotModal = !this.display.newSlotModal;
    },
    async toggleEditSlotModal() {
      if (this.display.editSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetMachineId == '') {
          await this.getBundleListByCompany();
          this.displayBundlesDataTable();
        }
        else {
          await this.getMachineData();
          await this.getBundleListByMachine();
          this.displayBundlesDataTable();
        }
      }
      this.display.editSlotModal = !this.display.editSlotModal;
    },
    async toggleLinkSlotModal() {
      if (this.display.linkSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetMachineId == '') {
          await this.getBundleListByCompany();
          this.displayBundlesDataTable();
        }
        else {

          await this.getMachineData();
          await this.getBundleListByMachine();
          this.displayBundlesDataTable();
        }
      }
      this.display.linkSlotModal = !this.display.linkSlotModal;
    },
    async toggleDeleteSlotModal() {
      if (this.display.deleteSlotModal) {
        if (this.$route.params.currentDisplay === 'slots' || this.targetMachineId == '') {
          await this.getBundleListByCompany();
          this.displayBundlesDataTable();
        }
        else {
          await this.getMachineData();
          await this.getBundleListByMachine();
          this.displayBundlesDataTable();
        }
        if (this.bundleList[0] != undefined) this.targetBundleId = this.bundleList[0].id;
      }
      this.display.deleteSlotModal = !this.display.deleteSlotModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetBundle);
        });
        $(".btnEditBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onEditBundle);
        });
        $(".btnLinkBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onLinkBundle);
        });
        $(".btnUnlinkBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onUnlinkBundle);
        });
        $(".btnDeleteBundle").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteBundle);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getBundleListByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getBundleListByCompany(request);
      this.bundleList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.bundleList);
    },
    async getBundleListByMachine(callback) {
      let request = {
        machineId: this.targetMachineId,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getBundleListByMachine(request);
      this.bundleList = api.parseBundlesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.bundleList);
    },
    async getMachineData(callback) {
      let response = await api.getMachineData(this.targetMachineId);
      this.targetMachineData = api.parseMachineFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetMachineData);
    },
    async getMachinesDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      this.machineList = api.parseMachinesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.machineList);
    },
    // TARGET 
    onTargetBundle(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetBundleId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetBundleId}})
    },
    // SET 
    onEditBundle(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetBundleId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetBundleId}})
      this.toggleEditSlotModal();
    },
    // LINK 
    onLinkBundle(event) {
      console.log('LINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetBundleId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetBundleId}})
      this.linkAction = "link"
      this.toggleLinkSlotModal();
    },
    // UNLINK 
    onUnlinkBundle(event) {
      console.log('UNLINK WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetBundleId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetBundleId}})
      this.linkAction = "unlink"
      this.toggleLinkSlotModal();
    },
    // DELETE
    async onDeleteBundle(event) {
      if (!this.display.deleteSlotModal) {
        console.log('DELETE WHERE ID:');
        console.log(this.targetBundleId);
        this.targetBundleId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetBundleId}})
      }
      else {
        let response = await api.deleteBundle(this.targetBundleId);
        if (response["status-code"] === "200") {
          window.alert("El hueco ha sido eliminado satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar el hueco.");
        }
      }
      await this.toggleDeleteSlotModal();
    }
  },
  async created() {
    await this.getMachinesDataByCompany();
    if (this.$route.params.currentDisplay === 'slots') {
      await this.getBundleListByCompany();
    }
    else if (this.targetMachineId != ''){
      await this.getMachineData();
      await this.getBundleListByMachine();
    }
    this.$emit('getAvailableCompanies',this.onChangeDisplayType(this.displayType));
    if (this.bundleList[0] != undefined && this.targetBundleId === "")
      this.targetBundleId = this.bundleList[0].id;
  },
  mounted() {
    $("#bundlesDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.bundlesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
.btonCopyId:hover, .btnTargetBundle:hover, .btnLinkBundle:hover, .btnUnlinkBundle:hover, .btnEditBundle:hover, .btnDeleteBundle:hover {
  color: var(--main-dark) !important;
}
</style>