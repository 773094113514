<template>
<!-- SECTION Warnings -->
  <div id="companiesSection" class="row col-12 m-0 p-0">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">

<!-- HEADER Warnings -->
      <div class="col-lg-2 col-md-4 col-12 m-0 p-2 text-white rounded-5">
        <div class="bg-menu border-1 rounded-5 box sombra">
          
          <div class="col-12 overflow-hidden justify-content-center">
            <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 ps-1 pt-1">
            <ion-icon name="mail" class="tx-18 me-2 align-bottom"></ion-icon>
              {{dictionary["Avisos"][session.user.language]}}
            </h6>
          </div>
          
          <div class="p-3 pt-0">
<!-- Selector de Sistema -->
            <div class="col-12 m-0 p-0">	
              <hr class="m-0 p-0">
                
              <select
              :value="currentCompany.nifCif"
              class="form-control mt-3 h-100 text-nowrap bg-white" 
              style="border: solid 2px white ; color: black;"
              @change="onCurrentCompanyChanged">
                <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
                <option v-for="company in availableCompanies" :key="company.nifCif"
                :v-if="company.nifCif != session.user.nifCif
                && company.relation != undefined
                && company.relation.referenceCompany === this.session.user.nifCif"
                :value="company.nifCif">
                  {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                </option>
              </select>

            </div>
            
          </div>
          
        </div>
      </div>

<!-- HEADER Warnings -->
      <div class="col-lg-10 col-md-8 col-12 m-0 p-2 overflow-show justify-content-center">
        <div class="col-12 table-responsive bg-caja rounded-top-5 m-0 p-0 sombra">
          <!--<ion-icon v-if="session.user.bookmark==='companies'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <div class="position-sticky col-12 m-0 pt-2 pb-2 gradient-tablas text-white border-1" >
            <ion-icon name="mail-outline" class="tx-16 mb-1 me-2 align-bottom"></ion-icon>
            {{dictionary["Avisos"][session.user.language]}}
          </div>

          <div class="m-0 p-3 overflow-auto">
            <table id="warningsDataTable" class="table table-striped p-0 pb-3 bg-caja w-100">
              <thead class="gradient-tablas tx-14 text-white text-center overflow-hidden">
                <tr>
                  <th v-for="column in warningsColumns" :key="column.data"
                  :width="column.width" class="tx-medium fw-medium text-nowrap">
                    {{dictionary[column.text][session.user.language]}}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "WarningsComponent",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getWarningList();
      this.displayWarningsDataTable();
    }
  },
  data() {
    return {
      warningsColumns: [
        {text:"", data: "warningReadDateTime", width: "5%", orderable: false,
          render: (data) => {
            return (data.value != "")
            ? "<ion-icon name='mail-open-outline' data-id="+data.id+" class='tx-24 tx-primary p-auto m-auto'></ion-icon>"
            : "<ion-icon name='mail-outline' data-id="+data.id+" class='tx-24 tx-primary p-auto m-auto'></ion-icon>";
          }
        },
        {text:"Detalles", data: "warningDescription", width: "15%"},
        {text:"Etiqueta", data: "warningLabel", width: "10%"},
        {text:"Es alarma", data: "warningIsAlarm", width: "10%",
          render: (data) => {
            return data;
          }
        },
        {text:"Es notificación", data: "warningIsNotification", width: "10%",
          render: (data) => {
            return data;
          }
        },
        {text:"Estado", data: "warningStatus", width: "10%",
          render: (data) => {
            switch (data) {
              case "0":
                return "Desactivado";
                case "1":
                  return "Preactivado";
                case "2":
                  return "Activado";
                case "3":
                  return "Postactivado";
              default:
                return "-";
            }
          }
        },
        {text:"Mostrar", data: "warningCondition", width: "10%",
          render: (data) => {
              return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailWarning btn tx-24 tx-primary p-auto m-auto'></ion-icon>";
          }
        }
      ],
      targetWarningId: "",
      warningList: [],
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    displayWarningsDataTable() {
      $("#warningsDataTable").DataTable().clear()
      for (let i in this.warningList) {
        $("#warningsDataTable").DataTable().rows.add([{
          warningId: this.warningList[i].id, 
          warningLabel: this.warningList[i].label, 
          warningDescription: this.warningList[i].description, 
          warningIsAlarm: this.warningList[i].isAlarm, 
          warningIsNotification: this.warningList[i].isNotification, 
          warningType: this.warningList[i].type,
          warningStatus: this.warningList[i].status, 
          warningNotificationDateTime: this.warningList[i].notificationDateTime, 
          warningReadDateTime: {id: this.warningList[i].id , value:this.warningList[i].readDateTime}, 
          warningActivationDateTime: this.warningList[i].activationDateTime, 
          warningPreactiveDateTime: this.warningList[i].preactiveDateTime, 
          warningSilencedDateTime: this.warningList[i].silencedDateTime,
          warningCondition: this.warningList[i].conditition 
        }]);
      }
      $("#warningsDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$emit('setCurrentCompany',value);
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetWarning").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetWarning);
        });
        $(".btnDetailWarning").get().forEach(btn => {
          btn.addEventListener('click', this.onDetailWarning)
        });
      }, 300);
    },
    // GET
    async getWarningList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getWarningList(request);
      this.warningList = api.parseWarningsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.warningList);
    },
    // TARGET 
    onTargetWarning(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetWarningId = event.target.getAttribute('data-id');
    },
  },
  async created() {
    await this.getWarningList();
    this.$emit('getAvailableCompanies',(this.displayWarningsDataTable));
  },
  mounted() {
    $("#warningsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.warningsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>
<style>
#prepareWarningModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.btnDetailWarning:hover {
  color: var(--main-dark) !important;
}

</style>