<template>
<!-- SIDE MENU Left -->
<div :class="(visibility.sideMenu)?'nav-side-menu-left':'nav-side-menu-left-collapsed'"
  class="bg-menu overflow-hidden">  
  <div style="position: relative; height: 4.5rem; margin:0;"></div>

    <!-- Nombre de usuario -->
    <div class="menu-user p-4">
      <div class="top-menu-profile-button"
      style="vertical-align: middle;"
      @click="onLeftSideMenuItemClicked('profile')">
        <ion-icon name="person" class="top-menu-icon tx-white mt-2 align-middle" style="font-size: 25px"></ion-icon>
      </div>
      <div class="col ps-3" style="align-content: center;">  
        <p class="tx-16 m-0 p-0"><a class="text-white m-0 p-0" style="align-self: center;" 
        @click="onLeftSideMenuItemClicked('profile')">
          {{session.user.name+" "+session.user.lastName}}
        </a></p>
      </div>
    </div>
    <div class="col-12">
        <a class="tx-white text-nowrap border-1 border-danger rounded-5 m-0 p-2"
        @click="logOutRequest">
          <ion-icon name="exit-outline" :alt="dictionary['Cerrar sesión'][session.user.language]"
          class="menu-item-icon tx-22 align-bottom ms-2 mt-1"></ion-icon>
          <span class="ms-1 me-2"> 
            {{dictionary["Cerrar sesión"][session.user.language]}} 
          </span>
        </a> 
    </div>
    
    <!-- LOG OUT -->
    <div class="w-100 p-3 pb-0">
      <hr class="w-100 text-white">
    </div>

    <div class="row p-0 m-0 justify-content-center"
    v-for="page, pageName in generalPages" :key="pageName">
      <a class="mb-3 text-nowrap"
      :class="(this.$route.params.currentDisplay===page.value)?'tx-focus':'text-white'"
      @click="onLeftSideMenuItemClicked(pageName)">
        <ion-icon :name="page.icon" :alt="dictionary[page.text][session.user.language]"
        class="menu-item-icon tx-30 align-bottom me-2 hydrated"></ion-icon>
        <span class="ms-1 lh-md">
          {{dictionary[page.text][session.user.language]}}
        </span>
      </a> 
      <!--<hr style="{padding-right: 10%; padding-left: 10%; width: 100%;}">-->
    </div>
  </div>

  <!-- SIDE MENU Right -->
  <div :class="(visibility.sideMenu)?'nav-side-menu-right':'nav-side-menu-right-collapsed'"
  class="bg-white ms-3 pb-5" style="overflow: scroll; overflow-x: hidden; scrollbar-width: none;">
    <div class="row m-0 p-0 justify-content-around">
      <div v-for="module, moduleName in this.availableModules" :key="moduleName"
      class="row col-lg-3 col-12" style="max-height: 140px;">

        <div class="col-lg-12 col-md-3 col-12 mt-5 p-0 m-0 border text-center rounded-5 sombra">
          <h5 for="chk">
            <a @click="onModuleClicked(moduleName)"
            class="col-12 aside-anchor text-center text-dark" style="font-size: 14px !important;">
              <ion-icon :name="this.modules[moduleName].icon" class="tx-20 text-dark me-3 pt-3"></ion-icon>
              {{ dictionary[this.modules[moduleName].text][session.user.language].toUpperCase() }}
            </a>
          </h5>
        </div>
        
        <div id="ocultar2" class="border rounded-5 mt-3 sombra"> 
          <ul class="col-12 row m-0">
            <li v-for="page, pageName in module" :key="pageName"
            @click="onRightSideMenuItemClicked(pageName, moduleName)"
            class="col-12 tx-16 mb-3 text-dark" style="list-style: none; cursor: pointer;">
              <button class="aside-list-button text-dark"> {{ dictionary[this.pages[pageName].text][session.user.language] }} </button>
            </li>
          </ul>
        </div>
        
      </div>

      <div v-if="!isDevicesVisible && !isFabricVisible && !isSmartlockersVisible && !isStorageVisible" class="card col-12 m-5 ps-4 pe-1 text-dark" >
        <div class="row col-lg-12 col-md-12 col-12 bg-dark border-1 border-white border-1 rounded-5 p-0">
                <img title="Logotipo" src="@/assets/img/hexagonos.png" class="clomos-log-in-logo col-lg-12 col-md-12 col-12 img-fluid m-auto p-3" style="max-width: 400px; max-height:auto;">
        </div>
      </div>

      <!--Dashboard para fabricación-->
      <div v-show="isFabricVisible" class="card col-12 m-5 ps-4 pe-1 text-dark">
        <div class="row col-lg-12 col-md-12 col-12 border-1 border-white border-1 rounded-5 p-0 sombra" style="min-height: 400px;">
          <div class="col-lg-4 col-md-4 p-1 pt-1 content-item text-center aos-init aos-animate">
          <p class="text-dark">
          <img title="Logotipo" src="@/assets/img/s-almacen.jpg" class="clomos-log-in-logo col-lg-12 col-md-12 col-12 img-fluid m-auto p-3" style="max-width: 400px; max-height:auto;">
          </p>
        </div>
        <div class="col-lg-8 col-md-8 content-item border-start-0 p-4 aos-init aos-animate">
            <div class="row col-12">
              <h4 class="d-flex mt-0 text-dark">Gestión y monitorización</h4>
              <ul class=" text-dark text-start mt-5">
                  <li class="mb-3"><strong>Cuadros de mando inteligentes</strong> </li>
                  <li class="mb-3"><strong>Gestión de la cadena de suministro</strong> </li>
                  <li class="mb-3"><strong>Gemelos digitales</strong> </li>
                  <li class="mb-3"><strong>Mantenimiento predictivo</strong> </li>
                  <li class="mb-3"><strong>Gestión energética</strong> </li>
                  <li class="mb-3"><strong>Seguridad en el trabajo</strong> </li>
                </ul>
                <em class=" text-dark">* Es importante tener en cuenta que nuestros paneles de control de smart lockers pueden variar según las necesidades y características específicas de la empresa. CLOMOS ofrece diferentes funcionalidades para adaptarse a las particularidades de cada negocio.</em>
              <p></p>
            </div>
        </div>

      </div>
      </div>
      <!--Dashboard para devices-->
      <div v-show="isDevicesVisible" class="card col-12 m-5 ps-4 pe-1 text-dark">
        <div class="row col-lg-12 col-md-12 col-12 border-1 border-white border-1 rounded-5 p-0 sombra" style="min-height: 400px;">
          <div class="col-lg-4 col-md-4 p-1 pt-1 content-item text-center aos-init aos-animate">
          <p class="text-dark">
          <img title="Logotipo" src="@/assets/img/s-almacen.jpg" class="clomos-log-in-logo col-lg-12 col-md-12 col-12 img-fluid m-auto p-3" style="max-width: 400px; max-height:auto;">
          </p>
        </div>
        <div class="col-lg-8 col-md-8 content-item border-start-0 p-4 aos-init aos-animate">
            <div class="row col-12">
              <h4 class="d-flex mt-0 text-dark">Gestión de dispositivos</h4>
              <ul class=" text-dark text-start mt-5">
                  <li class="mb-3"><strong>Visualización y control remoto de dispositivos</strong> </li>
                  <li class="mb-3"><strong>Gestión de dispositivos</strong> </li>
                  <li class="mb-3"><strong>Monitorización en tiempo real</strong> </li>
                  <li class="mb-3"><strong>Geolocalización</strong> </li>
                  <li class="mb-3"><strong>Análisis de datos</strong> </li>
                  <li class="mb-3"><strong>Configuración de reglas y notificaciones</strong> </li>
                  <li class="mb-3"><strong>Integración con otras plataformas</strong> </li>
                  <li class="mb-3"><strong>Seguridad</strong> </li>
                </ul>
                <em class=" text-dark">* Es importante tener en cuenta que nuestros paneles de control de smart lockers pueden variar según las necesidades y características específicas de la empresa. CLOMOS ofrece diferentes funcionalidades para adaptarse a las particularidades de cada negocio.</em>
              <p></p>
            </div>
        </div>

      </div>
    </div>
      <!--Dashboard para almacen-->
      <div v-show="isStorageVisible" class="card col-12 m-5 ps-4 pe-1 text-dark">
        <div class="row col-lg-12 col-md-12 col-12 border-1 border-white border-1 rounded-5 p-0 sombra" style="min-height: 400px;">
          <div class="col-lg-4 col-md-4 p-1 pt-1 content-item text-center aos-init aos-animate">
          <p class="text-dark">
          <img title="Logotipo" src="@/assets/img/s-almacen.jpg" class="clomos-log-in-logo col-lg-12 col-md-12 col-12 img-fluid m-auto p-3" style="max-width: 400px; max-height:auto;">
          </p>
        </div>
        <div class="col-lg-8 col-md-8 content-item border-start-0 p-4 aos-init aos-animate">
            <div class="row col-12">
              <h4 class="d-flex mt-0 text-dark">Gestión de almacén</h4>
              <ul class=" text-dark text-start mt-5">
                  <li class="mb-3"><strong>Seguimiento de envíos</strong> </li>
                  <li class="mb-3"><strong>Gestión de inventario</strong> </li>
                  <li class="mb-3"><strong>Planificación y programación</strong> </li>
                  <li class="mb-3"><strong>Gestión de almacenes</strong> </li>
                  <li class="mb-3"><strong>Análisis de datos y métricas</strong> </li>
                  <li class="mb-3"><strong>Integración con sistemas externos</strong> </li>
                  <li class="mb-3"><strong>Gestión de proveedores y socios comerciales</strong> </li>
                  <li class="mb-3"><strong>Monitorización de indicadores clave de rendimiento (KPI)</strong> </li>
                </ul>
                <em class=" text-dark">* Es importante tener en cuenta que nuestros paneles de control de smart lockers pueden variar según las necesidades y características específicas de la empresa. CLOMOS ofrece diferentes funcionalidades para adaptarse a las particularidades de cada negocio.</em>
              <p></p>
            </div>
        </div>

      </div>
      </div>
      <!--Dashboard para lockers-->
      <div v-show="isSmartlockersVisible" class="card col-12 m-5 ps-4 pe-1 text-dark">
        <div class="row col-lg-12 col-md-12 col-12 border-1 border-white border-1 rounded-5 p-0 sombra" style="min-height: 400px;">
          <div class="col-lg-4 col-md-4 p-1 pt-1 content-item text-center aos-init aos-animate">
          <p class="text-dark">
          <img title="Logotipo" src="@/assets/img/s-almacen.jpg" class="clomos-log-in-logo col-lg-12 col-md-12 col-12 img-fluid m-auto p-3" style="max-width: 400px; max-height:auto;">
          </p>
        </div>
        <div class="col-lg-8 col-md-8 content-item border-start-0 p-4 aos-init aos-animate">
            <div class="row col-12">
              <h4 class="d-flex mt-0 text-dark">Gestión Smart Lockers</h4>
              <ul class=" text-dark text-start mt-5">
                  <li class="mb-3"><strong>Gestión de pedidos</strong> </li>
                  <li class="mb-3"><strong>Gestión de inventario</strong> </li>
                  <li class="mb-3"><strong>Logística y seguimiento de entregas</strong> </li>
                  <li class="mb-3"><strong>Gestión de proveedores</strong> </li>
                  <li class="mb-3"><strong>Gestión de la calidad y seguridad alimentaria</strong> </li>
                  <li class="mb-3"><strong>Análisis y reportes</strong> </li>
                  <li class="mb-3"><strong>Integración con sistemas externos</strong> </li>
                  <li class="mb-3"><strong>Personalización y configuración</strong> </li>
                </ul>
                <em class=" text-dark">* Es importante tener en cuenta que nuestros paneles de control de smart lockers pueden variar según las necesidades y características específicas de la empresa. CLOMOS ofrece diferentes funcionalidades para adaptarse a las particularidades de cada negocio.</em>
              <p></p>
            </div>
        </div>

      </div>
    </div>

      <div v-if="this.availableModules['devices']===undefined"
      class="col-lg-5 col-md-5 col-11 mt-5 border bg-menu text-center rounded-5 sombra"
      style="pointer-events: none; opacity: 0.5;">
        <h5>
          <a class="col-12 aside-anchor text-center">
            <ion-icon name="lock-closed-outline" class="tx-20 me-3 pt-3"></ion-icon>
            {{ dictionary['Dispositivos'][session.user.language].toUpperCase() }}
          </a>
        </h5>
        <ul class="col-12 row m-0 p-0">
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Emplazamientos'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Dispositivos'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Gráficas'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Avisos'][session.user.language] }} </button>
          </li>
        </ul>
      </div>
      
      <div v-if="this.availableModules['fabric']===undefined"
      class="col-lg-5 col-md-5 col-11 mt-5 border bg-menu text-center rounded-5 sombra"
      style="pointer-events: none; opacity: 0.5;">
        <h5>
          <a class="col-12 aside-anchor text-center">
            <ion-icon name="lock-closed-outline" class="tx-18 me-3 pt-3"></ion-icon>
            {{ dictionary['Fabricación'][session.user.language].toUpperCase() }}
          </a>
        </h5>
        <ul class="col-12 row m-0 p-0">
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Emplazamientos'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Máquinas'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Conjuntos'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Avisos'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Incidencias'][session.user.language] }} </button>
          </li>
        </ul>
      </div>
      
      <div v-if="this.availableModules['storage']===undefined"
      class="col-lg-5 col-md-5 col-11 mt-5 border bg-menu text-center rounded-5 sombra"
      style="pointer-events: none; opacity: 0.5;">
        <h5>
          <a class="col-12 aside-anchor text-center">
            <ion-icon name="lock-closed-outline" class="tx-20 me-3 pt-3"></ion-icon>
            {{ dictionary['Almacén'][session.user.language].toUpperCase() }}
          </a>
        </h5>
        <ul class="col-12 row m-0 p-0">
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Emplazamientos'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Stock'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Recepciones'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Expediciones'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Avisos'][session.user.language] }} </button>
          </li>
        </ul>
      </div>

      <div v-if="this.availableModules['smartlockers']===undefined"
      class="col-lg-5 col-md-5 col-11 mt-5 border bg-menu text-center rounded-5 sombra"
      style="pointer-events: none; opacity: 0.5;">
        <h5>
          <a class="col-12 aside-anchor text-center" style="font-size: 16px !important;opacity:0.3;pointer-events: none;cursor: not-allowed;">
            <ion-icon name="lock-closed-outline" class="tx-20 me-3 pt-3"></ion-icon>
            {{ dictionary['Smart Lockers'][session.user.language].toUpperCase() }}
          </a>
        </h5>
        <ul class="col-12 row m-0 p-0">
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Emplazamientos'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Lockers'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Conjuntos'][session.user.language] }} </button>
          </li>
          <li class="col-lg-6 col-md-12 col-6 tx-16 mb-3" style="list-style: none; cursor: pointer;">
            <button class="aside-list-button"> {{ dictionary['Avisos'][session.user.language] }} </button>
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "SideMenuComponent",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    availableModules: {
      type: Object,
      requiered: true,
    }
  },
  data() {
    return {
      isDevicesVisible: false,
      isFabricVisible: false,
      isStorageVisible: false,
      isSmartlockersVisible: false,
      generalPages: {
        companies: {text:"Empresas", icon: "briefcase-outline", value:"companies"},
        users: {text:"Usuarios", icon: "people-outline", value:"users"}
      },
      pages: {
        dashboard: {text:"Dashboard", icon: "home-outline", value: "dashboard"},
        companies: {text:"Empresas", icon: "briefcase-outline", value:"companies"},
        users: {text:"Usuarios", icon: "people-outline", value:"users"},
        emplacements: {text:"Emplazamientos", icon: "location-outline", value: "emplacements"},
        machines: {text:"Máquinas", icon: "cog-outline", value: "machines"},
        lockers: {text:"Lockers", icon: "cog-outline", value: "lockers"},
        bundles: {text:"Conjuntos", icon: "grid-outline", value: "bundles"},
        devices: {text:"Dispositivos", icon: "hardware-chip-outline", value: "devices"},
        products: {text:"Productos", icon: "cube-outline", value: "products"},
        stocks: {text:"Stock", icon: "layers-outline", value: "stocks"},
        receptions: {text:"Recepción", icon: "arrow-undo-outline", value: "receptions"},
        expeditions: {text:"Expedición", icon: "arrow-redo-outline", value: "expeditions"},
        orders: {text:"Pedidos", icon: "basket-outline", value: "orders"},
        refunds: {text:"Devoluciones", icon: "trash-bin-outline", value: "refunds"},
        lockings: {text:"Reservas", icon: "lock-open-outline", value: "lockings"},
        charts: {text:"Gráficas", icon: "stats-chart-outline", value: "charts"},
        warnings: {text:"Avisos", icon: "mail-outline", value: "warnings"},
        incidences: {text:"Incidencias", icon: "mail-outline", value: "incidences"},
      },
      modules: {
        devices: {text:"Dispositivos", icon: "hardware-chip-outline", value: "devices"},
        fabric: {text:"Monitorización", icon: "business-outline", value: "fabric"},
        storage: {text:"Almacén", icon: "grid-outline", value: "storage"},
        smartlockers: {text:"Smart Lockers", icon: "lock-open-outline", value: "smartlockers"},
      },
    }
  },
  emits: ["toggleSideMenu", "logOutRequest" ],
  methods: {
    // Capas
    onModuleClicked(moduleName) {
      if (moduleName === "devices") {
      this.isDevicesVisible = true;
      this.isFabricVisible=false
      this.isStorageVisible =false;
      this.isSmartlockersVisible = false;
    } else if (moduleName === "fabric") {
      this.isFabricVisible = true;
      this.isDevicesVisible= false
      this.isStorageVisible = false
      this.isSmartlockersVisible =false
    } else if (moduleName === "storage") {
      this.isStorageVisible = true
      this.isDevicesVisible= false
      this.isFabricVisible=false
      this.isSmartlockersVisible = false
    } else if (moduleName === "smartlockers") {
      this.isSmartlockersVisible = true
      this.isDevicesVisible= false
      this.isFabricVisible=false
      this.isStorageVisible = false
    }
      this.$router.replace({params: {currentModule: moduleName, currentDisplay: ""} })

    },
    // NAVIGATE 
    onLeftSideMenuItemClicked(pageName) {
      this.$emit('toggleSideMenu');
      this.$router.replace({params: {currentModule: "general", currentDisplay: pageName, targetEntity: ""} })
    },
    onRightSideMenuItemClicked(pageName, moduleName) {
      this.$emit('toggleSideMenu');
      this.$router.replace({params: {currentModule: moduleName, currentDisplay: pageName, targetEntity: ""} })
    },
   
    // LOG OUT
    logOutRequest() {
      this.$emit('logOutRequest');
    }
  },
}
</script>

<style>
.nav-side-menu-left {
  height: 100vh;
  width: 17%;
  color: #FFF;
  font-weight:400;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
}

.nav-side-menu-right {
  height: 100vh;
  width: 83%;
  color: #FFF;
  font-weight:400;
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  padding-top: 4.5rem;
}

@media (max-width: 767px) {
  .nav-side-menu-left {
    height: 40vh;
    width: 100%;
    transition: all 0.3s;
    top: 0;
  }
  .nav-side-menu-right {
    height: 60vh;
    width: 100%;
    transition: all 0.3s;
    top: 40vh;
    padding-top: 0;
  }
}

.nav-side-menu-left-collapsed {
  height: 100%;
  width: 0;
  color: #FFF;
  font-weight:400;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  position: fixed;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
}

.nav-side-menu-right-collapsed {
  height: 100%;
  width: 0;
  color: #FFF;
  font-weight:400;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  position: fixed;
  transition: all 0.3s;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  padding-top: 4.5rem;
}

@media (max-width: 767px) {
  .nav-side-menu-right-collapsed {
    top: 16.7rem;
  }
}

.menu-item-icon {
  cursor: pointer;
}

.menu-item-icon:hover {
  opacity: 0.75;
  transition: all 0.3s;
}

.menu-user{
  display: flex;
  justify-content: center;
  float: left;
  align-self: center;
  margin: 0 auto;
  margin-top: 2rem;
  transition: all 0.3s;
}

@media (max-width: 767px) {
  .menu-user {
    margin-top: 0;
  }
}

.menu-item-text{
  float: left;
  margin-left: 10%;
  transition: all 0.3s;
}
#ocultar2 {
  display: none;
}
        
#chk:checked + #ocultar2 {
  display: block;
}
</style>